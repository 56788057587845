import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ModalService } from '../modal/services/modal.service';
import { isDefined } from 'src/app/shared/services/utils.service';
import { OtvcPromptModalSevice } from './services/otvc-prompt-modal.service';
import { GlobalContentStore } from '../../store/global-content-store';
import { AppStore } from '../../models/app-store';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/core/services/http.service';
import { ContentStore } from 'src/app/shared/store/content-store';
import { PersistenceService } from 'src/app/core/services/persistence.service';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { CommonService } from '../../services/common.service';
import { OtvcVerifyValidator } from '../otvc/OtvcVerifyValidator';
import { GlobalSignOffService } from '../../../core/services/global-signoff.service';
import { OtvcSecurityStore } from 'src/app/modules/txn/otvc/otvc-security/data/otvc-security-store';
import { TrackingService } from '../../services/tracking.service';
import { of } from 'rxjs';
import { OmniturePageDetail } from '../../models/route-config';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Component({
  selector: 'app-otvc-prompt-modal',
  templateUrl: './otvc-prompt-modal.component.html',
  styleUrls: ['./otvc-prompt-modal.component.scss']
})
export class OtvcPromptModalComponent implements OnChanges {

  @Input() public otvcPromptData: any;
  @Input() public mode: any;
  @Input() public verifyUrl: any;
  @Input() public PromptContent: any;
  @Input() public currentModule: string;
  @Input() uniqueId: string;
  // @Input() public biometricType: string = null;
  @Output() modalCancelRequest = new EventEmitter();
  @Output() modalVerifySuccess = new EventEmitter();
  @Output() modalSuccess = new EventEmitter();
  isSubmit: boolean;
  pageState: any = {};
  pageContent: any = {};
  completed: boolean;
  modetype: string;
  errorModel: any = {};
  tooltipModal: any;
  skip: any;
  formError: any = {};
  formErrorRunTime: any = {};
  header: string;
  isFormSubmit: boolean;
  lang: string;
  lob: string;
  isWrapper: boolean;
  isIos: boolean;
  headerObj: any;
  footerObj: any;
  PageContentResp: any;
  isDataAvailable: boolean = false;
  hasUnexpectedException: boolean = false;
  unexpectedException: string;
  data: object;
  select: number;
  otvcVerifyForm:FormGroup;
  focus: string = '';
  hasError: boolean;
  otvcDetail: any;
  showMenu: boolean;
  isSend: boolean;
  model: any = {};
  isResendEnable: boolean;
  canProceed: boolean;
  nextState: string;
  PageObj: any;
  showSend: boolean;
  url: string;
  modelMode: string;
  compModel: any = { pageLoaded: false, formError: {}, errorModel: {} };
  modelVerifyUrl: string;
  verifyerror: string;
  loading: boolean = false;
  verifySpinner: boolean = false;

  constructor(private router: Router, private httpService: HttpService, public appStore: AppStore,
    public modalService: ModalService, public formBuilder: FormBuilder, private service: OtvcPromptModalSevice,
    public content: ContentStore, public globalContentStore: GlobalContentStore,
    private persistenceService: PersistenceService, private subscriptionService: ObservableSubscriptionService,
    private commonService: CommonService,
    private globalSignOffService: GlobalSignOffService,
    private trackingService: TrackingService) { }


  ngOnChanges() {
    if (this.currentModule === 'prompt') {
      const formData = this.getFormInfo(this.mode);
      const events = {
        siteInteraction: true,
        OTVCprompt: true
      };
      this.sendOmniture(formData.formName + ':' + formData.stepName + ':otvc:sms-email-prompt', events);
      this.data = this.otvcPromptData;
      this.modelMode = this.mode;
      this.modelVerifyUrl = this.verifyUrl;
      console.log(this.otvcPromptData);
      this.compModel.pageContent = this.pageContent = this.content;
      this.compModel.isDataAvailable = this.isDataAvailable = true;
      this.setInitData();
      if (!this.isNullOrEmpty(this.data)) {
        this.compModel.otvcDetail = this.data['OtvcDetail'];
      }
      if (!this.isNullOrEmpty(this.compModel.otvcDetail.LastUsed)) {
        this.compModel.isSend = false;
        this.compModel.model.otvcType = this.compModel.otvcDetail.LastUsed;
      }

      if (this.modelMode === 'prompt' || this.modelMode.startsWith('tempPassword') || this.modelMode === 'forgotSignonPassword' || this.modelMode === 'wgOnlineRegister') {

        // only one contact which is black listed, then show error and disable the dropdown.
        if (!this.isNullOrEmpty(this.compModel.otvcDetail.Contact)) {
          if (this.compModel.otvcDetail.Contact.length === 1) {
            this.compModel.model.otvcType = this.compModel.otvcDetail.Contact[0].Method;
            this.compModel.isSend = false;
            if (this.compModel.otvcDetail.Contact[0].BlackListed) {
              this.compModel.unexpectedException = this.PromptContent.error['MSGOTV003'].message;
              this.compModel.isSend = true;
              this.compModel.isResendEnable = true;
              // making by default choose one but if contact =1,select dropdown not have chosse one in html.
              this.compModel.model.otvcType = '';
            }
          } else if (this.compModel.otvcDetail.Contact.length > 1) {
            this.compModel.otvcDetail.newContact = [];
            for (var i = 0; i < this.compModel.otvcDetail.Contact.length; i++) {
              if (!this.compModel.otvcDetail.Contact[i].BlackListed) {
                let contact = this.compModel.otvcDetail.Contact[i];
                this.compModel.otvcDetail.newContact.push(contact);
                if (contact.Method === this.compModel.otvcDetail.LastUsed) {
                  this.compModel.model.otvcType = contact.Method;
                  this.compModel.isSend = false;
                }
                // this is for blacklisted
              } else {
                let contact = this.compModel.otvcDetail.Contact[i];
                if (contact.Method === this.compModel.otvcDetail.LastUsed) {
                  this.compModel.model.otvcType = '';
                  this.compModel.isSend = true;
                }
              }
            }
            if (this.compModel.otvcDetail.newContact.length === 1) {
              setTimeout(() => {
                let contact = this.compModel.otvcDetail.newContact[0];
                this.compModel.model.otvcType = contact.Method;
              });
              this.compModel.isSend = false;
            }
            if (this.compModel.otvcDetail.newContact.length === 0) {
              this.compModel.unexpectedException = this.PromptContent.error['MSGOTV003'].message;
              // since there is no any valid contact ,Making send button and dropdown disable.
              this.compModel.isSend = true;
              this.compModel.isResendEnable = true;
            }
          }
        }
      } else {

        // only one contact which is black listed, then show error and disable the dropdown.
        if (!this.isNullOrEmpty(this.compModel.otvcDetail.Contact)) {
          if (this.compModel.otvcDetail.Contact.length === 1) {
            this.compModel.model.otvcType = this.compModel.otvcDetail.Contact[0].Method;
            this.compModel.isSend = false;
            if (this.compModel.otvcDetail.Contact[0].BlackListed) {
              this.compModel.unexpectedException = this.PromptContent.error['MSGOTV003'].message;
              // Making send button and dropdown disable
              this.compModel.isResendEnable = true;
              this.compModel.isSend = true;
              // if it is blacklisted,making default selection in choose one
              this.compModel.model.otvcType = '';

            }
          } else if (this.compModel.otvcDetail.Contact.length > 1) {
            this.compModel.otvcDetail.newContact = [];
            var validContact = false;
            for (var i = 0; i < this.compModel.otvcDetail.Contact.length; i++) {
              if (!this.compModel.otvcDetail.Contact[i].BlackListed) {
                let contact = this.compModel.otvcDetail.Contact[i];
                this.compModel.otvcDetail.newContact.push(contact);
                validContact = true;
                if (contact.Method === this.compModel.otvcDetail.LastUsed) {
                  this.compModel.model.otvcType = contact.Method;
                  this.compModel.isSend = false;
                }
              }
            }
            if (this.compModel.otvcDetail.newContact.length === 1) {
              this.compModel.isSend = false;
              setTimeout(() => {
                this.compModel.model.otvcType = this.compModel.otvcDetail.newContact[0].Method;
              });
            }
            // show error if none of the contacts is valid.
            if (!validContact) {
              this.compModel.unexpectedException = this.PromptContent.error['MSGOTV003'].message;
              // Making dropdown and send button disable and by default selected is "choose one"
              this.compModel.model.otvcType = '';
              this.compModel.isSend = true;
              this.compModel.isResendEnable = true;
            }
          }
        }
      }

      this.compModel.pageLoaded = true;
    }
  }

  handleSelection(obj) {
    this.isSend = (this.model.otvcType === '');
  }

  handleSend(message: string) {
    this.loading = true;
    if (this.modelMode === 'SignOn') {
      if (message === 'send') {
        this.url = gatewayConfig.APIServices.userPrefOTVCFSend.url;
      } else if (message === 'resend') {
        this.url = gatewayConfig.APIServices.userPrefOTVCFResend.url;
      }
    } else if (this.modelMode === 'prompt') {
      if (message === 'send') {
        this.url = gatewayConfig.APIServices.OTVCSend.url;
      } else if (message === 'resend') {
        this.url = gatewayConfig.APIServices.OTVCResend.url;
      }
    } else if (this.modelMode === 'SSOLoginPrompt') {
      if (message === 'send') {
        this.url = gatewayConfig.APIServices.OTVCSend.url;
      } else if (message === 'resend') {
        this.url = gatewayConfig.APIServices.OTVCResend.url;
      }
    } else if (this.modelMode === 'SSOprompt') {
      if (message === 'send') {
        this.url = gatewayConfig.APIServices.SSOOTVCSend.url;
      } else if (message === 'resend') {
        this.url = gatewayConfig.APIServices.SSOOTVCResend.url;
      }
    } else if (this.modelMode.startsWith('tempPassword')) {
      if (message === 'send') {
        this.url = gatewayConfig.APIServices.OTVCTempSend.url;
      } else if (message === 'resend') {
        this.url = gatewayConfig.APIServices.OTVCTempResend.url;
      }
    } else if (this.modelMode === 'forgotSignonPassword') {
      if (message === 'send') {
        this.url = gatewayConfig.APIServices.forgotPasswordSend.url;
      } else if (message === 'resend') {
        this.url = gatewayConfig.APIServices.forgotPasswordResend.url;
      }
    } else if (this.modelMode === 'wgOnlineRegister') {
      if (message === 'send') {
        this.url = gatewayConfig.APIServices.wgOnlineRegisterSend.url;
      } else if (message === 'resend') {
        this.url = gatewayConfig.APIServices.wgOnlineRegisterResend.url;
      }
    } else if (this.modelMode === 'updatePassword') {
      if (message === 'send') {
        this.url = gatewayConfig.APIServices.OTVCUpdatePasswordSend.url;
      } else if (message === 'resend') {
        this.url = gatewayConfig.APIServices.OTVCUpdatePasswordResend.url;
      }
    } else {
      if (message === 'send') {
        this.url = gatewayConfig.APIServices.userPrefOTVCSend.url;
      } else if (message === 'resend') {
        this.url = gatewayConfig.APIServices.userPrefOTVCResend.url;
      }
    }

    this.compModel.isResendEnable = true;
    this.service.sendOtvcRequested({ ContactMethod: this.compModel.model.otvcType }, this.url).subscribe(
      (result: any) => {
        this.loading = false;
        this.setFocus('sendSuccessfull');
        console.log('Success callback of OTVC Send');
        console.log('result=');
        console.log(JSON.stringify(result));
        this.compModel.showSend = false;
        this.compModel.isResendEnable = true;
        this.sentSelected();
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        this.compModel.hasError = true;
        this.compModel.showSend = true;
        this.compModel.isResendEnable = false;
        if (isDefined(err.error) && err.error.Exception.ErrorCode === 'SYSSC5303') {
          this.compModel.showSend = false;
          this.compModel.isResendEnable = true;
        }
        this.verifyFail(err);
      }
    );
  }

  setFocus(elementID) {
    if (elementID) {
      window.requestAnimationFrame(() => {
        const targetElement = document.getElementById(elementID);
        if (targetElement) {
          targetElement.focus();
        }
      });
    }
  }

  sentSelected() {
    var self = this;
    self.compModel.isResendEnable = false;
  }

  getFormInfo(page: string) {
    let formInfo = {
      formName: '',
      stepName: ''
    };
    switch (page) {
      case 'PostSignOn':
        formInfo.formName = 'obr-change-security-contact-info';
        formInfo.stepName = 'details';
        break;
      case 'SignOn':
        formInfo.formName = 'obr-account-setup-otvc';
        formInfo.stepName = 'details';
        break;
      case 'prompt':
        formInfo.formName = 'sign-on';
        formInfo.stepName = 'details';
        break;
      case 'tempPasswordB':
      case 'tempPasswordS':
      case 'tempPasswordT':
        formInfo.formName = 'sign-on-temp-password';
        formInfo.stepName = 'details';
        break;
      case 'forgotSignonPassword':
        formInfo.formName = 'obr-reset-password';
        formInfo.stepName = 'verification';
        break;
      case 'forgotTradingPassword':
        formInfo.formName = 'obr-forgot-trading-password';
        formInfo.stepName = 'details';
        break;
      case 'updateUserID':
        formInfo.formName = 'obr-update-user-id';
        formInfo.stepName = 'details';
        break;
      case 'updateAddress':
        formInfo.formName = 'obr-update-address';
        formInfo.stepName = 'details';
        break;
      case 'wgOnlineRegister':
        formInfo.formName = 'register-obr';
        formInfo.stepName = 'identity-verification';
        break;
      case 'SSOLoginPrompt':
      case 'SSOprompt':
        formInfo.formName = 'obr-sso-setup';
        formInfo.stepName = 'details';
        break;
    }
    return formInfo;
  }

  sendOmniture(data: string, inputEvent: any) {
    if ((window as any).TrackingEnabled) {
      of(this.trackingService.tagInteraction(data, true, false, inputEvent));
    }
  }

  getContactType(type: string) {
    let contactType;
    switch (type) {
      case 'EMAIL':
        contactType = 'email';
        break;
      case 'MOBILE_PHONE_TEXT':
        contactType = 'sms';
        break;
      case 'MOBILE_PHONE':
        contactType = 'phone';
        break;
      case 'BUSINESS_PHONE':
        contactType = 'phone';
        break;
    }
    return contactType;
  }

  verify() {
    this.verifySpinner = true;
    let self = this;
    this.service.verifyOtvc(this.verifyUrl, { Code: this.compModel.model.verifyCode }).subscribe(
      (result: any) => {
        this.verifySpinner = false;
        if (result) {
          if (isDefined(OtvcSecurityStore.savedInfo) && OtvcSecurityStore.savedInfo != null) {
            OtvcSecurityStore.savedInfo = undefined;
          }
          if (this.modelMode === 'PostSignOn') {
            this.cancelPrompt();
            this.commonService.callNonAPI("txn/userPreferences/otvc/confirm");
            // } else if (result.Next && result.Next === 'SSO_TO_AZURE') {
            //   if (result.Lob) {
            //     this.persistenceService.cookieStorePersist('MBI_LOB', result.Lob);
            //     this.appStore.state.user.lob = result.Lob.toLowerCase();
            //   }
            //   this.cancelPrompt();
            //   this.compModel.canProceed = true;
            //   this.commonService.SSOToAzure(result.SsoLinkIDMnum, this.biometricType);
          } else if (this.modelMode === 'SignOn' || this.modelMode === 'prompt' || this.modelMode === 'forgotSignonPassword') {
            if (result.Lob) {
              this.persistenceService.cookieStorePersist('MBI_LOB', result.Lob);
              this.appStore.state.user.lob = result.Lob.toLowerCase();
            }
            this.cancelPrompt();
            this.compModel.canProceed = true;
            if (result.Next == 'TEMP_PASSWORD') {
              this.service.tempPasswordInit().subscribe((res2) => {
                this.commonService.handleNextPage(result.Next, this, res2.PasswordFlag, result?.OmnitureToken, null, result.PostSignOnOtvcRequired);
              });
            } else {
              this.commonService.handleNextPage(result.Next, this, result.TempPasswordFlag, result?.OmnitureToken);
            }
            return false;
          } else if (this.modelMode === 'forgotTradingPassword') {
            this.cancelPrompt();
            this.commonService.callNonAPI("txn/userPreferences/forgotTradingPassword/landing");
          } else if (this.modelMode === 'updateUserID') {
            this.cancelPrompt();
            this.commonService.callNonAPI("txn/userPreferences/userIDChange/landing");
          } else if (this.modelMode === 'updateAddress') {
            this.cancelPrompt();
            this.commonService.callNonAPI("txn/userPreferences/contact-info-update/landing");
          } else if (this.modelMode === 'changeTradingPassword') {
            this.cancelPrompt();
            this.commonService.callNonAPI("txn/userPreferences/changeTradingPassword/landing");
          } else if (this.modelMode === 'changeSignOnPassword') {
            this.cancelPrompt();
            this.commonService.callNonAPI("txn/userPreferences/changeSignOnPassword/landing");
          } else if (this.modelMode === 'SSOLoginPrompt' || this.modelMode === 'SSOprompt') {
            if (result.Next == 'SSO_ASSOCIATE') {
              this.modalVerifySuccess.emit();
            } else {
              if (result.Lob) {
                this.persistenceService.cookieStorePersist('MBI_LOB', result.Lob);
                this.appStore.state.user.lob = result.Lob.toLowerCase();
              }
              this.cancelPrompt();
              this.compModel.canProceed = true;
              if (result.Next == 'TEMP_PASSWORD') {
                this.service.tempPasswordInit().subscribe((res2) => {
                  this.commonService.handleNextPage(result.Next, this, res2.PasswordFlag, result?.OmnitureToken, null, result.PostSignOnOtvcRequired);
                });
              } else {
                this.commonService.handleNextPage(result.Next, this, result.TempPasswordFlag, result?.OmnitureToken);
              }
              return false;
            }
          }

          // This block is for FrgotsignonPass. if verify does not return next page name, which means it was called from forgotsignonpass.
        } else {
          if (this.modelMode === 'wgOnlineRegister') {
            this.cancelPrompt();
            this.commonService.callNonAPI("onlineRegistration/password");
          } else if (this.modelMode.startsWith('tempPassword')) {
            this.cancelPrompt();
            this.compModel.canProceed = true;
            this.commonService.handleNextPage('TEMP_PASSWORD', this, this.modelMode.substr(this.modelMode.length - 1));
            return false;
          } else if (this.modelMode === 'updatePassword') {
            this.cancelPrompt();
            this.commonService.callNonAPI("updatePassword/landing");
          }
          else {
            this.cancelPrompt();
            this.commonService.callNonAPI('signOn/changeSignOnPassword');
          }
        }
      },
      (err) => {
        this.verifySpinner = false;
        this.verifyFail(err);
      }
    );

  }

  confirmChange() {
    var errorContent: any = {};
    errorContent.title = '';
    errorContent.subTitle = '';
    errorContent.message = this.content.text.completed,
      errorContent.buttons = [{
        text: this.content.text.okButton
      }];
    errorContent.cssClass = 'alert-error-popup';
    errorContent.enableBackdropDismiss = false;
    alert('changes saved');
  }

  handleRunTimeErrors(err) {
    this.formErrorRunTime = {};
    this.formErrorRunTime.verifyCode = { message: 'new errr' };
  }

  verifyFail(err) {
    if (isDefined(err.error) && isDefined(err.error.Exception) && err.error.Exception !== null) {
      if (isDefined(err.error.Exception.ErrorCode) && err.error.Exception.ErrorCode === 'SYSSC5303') {
        this.compModel.showSend = false;
        this.compModel.isResendEnable = true;
        throw err;
      }
      // This is when user enter incorrect varification code in modal.
      else if (isDefined(err.error.Exception.ErrorCode) && err.error.Exception.ErrorCode === 'SYSSC5302') {
        this.formError = {};
        setTimeout(() => {
          this.formError = OtvcVerifyValidator.resolveServerValidationError(this.otvcVerifyForm, err.error.Exception.ErrorMessage, 'verifyCode');
        }, 200);
      }
      // This is when user enters an expired code
      else if (isDefined(err.error.Exception.ErrorCode) && err.error.Exception.ErrorCode === 'SYSSC5301') {
        this.formError = {};
        setTimeout(() => {
          this.formError = OtvcVerifyValidator.resolveServerValidationError(this.otvcVerifyForm, err.error.Exception.ErrorMessage, 'verifyCode');
        }, 200);
      }
      // This is for session timeout #1798.
      else if (isDefined(err.error.Exception.ErrorCode) &&
        (err.error.Exception.ErrorCode === 'UIBUSSC0001') || (err.error.Exception.ErrorCode === 'UIUSRSC0034')) {
        this.globalSignOffService.timeOut();
      }
      else if (isDefined(err.error.Exception.ErrorCode) && err.error.Exception.ErrorCode === 'MBUIMSG001'
        || err.error.Exception.ErrorCode === 'MBUIMSG002'
        || err.error.Exception.ErrorCode === 'MBUIMSG001'
        || err.error.Exception.ErrorCode === 'MBUIMSG0025'
        || err.error.Exception.ErrorCode === 'BUSSC7247'
        || err.error.Exception.ErrorCode === 'MBUIMSG004'
        || err.error.Exception.ErrorCode === 'MBBUSSC0025'
        || err.error.Exception.ErrorCode === 'MBBUSSC0026'
        || err.error.Exception.ErrorCode === 'MBUSRFA0001'
        || err.error.Exception.ErrorCode === 'MBUSRFA0004'
        || err.error.Exception.ErrorCode === 'MBBUSSC0026') {

        throw err;
        this.hasUnexpectedException = true;
      } else if (isDefined(err.error.Exception.ErrorCode) && err.error.Exception.ErrorCode === 'UIUSRSC0033'
        || err.error.Exception.ErrorCode === 'SYSFR0718'
        || err.error.Exception.ErrorCode === 'SYSSC5318'
        || err.error.Exception.ErrorCode === 'BUSSC7281') {
        if (this.modelMode === 'prompt' || this.modelMode.startsWith('tempPassword')) {
          this.modalCancelRequest.emit();
          throw err;
        } else if (this.modelMode === 'PostSignOn' || this.modelMode === 'SignOn' || this.modelMode === 'forgotTradingPassword' || this.modelMode === 'updateUserID' || this.modelMode === 'updateAddress' || this.modelMode === 'forgotSignonPassword' || this.modelMode === 'wgOnlineRegister' || this.modelMode === 'updatePassword' || this.modelMode === 'SSOprompt' || this.modelMode === 'SSOLoginPrompt') {
          // hide next button
          this.compModel.showSend = true;
          this.compModel.isResendEnable = false;
          throw err;
        }
      } else {
        throw err;
      }
    }
  }


  isNullOrEmpty(value) {
    if (typeof value === 'undefined') {
      return true;
    }

    if (typeof value === 'object') {
      return !value;
    }

    if (typeof value === 'string' && value !== '') {
      return false;
    }

    return true;
  }

  setInitData() {
    this.compModel.showSend = true;
    this.compModel.isResendEnable = false;
    this.compModel.pageState = {};
    this.compModel.isSend = false;
    this.compModel.model = {};
    this.compModel.model.otvcType = '';
    this.compModel.model.verifyCode = '';
  }
  cancel() {
    if (this.currentModule === 'prompt') {
      this.subscriptionService.setError('RESET');
      this.compModel.unexpectedException = undefined;
      this.formError.selectDropdown = undefined;
      this.formError.verifyCode = undefined;
      this.modalCancelRequest.emit();
    }
  }

  cancelPrompt() {
    const formData = this.getFormInfo(this.modelMode);
    const contactMethod = this.getContactType(this.compModel.model.otvcType);
    const events = {
      siteInteraction: true,
      OTVCsuccess: true
    };
    this.sendOmniture(formData.formName + ':' + formData.stepName + ':otvc:' + contactMethod + '-success', events);
    this.modalSuccess.emit();
    if (this.currentModule === 'prompt') {
      this.subscriptionService.setError('RESET');
      this.compModel.unexpectedException = undefined;
      this.formError.selectDropdown = undefined;
      this.formError.verifyCode = undefined;
      this.modalService.close('otvcprompt' + this.uniqueId);
    }
    if (this.modelMode === 'SignOn') {
      if ((window as any).TrackingEnabled) {
        const config: OmniturePageDetail = this.getOmnitureConfig();
        of(this.trackingService.tagPageActionManually(config));
      }
    }
  }
  getOmnitureConfig() {
    let config: OmniturePageDetail;
    config = {
      name: "otvcsetup",
      url: "/signOn/otvc/otvcsetup",
      data: {
        name: "confirmation",
        hierarchy: "online-account-setup,otvc",
        formInfo: {
          name: "obr-account-setup-otvc",
          stepName: "confirmation",
        },
        events: { formStep: true, formSubmit: true, pageView: true },
      },
    };

    return config;
  }

  changeErrorEvent(event) {
    this.formError = event;
  }
}
