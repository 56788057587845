import { RouteGroup } from '../shared/models/route-config';

export const SIGNON_HIERARCHY = 'signon';


export const OtvcConfig: RouteGroup = {
  '/signOn/otvc/otvcsetup': {
    name: 'OtvcSecurity',
    service: 'OtvcSecurityService',
    store: 'OtvcSecurityStore',
    resolveContent: 'otvcsecurity',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'init',
        restUrl: 'userPrefOTVCFInit',
        get request(): any {
          return {
          };
        }
      }
    ],
    trackingData: {
      hierarchy: "online-account-setup, otvc",
      name: "details",
      formInfo: {
        name: "obr-account-setup-otvc",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true }
    }
  },

  '/txn/userPreferences/otvc/otvcsetup': {
    name: 'OtvcSecurity',
    service: 'OtvcSecurityService',
    store: 'OtvcSecurityStore',
    resolveContent: 'otvcsecurity',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'init',
        restUrl: 'userPrefOTVCInit',
        get request(): any {
          return {
          };
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences,security,contact-information",
      name: "details",
      nameGA: "Preferences - Change Security Contact Information",
      formInfo: {
        name: "obr-change-security-contact-info",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true }
    },
    brazeID: "viewed_change_contact_information_details"
  },
  '/txn/userPreferences/otvc/confirm': {
    name: 'OtvcConfirm',
    // service: 'OtvcSecurityVerifyService',
    store: 'OtvcConfirmStore',
    resolveContent: 'otvcconfirm',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      // {
      //     id: 'init',
      //     restUrl: 'userPrefOTVCValidate',
      //     get request(): any {
      //         return {
      //             };
      //         }
      // }
    ],
    trackingData: {
      hierarchy: "preferences,security,contact-information",
      name: "confirmation",
      nameGA: "Preferences - Change Security Contact Information Confirmation",
      formInfo: {
        name: "obr-change-security-contact-info",
        stepName: "confirmation",
      },
      events: { formStep: true, formSubmit: true, pageView: true }
    },
    brazeID: "action_change_contact_information_confirmed"
  },
  '/broadcast': {
    name: 'Broadcast',
    service: 'BroadcastService',
    store: 'BroadcastStore',
    resolveContent: 'broadcastmessage',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
    ],
    trackingData: {
      name: "broadcast-messages",
      nameGA: "Broadcast Messages"
    },
    brazeID: "viewed_broadcast_messages"
  },
  '/splash': {
    name: 'splash',
    service: 'SplashService',
    store: 'SplashStore',
    resolveContent: 'splash',
    policy: {
        navFrom: {
            url: ['*'],
            fallBackUrl: ''
        },
        navTo: {
            url: ['*'],
            fallBackUrl: ''
        }
    },
    restService: [
    ],
    trackingData: {
      name: "go-paperless",
      nameGA: "Go Paperless"
    }
  },
  '/splashPassword': {
    name: 'splashpassword',
    service: 'SplashPasswordService',
    store: 'SplashPasswordStore',
    resolveContent: 'splashpassword',
    policy: {
        navFrom: {
            url: ['*'],
            fallBackUrl: ''
        },
        navTo: {
            url: ['*'],
            fallBackUrl: ''
        }
    },
    restService: [
      {
        id: 'init',
        restUrl: 'initPasswordMigration',
        get request(): any {
          return {
          };
        }
      }
    ],
    trackingData: {
      name: "",
      nameGA: ""
    }
  },
};