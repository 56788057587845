<div *ngIf="isDesktop() && displayGlobalHeader()" class="globalHeaderBackground globalHeader{{currentLob}}">
  <div class="globalHeaderWrapper A12" [ngClass]="{'ssoPadding' : isSSO && currentLob != LOB.IE, 'ie-ssoPadding' : isSSO && currentLob == LOB.IE, 'ieGlobalHeaderWrapper': currentLob == LOB.IE}">
    <div *ngIf="isSSO" [ngClass]="{'investingHeader': currentLob != LOB.IE , 'ie-investingHeader': currentLob == LOB.IE}">
      <a href="javascript:void(0);">{{globalContent.text.investing}}</a>
    </div>
    <div *ngIf="isSSO" [ngClass]="{'bankingHeader': currentLob != LOB.IE , 'ie-bankingHeader': currentLob == LOB.IE}" style="margin-right: auto;">
      <a [ngClass]="{'color01': currentLob != LOB.IE}" href="javascript:void(0);" (click)="goToOnlineBanking()">{{globalContent.text.banking}}</a>
    </div>
    <ng-container *ngIf="isToggleLocaleEnable() && !isSourceDCO">
      <button class="toggleLocale" (click)="toggleLocale();" *webOnly
        [attr.lang]="appStore.lang == 'en' ? 'fr' : 'en'">{{globalContent.text.languageSwitch}}
      </button>
    </ng-container>
    <ng-container *ngIf="isPreSignoffEnable()">
      <button class="headerSignOff color01" [ngClass]="{'ieHeaderSignOff': currentLob == LOB.IE}" (click)="signOff()">
        {{globalContent.text.signOff}}
        <span class="sr-only">{{globalContent.text.signOffHiddenText}}</span>
      </button>
    </ng-container>

  </div>
</div>
<div class="{{lob}}StickyNav {{showNav ? '' : 'hideNav'}}">
  <div id="page-header" class="pageHeader"
    [ngClass]="{'marginBottom0': currentPage=='/splash' || currentPage=='/splashPassword', 'registerPageHeader': onlineRegister(), 'iePageHeader': lob == LOB.IE}">
    <app-header [breakPoint]="isDesktop()" [preHeader]="true" [isSSO]="isSSO">
    </app-header>
  </div>
  <div class="headerSpacer"></div>
</div>