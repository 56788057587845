<div class="mainAppContainer" id="main-app-container" [ngClass]="'page-lob-' + getLob()">
  <div id="page-container" class="pageContainer">
    <div id="focusElement" class="sr-only" tabindex="-1" [attr.aria-label]="pageTitleAnnoucement">
      {{pageTitleAnnoucement}}
    </div>
    <header id="pre-header" *ngIf="enableHeader">
      <app-pre-header [currentPage]="currentPage()"></app-pre-header>
    </header>
    <!-- <nav id="global-nav" class="globalNav">
        <app-navigation [openMenu]="isMenuOpen" (menuClicked)="openMenu()" (close)="closeMenu()"> </app-navigation>
    </nav> -->
    <div id="page-content-container" [ngClass]="displayFull()? '':'pageContentContainer'">
      <!-- <main id="page-content" class="pageContent"> -->
      <main [ngClass]="{'pageContent': !displayFull(), 'passwordPageWidth': passwordPage()}">
        <!-- <div *ngIf="isBoldChatEnabled()" id="chatContainer" hidden>
           <div id="chatToken" hidden></div>
          <div id="chatExpiration" hidden></div>
          <div id="chatExpirationOffset" hidden></div> 
          <div *ngIf="!isUserLoggedIn()">
           <app-presignon-chat *ngIf="!isApp" [currentPage]="currentPage()" [currentLocale]="currentLocale()">
            </app-presignon-chat> -->
            <!-- <app-genesys-presignon-chat *ngIf="!isApp" [currentPage]="currentPage()" [currentLocale]="currentLocale()">
            </app-genesys-presignon-chat> 
          </div>
        </div> -->
        <router-outlet></router-outlet>
      </main>
    </div>
    <div class="page-container-spacer" [ngClass]="isSplashPassword()? 'password-background':''"></div>
    <footer id="pre-footer" class="footer-container" [ngClass]="{'ie-footer': getLob() == 'ie'}" *ngIf="enableFooter">
      <app-pre-footer></app-pre-footer>
    </footer>
  </div>
</div>