<div class="overlay-content" *ngIf="data && content.name == 'quicktradeoptionslanding'" id="overlay-body{{uniqueName}}">
  <app-global-refresh [mode]="'tradeOptions'" *ngIf="mode == 'quickTrade'" type="refresh" [name]="'tradeOptions'"></app-global-refresh>  
  <form *ngIf="data" #quicktradeOptionForm="ngForm" (ngSubmit)="next()" class="overlay-body" id="quick-options-form">
 <div class="container">
   
   <article>
     <div>

       <div class="page-top-invisible-divider -ms-page-top-divider-two-col error-alert error-section">
         <app-page-level-error *ngIf="!displayNewMessageBlock" [identifier]="'marketing-content-1'"
           [error]="content.text.marketing1" [mode]="'warning'" margin="top"></app-page-level-error>
           <custom-app-error (formErrorPass)="handleRunTimeErrors($event)" [identifier]="'custom-error'+uniqueName" (errorAlert)="logError($event)">
           </custom-app-error>
         <app-error *ngIf="mode != 'quickTrade'" (formErrorPass)="handleRunTimeErrors($event)" (errorAlert)="logError($event)"></app-error>
       </div>

       <div class="hidden-accessibility-container">
         <app-field-level-error-global-message [errorList]="formError"
           [identifier]="'field-level-global-qt'+uniqueName">
         </app-field-level-error-global-message>
       </div>

       <!-- MOBILE START -->
     <div *ngIf=" !isDesktop()">
       <div *ngIf="this.appStore.lob == LOB.IE && displayNewMessageBlock" class="marginBottom20">
         <new-message-block [content]="content">
           <span class="A12">
             {{ content.text.youCanNow }}
             <strong>
               <a class="underline" href="javascript:void(0);" (click)="openWindow(content.text.coverPutLink)">{{
                 content.text.coverPutText }}</a>
               <span class="sr-only">{{ content.text.newWindowText }}</span>
             </strong>
             {{ content.text.orderWhen }}
           </span>
         </new-message-block>
       </div>
       <!-- OPTION CENTRAL START -->
       <div class="col col-100 col-100-small option-central" *ngIf=" this.appStore.lob == LOB.IE">
         <a class="understanding-options-link-quick-options" href="javascript:void(0);" (click)='goToOptionsCentral()'>
           <div class="icon-E-Transfer" aria-hidden="true"></div>
           <span class="options-link-text">{{content.text.optionsCentral}}</span>
           <span class="sr-only">{{content.text.newWindowText}}</span>
         </a>
       </div>
       <!-- OPTION CENTRAL END -->
       <!-- ACCOUNT _ MOBILE START -->
       <div class="form-bright-zebra paddingTop0 mobileview">
         <div class="col col-100 col-100-small account">

           <div *ngIf="!singleAccount; else hasSignleAccount">
             <div class="account-selector-mobile">
             <input type="hidden" #accountNumber="ngModel" name="accountNumber" [(ngModel)]="inputs.accountIndex"
               for="accountToolTipMobile" class="soft"/>
               <app-account-selector-dropdown-trade-drawer [paramAccountList]="accountList"
                 [paramPageContent]="content" [paramSelectedAccountNumber]="selectedAccount"
                 (selectedAccount)="AccountListReturn($event)" [error]="formError.accountNumber"
                 [chooseOne]="inputs.accountIndex == -1" [mode]="'quicktradeOptions'" [styling]="'quickTrade'" [describedbyAttr]="'error-accountNumber-quick-trade'">
               </app-account-selector-dropdown-trade-drawer>
               <app-field-level-error [error]="formError.accountNumber" [Id]="'error-accountNumber-quick-trade'"
                 class="account-error">
               </app-field-level-error>
               <div *ngIf="isAccountEligibleForTrading == false && formError.accountNumber != undefined"
                 class="enableMarginContainer">
                 <a class="error-class" href="javascript:void(0);" (click)="EnableTradeAccount()">{{
                   content.text.setUpNow }}</a>
               </div>
             </div>
             <div class="accountTooltip">
              <app-tooltip [id]="'accountToolTipMobile'+uniqueName" [tooltipContent]="accountToolTipMobile"
                openIconName="icon-Info-Circle" [largeHitBox]="true" targetEle="{{'accountToolTipMobile'+uniqueName}}"
                (clickTooltip)="tooltipClicked(content.text.account, 'account')"
                [modalPos]=-225>
                <div class="tooltip-content">
                  {{ content.text.accountSelectorTooltipLine1 }}
                </div>
                <div class="tooltip-content">
                  <span [innerHtml]="content.text.accountSelectorTooltipLine2Bold"></span>&nbsp;
                  <span [innerHtml]="content.text.accountSelectorTooltipLine2"></span>
                </div>
              </app-tooltip>
            </div>

             <div *ngIf="this.inputs.accountIndex !== -1" class="accountAreaRow">
               <div class="accountLeftCol">
                 <ng-container *ngIf="!buyingPowerLoading && !balanceLoading">
                   <!-- <div class="col-90"> -->
                   <div class="buying" *ngIf="this.inputs.accountIndex !== -1">
                     <div class="buying-po">
                       <div class="col share paddingRight5 color02" for="buyingPowerTooltip">
                         <label class="buying-power-text" *ngIf="
                   accountList[this.inputs.accountIndex]?.isRegistered
                   ">
                           {{ content.text.buyingPowerText }}</label>
                         <label class="buying-power-text" *ngIf="
                     !accountList[this.inputs.accountIndex]?.isRegistered
                   ">
                           {{ content.text.combinedBuyingPower }}</label>
                       </div>

                     </div>
                   
                   <ng-container *ngIf="
                 accountList[this.inputs.accountIndex]?.isRegistered ">
                     <div class="buying-po" *ngIf=" accountList[this.inputs.accountIndex]
                     ?.BuyingPowerCAD && settleAmountCurrency == 'CAD' ">
                       <span [innerHtml]="
                     accountList[this.inputs.accountIndex]?.BuyingPowerCAD.replace( '$','').replace('$', '')
                       | currencyFormatter : settleAmountCurrency : 'format-currency-sr'"></span>
                     </div>
                     <div class="buying-po" *ngIf="accountList[this.inputs.accountIndex]
                     ?.BuyingPowerUSD && settleAmountCurrency == 'USD'">
                       <span
                         [innerHtml]=" accountList[ this.inputs.accountIndex
                     ]?.BuyingPowerUSD.replace('$','').replace('$', '')| currencyFormatter : settleAmountCurrency : 'format-currency-sr'"></span>
                     </div>
                   </ng-container>
                   <ng-container *ngIf="!accountList[this.inputs.accountIndex]?.isRegistered">
                     <div class="buying-po" *ngIf="accountList[this.inputs.accountIndex]?.ConsolidatedBuyingPower">
                       <span [innerHtml]=" accountList[this.inputs.accountIndex]?.ConsolidatedBuyingPower.replace(
                       '$','').replace('$', '') | currencyFormatter : 'CAD' : 'format-currency-sr' "></span>
                     </div>
                   </ng-container>
                 </div>

                 <div class="cashing" *ngIf="this.inputs.accountIndex !== -1">
                   <div class="cash-bl">
                     {{ content.text.cashBalance }}
                   </div>
                   <div class="cash-bl" *ngIf="
                 accountList[this.inputs.accountIndex]?.accountBalanceAmount">
                     <span [innerHtml]="accountList[this.inputs.accountIndex]?.accountBalanceAmount
                     .replace('$', '').replace('$', '')| currencyFormatter
                       : settleAmountCurrency : 'format-currency-sr'"></span>
                   </div>
                 </div>
               <!-- </div> -->
                 </ng-container>
                 <div *ngIf="buyingPowerLoading || balanceLoading" aria-hidden="true">
                   <div class="new-skeleton-row">
                     <div class="new-skeleton-value-title halved skeleton-animation">
                       <div></div>
                       <div></div>
                     </div>
                     <div class="new-skeleton-value halved skeleton-animation">
                       <div></div>
                     </div>
                   </div>
                 </div>
               </div>
               <div class="accountRightCol" *ngIf="this.inputs.accountIndex !== -1">
                <app-tooltip *ngIf="content && !buyingPowerLoading && !balanceLoading" [id]="'buyingPowerToolTip'+uniqueName" 
                           [tooltipContent]="priceToolTip"
                           openIconName="icon-Info-Circle" 
                           (clickTooltip)="tooltipClicked(content.list.trailingTooltipList.buyingPowerText, 'Buying power')"
                           [mode]="'modal-soft'" [largeHitBox]="true" targetEle="{{'buyingPowerTooltip'+uniqueName}}"
                           attr.aria-label="{{content.list.trailingTooltipList.labelText}}">
                           <div class="tooltip-lightbox">
                             <p class="tooltipTextStyle tooltips-style-text paddingTop16">
                               {{content.list.trailingTooltipList.toolTipIntroContainer3}}
                             </p>
                             <p class="buyingPower">
                               {{content.list.trailingTooltipList.toolTipSubheading1}}
                             </p>
                             <div class="box-buying-power">
                               <ul>
                                 <li class="bb-3 tooltipTextStyle tooltips-style-text paddingBottom8">
                                   {{content.list.trailingTooltipList.cashBalances}}
                                 </li>
                                 <li class="bb-3 tooltipTextStyle tooltips-style-text paddingTop8 paddingBottom8">
                                   {{content.list.trailingTooltipList.marginableLoanValueIfHeld}}
                                 </li>
                                 <li class="bb-3 tooltipTextStyle tooltips-style-text paddingTop8 paddingBottom8">
                                   {{content.list.trailingTooltipList.pendingCashTransfersOut}}
                                 </li>
                                 <li class="bb-3 tooltipTextStyle tooltips-style-text paddingTop8 paddingBottom8">
                                    {{content.list.trailingTooltipList.openOrdersDecreaseBuyingPower}}
                                 </li>
                                 <li class="tooltipTextStyle tooltips-style-text paddingTop8">
                                   {{content.list.trailingTooltipList.filledOrdersPriorSettlement}}
                                 </li>
                               </ul>
                             </div>
                             <p class="buyingPower">
                               {{content.list.trailingTooltipList.investmentAccounts}}
                             </p>
                             <p class="tooltipTextStyle tooltips-style-text">
                               {{content.list.trailingTooltipList.toolTipCloseContainer5}}
                             </p>
                             <p class="paddingTop8 tooltipTextStyle tooltips-style-text">
                               {{content.list.trailingTooltipList.marginAccountsDesc}}
                             </p>
                             <p class="marginTop20 tooltips-style-text">
                               <a class="underline" href="javascript:void(0);"
                                 (click)="openWindow(content.list.trailingTooltipList.l5_url)">
                                   {{content.list.trailingTooltipList.l5}}
                               </a>
                             </p>
                           </div>
                         </app-tooltip>
               </div>
             </div>
             <ng-template #hasSignleAccount>
               <div class="select-item field-input">
                 <span class="one-account uppercase">
                   {{ accountList[0].AccountTypeDesc
                 }}{{ accountList[0].AccountNumber }}</span>
               </div>
             </ng-template>
         </div>
         </div>
       </div>
       <!-- ACCOUNT _ MOBILE END -->
       <!-- ACTION & OPTION SYMBOL _ MOBILE START -->
       <div class="form-bright-zebra">
         <div class="col col-100 col-100-small account">
           <label id="action-label{{uniqueName}}" class="field-label paddingRight5">
             {{ content.text.action}}
           </label>  
           <!-- <div class="account-selector-mobile"></div> -->
           <div class="action-tooltip col-5">
             <app-tooltip *ngIf="this.appStore.lob == LOB.IE" [id]="'actionToolTip'" [tooltipContent]="actionToolTip"
               openIconName="icon-Info-Circle" targetEle="action-label{{uniqueName}}" [largeHitBox]="true" [modalPos]=-25 >
               <div class="tooltip-content">
                 <span [innerHTML]="content.text.actionTooltipContentsmall"></span><br />
               </div>
               <div class="tooltip-content">
                 <span [innerHTML]="content.text.buyToOpenTooltipContentSmall"></span><br />
               </div>
               <div class="tooltip-content">
                 <span [innerHTML]="content.text.sellToOpenTooltipContentSmall"></span>
               </div>
               <div class="tooltip-content">
                 <span [innerHTML]="content.text.closeTooltipContentSmall"></span>
               </div>

             </app-tooltip>
           </div>
           <div class="padding-bottom-mobile col-100">
             <app-connected-radio [contentArray]="actionArray" [(selectedValue)]="inputs.action"
               [uniqueName]="'TradeOptionsLandingactionToggle'+uniqueName" [pagename]="'TradeOptions'"
               (change)="changeActionMode(inputs.action, true)" [labelledbyID]="'action-label'+uniqueName">
             </app-connected-radio>
             <input type='hidden' #actionMode="ngModel" name="actionMode" [(ngModel)]='inputs.action' class="soft">
             <app-field-level-error [error]="formError.actionMode"
               [Id]="'trading-options-action-error-qt'+uniqueName">
             </app-field-level-error>
           </div>

           <div *ngIf="cantfindOptionsClose || manuallySearchAndClose" class="padding-bottom-mobile col-100">
             <app-connected-radio [contentArray]="actionCloseArray" [(selectedValue)]="inputs.actionClose"
               [uniqueName]="'TradeOptionsLandingactionCloseToggle'+uniqueName" [pagename]="'TradeOptions'"
               (change)="changeActionCloseMode(inputs.actionClose, true)" [labelledbyID]="'actionClose'+uniqueName">
             </app-connected-radio>
             <input type='hidden' #closeActionMode="ngModel" name="closeActionMode"
               [(ngModel)]='inputs.actionClose' class="soft">
             <app-field-level-error [error]="formError.actionMode"
               [Id]="'trading-options-action-close-error-qt'+uniqueName">
             </app-field-level-error>
           </div>
           
           <div *ngIf="cantfindoptionsdropdown">
             <app-dropdown-list [id]="'stockHoldingDropdown-qt'" [paramOptions]="optionHoldings" [style]="'soft'"
                 (selectedAccount)="updateCantFindOptions()" [paramPageContent]="content"
                 [chooseOne]="inputs.optionHoldingIndex === -1" [paramSelectedIndex]="inputs.optionHoldingIndex"
                 [mode]="'holdings'" [isTradeOption]="true">
               </app-dropdown-list>
             </div>

            <span [attr.id]="'symbol-label-qt'+uniqueName" class="sr-only">{{content.text.optionSymbol}}</span>
           <div class="field-input"
           [hidden]="(inputs.action !== 'BUYTOOPEN' && inputs.action !== 'SELLTOOPEN' && !manuallySearchAndClose) || symbolLoading || 
           cantfindOptionsClose || cantfindoptionsdropdown || cantfindOptionsOpen">
             <app-predictive-search [placeholder]=" content.text.searchbyCompanynameandsymbol" [query]="query" [styling]="'quickTrade'"
               [contents]="content" [pagename]="'TradeOptions'" (selectedSymbol)="updateSymbol($event)" (cantfindOption)="updateCantfindSymbol($event)"
               (changeQuery)="ChangeSymbolName($event)" [formError]="formError.symbol" (scrollUpForApp)="scrollUpForApp()" (scrollbackForApp)="scrollbackForApp()"
               [labelledbyAttr]="'action-label'+uniqueName" [scrollAttr]="'action-label'+uniqueName" [describedbyAttr]="
               'symbol-error-qt'+uniqueName
         " [hidden]="manuallySearchAndClose || cantfindOptionsOpen">
             </app-predictive-search>
             <input type="hidden" (keydown)="clearManualQuote($event)" [(ngModel)]="inputs.symbol" #symbol="ngModel"
               name="symbol" id="stockHoldingInput-qt" [inputErrorDirective]="formError.symbol"
               aria-labelledby="action-label{{uniqueName}}" [attr.aria-describedby]="
           inputs.action == 'BUYTOOPEN' || inputs.action == 'SELLTOOPEN' ||
           inputs.action == '' ||
           symbolLoading ||
           chooseSymbol
             ? null
             : 'symbol-error-qt'+uniqueName
         " [ngClass]="
           inputs.action == 'BUYTOOPEN' || inputs.action == 'SELLTOOPEN' ||
           inputs.action == '' ||
           symbolLoading ||
           chooseSymbol
             ? 'hiddenControl input-textarea-border-color soft'
             : ' input-textarea-border-color soft'
         " />
             <app-field-level-error [error]="formError.symbol" [Id]="'symbol-error-qt'+uniqueName"></app-field-level-error>
             <!-- <button *ngIf="optionHoldings.length > 0 && !chooseSymbol" class="choose-symbol-toggle color02"
         type="button" (click)="toggleHoldingChoose()">
         {{ content.text.symbolDropdown }}
       </button> -->
           </div>
           <div class="field-input loading-animation-row loading-animation-border" *ngIf='symbolLoading' aria-hidden="true">
              <div  class="loading-animation new-skeleton-value-title marginshimmer col-66">
                <div class="shimmer-four-fifth"></div>
            </div>
           </div>
           <div [hidden]="inputs.action != 'CLOSE' || symbolLoading || manuallySearchAndClose || 
           (optionHoldings.length == 0 || !inputs.chooseSymbol)"
             class="select-item field-input border-field">
             <app-dropdown-list [id]="'stockHoldingDropdown-qt'" [paramOptions]="optionHoldings" [style]="'soft'"
               (selectedAccount)="updateSelectedHolding($event)" [paramPageContent]="content"
               [chooseOne]="inputs.optionHoldingIndex === -1" [paramSelectedIndex]="inputs.optionHoldingIndex"
               [mode]="'holdings'" [isTradeOption]="true">
             </app-dropdown-list>

             <input type="hidden" #optionHoldingIndex="ngModel" name="optionHoldingIndex"
               [(ngModel)]="inputs.optionHoldingIndex" class="soft"/>
             <app-field-level-error [error]="formError.optionHoldingIndex" [Id]="'holding-error-qt'">
             </app-field-level-error>
           </div>
           <div  class="select-item field-input padding-bottom-mobile"
           [ngClass]="
           symbolLoading ||
           (optionHoldings.length > 0 && inputs.chooseSymbol) ||
           !(
             inputs.actionClose == 'BUYTOCLOSE'  || inputs.actionClose == 'SELLTOCLOSE'
           ) || !(cantfindOptionsClose || manuallySearchAndClose) 
             ? 'hiddenControl'
             : ''
         ">
             <input type="text" placeholder="{{content.text.searchbyCompanynameandsymbol}}" (keydown)="clearManualQuote($event)" [(ngModel)]="inputs.symbol" #symbol="ngModel"
                 name="symbol" id="optionHoldingInput" aria-labelledby="symbol-label" aria-describedby="symbol-error"
                 [inputErrorDirective]="formError.symbol" class="soft" (blur)="callQuote()"/>
             <app-field-level-error [error]="formError.symbol" [Id]="'symbol-error-qt'">
             </app-field-level-error>
           </div>

           <div  class="select-item field-input padding-bottom-mobile"
           *ngIf="
           (cantfindOptionsOpen && (inputs.action == 'BUYTOOPEN' || inputs.action == 'SELLTOOPEN'))
         ">
             <input type="text" placeholder="{{content.text.searchbyCompanynameandsymbol}}" (keydown)="clearManualQuote($event)" [(ngModel)]="inputs.symbol" #symbol="ngModel"
                 name="symbol" id="optionHoldingInput" aria-labelledby="symbol-label" aria-describedby="symbol-error"
                 [inputErrorDirective]="formError.symbol" class="soft"/>
             <app-field-level-error [error]="formError.symbol" [Id]="'symbol-error-qt'">
             </app-field-level-error>
           </div>

           <div *ngIf="errorQuote && !formError.symbol">
             <app-symbol-quote [titleId]="'redirectTargetQuote1'" [mode]="
           this.appStore.lob == LOB.IE ? 'new-full' : 'full-large'
         " [contents]="quoteContent" [selectedSymbol]="selectedSymbol" [(quote)]="quote" [orderType]="inputs.action"
               (errorCallback)="recieveSymbolError($event)" [fromQuickTrade]="true">
             </app-symbol-quote>
           </div>

           <div class="padding-bottom-mobile col-100" *ngIf="selectedAccount != undefined && !symbolLoading &&
           (optionHoldings.length == 0 || !inputs.chooseSymbol) && (inputs.action == 'CLOSE' && 
           !isDesktop() && !cantfindoptionsdropdown) || cantfindOptionsOpen || cantfindOptionsClose">
           <label id="account-label-0" class=" field-label">{{ content.text.market }}</label>
               <app-connected-radio [contentArray]="marketArray" [(selectedValue)]="inputs.market"
               [uniqueName]="'TradeOptionsLandingMarketToggle'+uniqueName" [pagename]="'TradeOptions'"
               (change)="changeMarketMode(inputs.market, true)" [labelledbyID]="'market'+uniqueName">
             </app-connected-radio>
             <input type='hidden' #marketMode="ngModel" name="marketMode" [(ngModel)]='inputs.market' class="soft">
             <app-field-level-error [error]="formError.marketMode" [Id]="'market-error'"></app-field-level-error>
           </div>

           <ng-template #symbolOrSecSelctionText>
             <div class="first-time" *ngIf="!errorQuote">
               <div class="title">{{ content.text.lookingFor }}</div>
               <div class="title-desc">{{ content.text.firstStock }}</div>
             </div>
           </ng-template>
           <div [ngClass]="EAAErrorFlag ? 'quote-error EAA paddingTop20' : 'quote-error paddingTop20'" *ngIf="quoteError && !quoteLoading && EAAErrorFlag">
             <div class="quote-error-message underline-inner-links" [innerHtml]="quoteError" style="padding-left: 0px;"></div>
           </div>
         </div>
         <div>
           <input type="hidden" #marketMode="ngModel" name="marketMode" [(ngModel)]="inputs.market" class="soft" />
         </div>
       </div>
       <!-- ACTION & OPTION SYMBOL _ MOBILE END -->
       <!-- OPTION CHART _ MOBILE START -->


       <!-- <div class="form-bright-zebra paddingTop0 mobileview" *ngIf="quoteError && !quoteLoading && EAAErrorFlag">
         <div class="col col-100 col-100-small account">
             <div [ngClass]="EAAErrorFlag ? 'quote-error EAA' : 'quote-error'">
               <div class="quote-error-message underline-inner-links" [innerHtml]="quoteError"></div>
             </div>
           </div>
           </div> -->

           <div class="form-bright-zebra paddingTop0 mobileview" *ngIf="optionQuoteError && !optionQuoteLoading && EAAErrorFlag">
         <div class="col col-100 col-100-small account">
             <div [ngClass]="EAAErrorFlag ? 'quote-error EAA' : 'quote-error'">
               <div class="quote-error-message underline-inner-links" [innerHtml]="optionQuoteError"></div>
             </div>
           </div>
           </div>

           <div class="form-bright-zebra paddingTop0 mobileview" *ngIf="quoteError && !quoteLoading && !EAAErrorFlag">
            <div class="col col-100 col-100-small account">
                <div class="marginBottom5">
                  <div class="col col-40 underlyingquote-title">
                    {{ content.text.underlyingQuote }}
                  </div>
                </div>
                <div class="quote-error-new paddingLeft0">
                  <div aria-hidden="true"
                    class="quote-error-image icon-Error-Warning-Fill color10"></div>
                  <div class="quote-error-message-new underline-inner-links-quote" [innerHtml]="quoteError"></div>
                </div>
              </div>
              </div>

           <div class="form-bright-zebra paddingTop0 mobileview" *ngIf="optionQuoteError && !optionQuoteLoading && !EAAErrorFlag">
            <div class="col col-100 col-100-small account">
                <div class="marginBottom5" >
                  <div class="col col-40 optionquote-title">
                    {{ content.text.optionQuotes }}
                  </div>
                </div>
                <div class="quote-error-new">
                  <div  aria-hidden="true" class="quote-error-image icon-Error-Warning-Fill color10"></div>
                  <div class="quote-error-message-new underline-inner-links-quote" [innerHtml]="optionQuoteError"></div>
                </div>
              </div>
              </div>

       <div class="form-bright-zebra paddingTop0 mobileview" *ngIf="((underlyingQuote || optionQuote) && quoteContent && (optionQuoteError == undefined && quoteError == undefined)) || quoteLoading ">
        <div id="anchoring-top"></div>
         <div class="col col-100 col-100-small account">
          <div class="loading-animation-row" *ngIf='quoteLoading'>
            <div  class="loading-animation new-skeleton-value-title marginTop10 col-40">
              <div class="shimmer-four-fifth"></div>
            </div>
            <div  class="loading-animation new-skeleton-value-title marginTop10 col-66"></div>        
          </div>
          <div *ngIf="(underlyingQuote || optionQuote) && quoteContent">
           <app-option-chain [titleId]="'redirectTargetQuote1'"
             [mode]="this.appStore.lob == LOB.IE ? 'new-full-collapse' : 'full-small'" [contents]="quoteContent"
             [optionQuote]="optionQuote" [underlyingQuote]="underlyingQuote"
             [quoteError]="quoteError" [optionQuoteError]="optionQuoteError" [selectedSymbol]="selectedSymbol"
             [orderType]="inputs.action" [orderTypeClose]="inputs.actionClose" [manuallySearchAndClose]="manuallySearchAndClose"
             [fromQuickTrade]="true" (getManualQuote)="getOptionQuoteTriggeredFromManualEntry($event)"
             (getChainedQuote)="getOptionQuoteTriggeredFromChain($event)" [pagename]="'QuickTrade'"
             [selectedOptionHolding]="selectedOptionHolding" (selectedChainData)="getSelectedDataForChain($event)"
             (selectedGreekData)="getSelectedDataForGreek($event)" [(customRefresh)]="customRefresh"
             [componentStore]="this.storeArray[this.mode]" [parentMode]="this.mode">
           </app-option-chain>
         </div>
        </div>
       </div>
       <!-- OPTION CHART _ MOBILE END -->

       <!-- ORDER PRICE TYPE _ MOBILE START -->
       <div class="form-bright-zebra paddingTop0 mobileview" [hidden]="hideOrderPrice()">
         <div class="col col-100 col-100-small account">
           <div class="col col-100 col-100-small">
             <label id="price-label{{uniqueName}}" class="field-label">
               <span class="paddingRight5">{{ content.text.price }}</span>
               <span>
                 <app-tooltip *ngIf="inputs.priceMode != ''" [id]="'priceToolTip'" [tooltipContent]="priceToolTip"
                   [modalPos]=-100 openIconName="icon-Info-Circle" targetEle="price-radio-1">
                   <div *ngIf="inputs.priceMode == 'marketMode'">
                     <p>
                       <span [innerHtml]="this.content.text.marketTooltip"></span>
                     </p>
                   </div>
                   <div *ngIf="inputs.priceMode == 'limitMode'">
                     <p>
                       <span [innerHtml]="this.content.text.limitTooltip"></span>
                     </p>
                   </div>
                 </app-tooltip>
               </span>
             </label>
             <div class="padding-bottom">
               <div class="order-type-box">
                 <app-connected-radio [contentArray]="priceArray" [(selectedValue)]="inputs.priceMode"
                   [uniqueName]="'TradeOptionsLandingPriceToggle'+uniqueName"
                   (change)="changePriceMode(inputs.priceMode, true)" [labelledbyID]="'price-label'+uniqueName"
                   [dynamicMode]="true"></app-connected-radio>
                 <input type='hidden' #priceMode="ngModel" name="priceMode" [(ngModel)]='inputs.priceMode' class="soft">
                 <app-field-level-error [error]="formError.priceMode"
                   [Id]="'trading-options-price-error-qt'+uniqueName">
                 </app-field-level-error>
               </div>
             </div>
           </div>
           <div class="col col-48 marginright1">
             <div class="engraved-textbox marginRight8 soft"
               [ngClass]="{'custom-error-input-quantity': (formError.quantity != null && formError.quantity != undefined)}">
               <div class="col col-40">
                 <label id="account-label-0-qt" class="field-label marginBottom0 soft" for="quantity-input{{uniqueName}}">
                   <span>{{ content.text.quantity }}</span>
                 </label>
               </div>
               <div class="col col-60 soft">
                 <!-- <div class="field-input"> -->
                 <input id="quantity-input{{uniqueName}}" #quantity="ngModel" name="quantity"  type="number" inputmode="decimal" min="0"
                   autocomplete="off" [(ngModel)]="inputs.quantity" (input)="quantityChange()" [appMaxlength]="4"
                   aria-describedby="quantity-held{{uniqueName}} quantity-error{{uniqueName}}" [inputErrorDirective]="formError.quantity"
                   class="mini-right-txt-box" />
                 <!-- <app-field-level-error [error]="formError.quantity" [Id]="'quantity-error'" class="err-small-290">
               </app-field-level-error> -->
                 <!-- </div> -->
               </div>
             </div>
             <div id="quantity-held{{uniqueName}}">
               <div class="A12 color03 marginTop5" *ngIf="isQuantityAvailable()">
                 {{
                 inputs.optionHoldingIndex != -1
                 ? optionHoldings[inputs.optionHoldingIndex].Quantity
                 .Content
                 : ""
                 }}
                 <span *ngIf="inputs.action === 'BUYTOCLOSE' || inputs.action === 'SELLTOCLOSE' || inputs.action === 'CLOSE'">
                   {{ content.text.held }}</span>
               </div>
             </div>
           </div>
           <div class="col col-48" *ngIf="inputs.priceMode != 'limitMode'"></div>
           <div class="col col-48" *ngIf="inputs.priceMode == 'limitMode'">
             <div class="engraved-textbox marginleft8 soft"
               [ngClass]="{'custom-error-input-limit': (formError.limit != null && formError.limit != undefined)}">
               <div class="col col-60">
                 <label id="account-label-0-qt" class="field-label marginBottom0 soft" for="limit-input">
                   {{ content.text.limitprice }} <span *ngIf="appStore.lang == 'en'">{{content.text.dollarSign}} </span>
                 </label>
                 <span class="floatRight"> </span>
               </div>
               <div class="col col-40">
                 <!-- <div class="field-input"> -->
                 <!-- type="{{pltInputType}}" -->
                 <input [(ngModel)]="inputs.limit" id="limit-input" #limit="ngModel" name="limit"
                   aria-describedby="limit-error" autocomplete="off" type="number" inputmode="decimal"
                   lang="{{ appStore.lang }}" (input)="changeLimitInput()" [appMaxlength]="10"
                   [isNumRegExApply]="true" [inputErrorDirective]="formError.limit"
                   class="mini-right-txt-box paddingLeft5" />
                 <!-- <app-field-level-error [error]="formError.limit" [Id]="'limit-error'" class="err-small-290">
               </app-field-level-error> -->
                 <!-- </div> -->
               </div>
             </div>
           </div>
           <div class="col col-48  marginBottom20 quantity-error-block">
             <app-field-level-error [error]="formError.quantity" [Id]="'quantity-error'" class="err-small-290">
             </app-field-level-error>
           </div>
           <div class="col col-48">
             <app-field-level-error [error]="formError.limit" [Id]="'limit-error'" class="err-small-290">
             </app-field-level-error>
           </div>
           <label id="trade-landing-expiry-label-qt{{uniqueName}}" class="field-label">
             {{ content.text.expiry }}
           </label>
           <div class="paddingBottom8">
             <fieldset class="day-custom-expiry">

               <app-connected-radio [contentArray]="expiryArray" [disabledArray]="disabledExpiryArray"
                 [(selectedValue)]="inputs.expiryMode" [uniqueName]="'TradeLandingExpiryToggle'+uniqueName"
                 (selectedValueChange)="changeExpiryMode($event)" [labelledbyID]="'trade-landing-expiry-label-qt'+uniqueName">
               </app-connected-radio>
               <app-field-level-error [error]="formError.expiryMode"
                 [Id]="'trade-landing-expiry-error-qt'+uniqueName">
               </app-field-level-error>
             </fieldset>
             <div>
               <input type="hidden" #expiryMode="ngModel" name="expiryMode" [(ngModel)]="inputs.expiryMode" class="soft"/>
             </div>
           </div>
           <div [ngClass]="inputs.expiryMode == 'customDate' ? 'paddingBottom8' : ''">
             <div class="col  paddingLeft0-small" *ngIf="inputs.expiryMode == 'customDate'">
               <div class="month-selector">
                 <label id="month-label" class="field-label">{{content.text.month}}</label>
                 <div class="select-item">
                   <select [(ngModel)]='inputs.dateMonth' name="dateMonth" class="custom-date-style soft"
                     (change)="calcMonthDays(inputs.dateMonth); setDateParams();"
                     aria-labelledby="expiry-label-qt month-label" aria-describedby="selected-date-error-qt"
                     [ngModelOptions]="{standalone: true}" [inputErrorDirective]="formError.selectedDate">
                     <option *ngFor="let month of monthList" [ngValue]='month.key'
                       [selected]="inputs.dateMonth == month.key">
                       {{formatDate(month.value)}}
                       </option>
                   </select>
                   <span class="select-icon icon-Chevron-Down color03" aria-hidden="true">
                   </span>
                 </div>
               </div>
             </div>
             <div class="col paddingLeft15 paddingLeft10-small" *ngIf="inputs.expiryMode == 'customDate'">
               <div class="day-selector">
                 <label id="day-label" class="field-label">{{content.text.day}}</label>
                 <div class="select-item">
                   <select [(ngModel)]='inputs.dateDay' name="dateDay" class="custom-date-style soft"
                     aria-labelledby="expiry-label-qt day-label" aria-describedby="selected-date-error-qt"
                     [ngModelOptions]="{standalone: true}" [inputErrorDirective]="formError.selectedDate"
                     (change)="setDateParams();">
                     <option *ngFor=" let day of dayList" [ngValue]='day.Day' [selected]="inputs.dateDay == day.Day"
                       [attr.disabled]="day.Disabled ? true:null">
                       {{day.Day}}</option>
                   </select>
                   <span class="select-icon icon-Chevron-Down color03" aria-hidden="true">
                   </span>
                 </div>
               </div>
             </div>
             <div class="col paddingLeft15" *ngIf="inputs.expiryMode == 'customDate'">
               <div [ngClass]="mode == 'quickTrade' ? 'calendar-selector' : 'calendar-selector-mobil'">
                 <app-date-selector [id]="'customDate-qt-options'+uniqueName" openIconName="icon-Calendar"
                   [targetEle]="'day-label'" [dateParams]="dateSelectorParam.dateParam"
                   [content]="dateSelectorParam.content" (dateSelectedByUser)='setSelectedDate($event)'
                   boundingContainer="overlay-body{{uniqueName}}">
                 </app-date-selector>
               </div>
             </div>
             <div>
               <app-field-level-error [error]="formError.selectedDate" [Id]="'selected-date-error-qt'">
               </app-field-level-error>
             </div>
           </div>
         </div>
       </div>
       <!-- MORE INFO  _ MOBILE START -->
       <div class="form-bright-zebra2 paddingTop0 mobileview paddingBottom0 ">
         <div class="col col-100 col-100-small account">
           <div [ngClass]="addExtraheightinBottom()" >
             <label class="understanding-options-link-quick-options" href="javascript:void(0);">
               <span class="more-info-label">{{ content.text.info }}</span>
               <span class="sr-only">{{ content.text.newWindowText }}</span>
               <span >
                 <app-tooltip [id]="'moreInformationtooltip'" [tooltipContent]="moreInfoTooltip"
                   openIconName="icon-Info-Circle" [mode]="'modal-soft'"
                   [largeHitBox]="true" (clickTooltip)="tooltipClicked(content.text.moreInformation, 'moreInformation')"
                   targetEle="price-input-qt" attr.aria-label="{{ content.text.moreInformationlabelText }}">
                   <div class="tooltip-title">
                     <span>{{ content.text.moreInformation }}</span>
                   </div>
                   <div class="tooltip-lightbox">
                     <ul class="A12 bulletpoint">
                       <li class="bb-2 more-info-tooltip-text  paddingBottom8"
                         [innerHtml]="content.moreInfoTooltip.line1">
                       </li>
                       <li class="bb-2 more-info-tooltip-text  paddingBottom8">
                         {{ content.moreInfoTooltip.line2 }}
                       </li>
                       <li class="bb-2 more-info-tooltip-text  paddingBottom8">
                         {{ content.moreInfoTooltip.line3 }}
                       </li>
                       <li class="bb-2 more-info-tooltip-text  paddingBottom8">
                         {{ content.moreInfoTooltip.line4 }}
                       </li>
                       <li class="bb-2 more-info-tooltip-text  paddingBottom8">
                         {{ content.moreInfoTooltip.line5 }}
                       </li>
                     </ul>

                     <div class="paddingTop16 refintiv">
                       <span class="more-info-tooltip-last-para"
                         [innerHTML]="content.moreInfoTooltip.marketData"></span>
                       <span> <a href="{{content.text.refintivLink}}" class="link-style more-info-tooltip-last-para"
                           target="_blank">{{content.moreInfoTooltip.refintiv}}</a></span>
                     </div>
                     <div><img src="./assets/images/refinitiv_logo.png" alt="refinitiv_logo" class="marginTop10"
                         width="96px" height="24px"></div>
                   </div>
                 </app-tooltip>
               </span>
             </label>
           </div>
         </div>
       </div>
       <!-- MORE INFO _ MOBILE END -->

       <!-- NEXT BTN _ MOBILE START -->
       <div class="button-bottom-container" [attr.aria-hidden]="isKeyboardOpen ? true : false" [hidden]="isKeyboardOpen">
         <div class="bottom-button" [ngClass]="{'bottom-button-quick-trade':mode == 'quickTrade', 'bottom-button-alg':mode != 'quickTrade', 'notch': notch, 'screenReader': isScreenReaderON}">
           <div class="container">
             <div class="button-background submit-hover-bg">

               <button [ngClass]="{'clear': true, 'button': true}" type="button" class="grey"
                 (click)="clearPage()">{{content.text.clear}}</button>
               <button class="green"
                 [ngClass]="{'submit': true,  'button': true, 'primary': true, 'submit-buy' : (inputs.action == 'BUYTOOPEN') || (inputs.actionClose == 'BUYTOCLOSE') || closeActionType('BUYTOCLOSE'), 'submit-sell' : (inputs.action == 'SELLTOOPEN' || (inputs.actionClose == 'SELLTOCLOSE') || closeActionType('SELLTOCLOSE'))}"
                 (click)="next()" type="button" buttonDisable
                 id="submitTradeDrawer">{{content.text.reviewOrder}}</button>
             </div>

           </div>
         </div>
       </div>

       <!-- NEXT BTN _ MOBILE END -->
     </div>
     <!-- MOBILE END -->

     </div>
   </article>
 </div>
</form>

</div>

<!-- TRADE OPTIONS CONFIRM - MOBILE STARTS-->
<app-modal *ngIf="openTradeConfirmInterceptModal" [attr.id]="'tradeOptionsConfirmModal'" [id]="'tradeOptionsConfirmModal'"  [header]="'tradeOptionsConfirmModalHeader'"
[targetElement]="returnConfirmComponentTargetElement()" (keydown.esc)="closeConfirmModal()" [moduleName]="'interceptModal'"
attr.aria-controls="{{'tradeOptionsConfirmModal' + uniqueId}}">
<section id="tradeOptionsConfirmModalContainer" class="tradeOptionsModalContainer">
<div class='modal-close'>
 <button [attr.id]="'tradeOptionsConfirmModalClose' + uniqueId" type="button" (click)='closeConfirmModal()'
   class="close-button icon-Close color04">
   <span class="sr-only">{{content.text.close}}</span>
 </button>
</div>






</section>
</app-modal>
<!-- TRADE OPTIONS CONFIRM - MOBILE ENDS- -->