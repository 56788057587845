<div *ngIf="content">
  <div id="page_top_id" class="page-top -ms-page-top-one-col"
    [ngClass]="{'-ms-page-top-two-col' : type==='two-col', '-ms-page-top-one-col': type !=='two-col', 'passwordPage': passwordPage, 'paddingBottom10' : warning}">
    <h1 [ngClass]="isDesktop() || isPreTxn() ? 'page-header-title' : 'sr-only'">{{content.text.pageHeader}}</h1>
    <ng-content select=".div-steps"></ng-content>
  </div>
  <section id="page_top_divider_id" class="page-top-invisible-divider -ms-page-top-divider-two-col paddingLeft25-big" [ngClass]="{'passwordPage': passwordPage}">
    <app-error></app-error>
  </section>
  <section *ngIf="type!=='two-col'" class="center-col-bright" [ngClass]="{'passwordPage center-col-password': passwordPage}">

    <div id="content_header_icon_id" class="row marginTop10">
      <div aria-hidden="true" class="content-header-icon icon-Checkmark-Fill color20" *ngIf="!warning">
      </div>
      <div aria-hidden="true" class="content-header-icon color20" *ngIf="warning">
        <img class="alert-icon" src="assets/images/icons/Alert.svg" alt="">
      </div>
    </div>
    <ng-content select=".div-body"></ng-content>
    <div *ngIf="orderConfirmInfo" class='tracking confirmation-divider content-body'>
      <div id="tracking_number_id" class="row">
        <span>{{content.text.trackingNumber}} </span>
        <span [innerHTML]="orderConfirmInfo.OrderId | srOnly:true"></span>
      </div>
      <div id="received_id" class="row">
        <span>{{content.text.received}} </span>
        <span>{{orderConfirmInfo.OrderTimestamp}}
        </span>
      </div>
    </div>
    <div *ngIf="!orderConfirmInfo">
      <ng-content select=".div-body"></ng-content>
    </div>
  </section>

  <section *ngIf="type==='two-col'" class="main-content-two-col">
    <section id="left_col_bright_id" class="left-col-bright" role="presentation">
      <div class="row">
        <div aria-hidden="true" class="content-header-icon icon-Checkmark-Fill color20">
        </div>
      </div>
      <ng-content select=".div-left"></ng-content>
    </section>
    <section id="right_col_dark_id" class="right-col-dark" role="presentation">
      <ng-content select=".div-right"></ng-content>
    </section>
  </section>

  <section *ngIf='mode != "quickOptions"'[ngClass]="{'page-bottom-divider -ms-page-bottom-divider-two-col': (orderConfirmInfo || isDesktop())}">
  </section>
  <section id="page_bottom_id" class="page-bottom -ms-page-bottom-two-col" [ngClass]="{'passwordPage': passwordPage}">
    <ng-content select=".div-bottom"></ng-content>
  </section>

</div>