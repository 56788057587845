import { Injectable } from '@angular/core';
import { AppData } from './app-data';
import { _User } from './user';
import { Store } from '../../core/store/store';
import { Lang, Lob } from './lob.enum';

@Injectable()
export class AppStore extends Store<AppData> {
  constructor() {
    super(new AppData());
    this.state.globalAlert = {};
  }

  set isNotch(val: boolean) {
    this.state.isNotch = val;
  }

  get isNotch() {
    return this.state.isNotch;
  }

  set headerBackfn(val: any) {
    this.state.headerBackFn = val;
  }

  get headerBackfn() {
    return this.state.headerBackFn;
  }

  initUser(user: _User.User) {
    this.setState({
      ...this.state, user
    });
  }

  isLoggedIn(): boolean {
    return this.state.user.status === _User.Status.AUTHZ;
  }

  isApp(spec?: string): boolean {
    if (spec == "app") {
      return this.state.user?.platformInfo.mode === _User.Mode.APP;
    } else if (spec == "inapp") {
      return this.state.user?.platformInfo.mode === _User.Mode.INAPPBROWSER;
    }
    return this.state.user?.platformInfo.mode === _User.Mode.APP || this.state.user?.platformInfo.mode === _User.Mode.INAPPBROWSER;
  }

  isOldApp(): boolean {
    return this.state.user.platformInfo.appVersion == _User.Version.OLD_APP;
  }

  get platform() {
    return this.state.user.platformInfo.platform;
  }

  get platformInfo() {
    return this.state.user.platformInfo;
  }

  get lang() {
    return this.state.user.lang;
  }

  set lang(langP: Lang) {
    this.state.user.lang = langP;
  }

  get layout() {
    return this.state.user.platformInfo.layout;
  }

  set layout(layoutP: number) {
    this.state.user.platformInfo.layout = layoutP;
  }

  get lob() {
    return this.state.user.lob;
  }

  set lob(lobP: Lob) {
    this.state.user.lob = lobP;
  }

  set refresh(time: number) {
    this.setState({
      ...this.state, refreshTime: time
    });
  }

  get refresh() {
    return this.state.refreshTime;
  }

  set crossFlow(val: boolean) {
    this.state.crossFlow = val;
  }

  get crossFlow() {
    return this.state.crossFlow;
  }

  setGlobalIndicator(indicator: string, val: boolean) {
    this.state.globalAlert[indicator] = val;
  }

  set globalAlert(val: any) {
    this.state.globalAlert = val;
  }

  get globalAlert() {
    return this.state.globalAlert;
  }

  set currentTimeStamp(timeStamp: any) {
    this.setState({
      ...this.state, currentTimeStamp: timeStamp
    });
  }

  get currentTimeStamp() {
    return this.state.currentTimeStamp;
  }

  set retrieveSessionInfo(data: any) {
    this.setState({
      ...this.state, retrieveSessionInfo: data
    });
  }

  get retrieveSessionInfo() {
    return this.state.retrieveSessionInfo;
  }

  get IsTradeOptionsEnabled() {
    return this.state.isTradeOptionsEnabled;
  }

  set uniqueId(num: string) {
    this.setState({
      ...this.state, uniqueId: 0
    });
  }

  get uniqueId() {
    this.state.uniqueId++;
    return '_uniqueID_' + this.state.uniqueId;
  }

  get features() {
    return this.state.features;
  }

  hideSplashScreen() {
    if (this.isApp && this.state.features.splashScreen) {
      this.state.features.splashScreen.hideNow();
    }
  }

  showSplashScreen() {
    if (this.isApp && this.state.features.splashScreen) {
      this.state.features.splashScreen.show();
    }
  }

  get height() {
    return this.state.device.height;
  }

  set height(height) {
    this.state.device.height = height;
  }

  get width() {
    return this.state.device.width;
  }

  set width(width) {
    this.state.device.width = width;
  }

  set backButtonFn(val: any) {
    this.state.backButtonFn = val;
  }

  get backButtonFn() {
    return this.state.backButtonFn;
  }

  get isWrappedBack() {
    return this.state.isWrappedBack;
  }

  set isWrappedBack(val: boolean) {
    this.state.isWrappedBack = val;
  }

  get version() {
    return this.state.version;
  }

  set version(val: any) {
    this.state.version = val;
  }

  get revision() {
    return this.state.user.platformInfo.revision;
  }

  get build() {
    return this.state.user.platformInfo.build;
  }

  get isScreenReaderON() {
    return this.state.features.isScreenReaderON;
  }

  set isScreenReaderON(val: boolean) {
    this.state.features.isScreenReaderON = val;
  }

  get midTransURL() {
    return this.state.midTransURL;
  }

  set midTransURL(url: string) {
    this.state.midTransURL = url;
  }

  get omnitureId() {
    return this.state.omnitureId;
  }

  set omnitureId(id: string) {
    this.state.omnitureId = id;
  }

  get omnitureAuthType() {
    return this.state.omnitureAuthType;
  }

  set omnitureAuthType(type: string) {
    this.state.omnitureAuthType = type;
  }

  set preTxnPagePermission(val: string) {
    this.state.preTxnPagePermission = val;
  }

  get preTxnPagePermission() {
    return this.state.preTxnPagePermission;
  }

  set connectionStatus(val: boolean) {
    this.state.connectionStatus = val;
  }

  get connectionStatus(): boolean {
    return this.state.connectionStatus;
  }

  set showErrorInPopup(val: boolean) {
    this.state.showErrorInPopup = val;
  }

  get showErrorInPopup() {
    return this.state.showErrorInPopup;
  }

  get brazeUserSession() {
    return this.state.brazeUserSession;
  }

  set brazeUserSession(val) {
    this.state.brazeUserSession = val;
  }

  get brazeInAppMessage() {
    return this.state.brazeInAppMessage;
  }

  set brazeInAppMessage(val) {
    this.state.brazeInAppMessage = val;
  }

  get signonHashingFlow() {
    return this.state.signonHashingFlow;
  }

  set signonHashingFlow(val) {
    this.state.signonHashingFlow = val;
  }
  
  get scrollToValue() {
    return this.state.scrollToValue;
  }

  set scrollToValue(val) {
    this.state.scrollToValue = val;
  }
}
