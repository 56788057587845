import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';

declare let require: any;
const gatewayConfig = require('../../../../../config/gateway-config.json');
@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService implements PageService {

  constructor(
    private httpService: HttpService
  ) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return input;
  }

  forgotTradingPasswordInit() {
    return this.httpService.post(gatewayConfig.APIServices.forgotTradingPasswordInit.url, null);
  }

  getOtvcRequest() {
    return this.httpService.post(gatewayConfig.APIServices.getOtvcRequest.url, {});
  }

  updateUserIDInit() {
    return this.httpService.post(gatewayConfig.APIServices.updateCurrentUserIdInit.url, null);
  }

  updateAddressInit() {
    return this.httpService.post(gatewayConfig.APIServices.updateAddressInit.url, null);
  }

  submitWebForm(params) {
    return this.httpService.post(gatewayConfig.APIServices.webFormSubmit.url, params);
  }

  getPDFBlob(url) {
    return this.httpService.getBlob(url);
  }
  updateTcp(req) {
    return this.httpService.post(gatewayConfig.APIServices.updateTcp.url, req);
  }

  changeSignOnPasswordInit() {
    return this.httpService.post(gatewayConfig.APIServices.changeSignOnPasswordInit.url, null);
  }
  changeTradingPasswordInit() {
    return this.httpService.post(gatewayConfig.APIServices.changeTradingPasswordInit.url, null);
  }
  declineTcp(req) {
    return this.httpService.post(gatewayConfig.APIServices.updateTcp.url, req);
  }
  refreshTcp(){ 
    return this.httpService.post(gatewayConfig.APIServices.manageTcpInit.url, {});
  }

}
