import { RouteGroup } from '../shared/models/route-config';

export const MutualFundsConfig: RouteGroup = {
  '/txn/mutualFunds/landing': {
    name: 'MutualFundsLanding',
    service: 'MutualFundsLandingService',
    servicePath: 'txn/mutualFunds/landing/services/mutual-funds-landing',
    store: 'MutualFundsLandingStore',
    resolveContent: 'mutualfundslanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'init',
        restUrl: 'getMutualFundInit',
        get request(): any {
          return {};
        }
      },
      {
        id: 'holdings',
        restUrl: 'getOrderHoldings',
        optional: true,
        get request(): any {
          return {
            FiType: "M"
          };
        }
      },
      {
        id: 'settlements',
        restUrl: 'getMutualFundSettlementAccount',
        optional: true,
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: 'trading,trade-mutual-funds',
      nameGA: 'Trade Mutual Funds',
      name: 'details',
      formInfo: { name: 'obr-trade-mutual-funds', stepName: 'details' },
      events: { formStep: true, formView: true, pageView: true }
    },
    brazeID: "viewed_trade_mutual_funds_details"
  },
  '/txn/mutualFunds/verify': {
    name: 'MutualFundsVerify',
    service: 'MutualFundsVerifyService',
    servicePath: 'txn/mutualFunds/verify/services/mutual-funds-verify',
    store: 'MutualFundsVerifyStore',
    resolveContent: 'mutualfundsverify',
    policy: {
      navFrom: {
        url: ['/txn/mutualFunds/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      },
      fallbackRoute: '/txn/mutualFunds/landing'
    },
    restService: [
      {
        id: 'init',
        restUrl: 'getMutualFundVerify',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: 'trading,trade-mutual-funds',
      name: 'verification',
      nameGA: 'Trade Mutual Funds - Verification',
      formInfo: { name: 'obr-trade-mutual-funds', stepName: 'verification' },
      events: { formStep: true, pageView: true }
    }
  },
  '/txn/mutualFunds/confirm': {
    name: 'MutualFundsConfirm',
    service: 'MutualFundsConfirmService',
    servicePath: 'txn/mutualFunds/confirm/services/mutual-funds-confirm',
    store: 'MutualFundsConfirmStore',
    resolveContent: 'mutualfundsconfirm',
    policy: {
      navFrom: {
        url: ['/txn/mutualFunds/verify'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      },
      fallbackRoute: '/txn/mutualFunds/landing'
    },
    restService: [
      {
        id: 'init',
        restUrl: 'getMutualFundSubmit',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: 'trading,trade-mutual-funds',
      name: 'confirmation',
      nameGA: 'Trade Mutual Funds - Confirmation',
      formInfo: { name: 'obr-trade-mutual-funds', stepName: 'confirmation' },
      events: { formStep: true, formSubmit: true, pageView: true, tradeSubmit: true },
      transaction: {
        mainField: "inFlow",
        fieldNames: {
          action: "orderReviewInfo.Activity.OrderType",
          type: "mutual-funds",
          symbol: "orderReviewInfo.Symbol.SymbolName",
          market: "notApplicable",
          exchange: "notApplicable",
          quote: "estimatedValues.quote",
          quantity: "estimatedValues.quantity",
          dividend: "estimatedValues.dividend",
          priceType: "notApplicable",
          expiry: "notApplicable",
          account: "estimatedValues.account",
          serviceFee: "estimatedValues.serviceFee",
          value: "estimatedValues.value",
          currency: "estimatedValues.currency",
          foreignExchange: "estimatedValues.foreignExchange",
        }
      }
    }
  },
  '/txn/mutualFunds/search': {
    name: 'MutualFundsSearch',
    service: 'MutualFundsSearchService',
    servicePath: 'txn/mutualFunds/search/services/mutual-funds-search',
    store: 'MutualFundsSearchStore',
    resolveContent: 'mutualfundssearch',
    policy: {
      navFrom: {
        url: ['/txn/mutualFunds/landing', '/txn/regularinvestmentplan/createmutualfund/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
    ],
    trackingData: {
      hierarchy: 'trading,trade-mutual-funds',
      name: 'search',
      nameGA: 'Trade Mutual Funds - Search',
      events: { pageView: true }
    }
  },

};
