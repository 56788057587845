import { BaseData } from 'src/app/shared/models/base-data';

export class CreateSignonPassData extends BaseData {
    constructor() {
        super();
        this.input = {
            newSignOnPassword: null,
            reEnteredSignOnPassword: null
        };
    }
}

export interface CreateSignOnPassInputs {
    newSignOnPassword: string;
    reEnteredSignOnPassword: string;
}