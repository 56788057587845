import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { Component, HostListener, OnInit } from '@angular/core';
import { AppStore } from '../../models/app-store';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { buildInfo } from 'src/app/config/build-info';
import { HttpService } from 'src/app/core/services/http.service';
import { RoutePermissionService } from '../../services/route-permission.service';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../modal/services/modal.service';
import { Lang, Lob } from '../../../shared/models/lob.enum';
import { PersistenceService } from 'src/app/core/services/persistence.service';
declare let require: any;
const gatewayConfig = require('../../../config/gateway-config.json');


@Component({
  selector: 'app-pre-footer',
  templateUrl: './pre-footer.component.html',
  styleUrls: ['./pre-footer.component.scss']
})

export class PreFooterComponent implements OnInit {

  fullpages: any = {
    "#/home": true,
    "#/signon": true,
    "#/signOn/forgotSignOnPassword": true,
    "#/signOn/changeSignOnPassword": true,
    "#/signOn/changeSignOnPass/confirm": true,
    "#/registerOnline/landing": true,
    "#/registerOnline/createPassword": true,
    "#/registerOnline/confirm": true,
    "#/onlineRegistration/password": true,
    "#/onlineRegistration/username": true,
    "#/onlineRegistration/mailingOptions": true,
    "#/onlineRegistration/confirmation": true
  }

  globalFooterPages: any = {
    "#/splash": true,
    "#/splashPassword": false
  }

  // Future sso pages
  ssoPagesList: any = {
    "#/SSOpage": true,
    "#/olbsso/signon": true
  }

  buildInfo_Client: string;
  // buildInfo_Server: string;
  buildInfo_App: string;
  isApp: boolean;
  openNewWindowText: string;
  qrCodeString: string = '';
  isSourceDCO:boolean = false;
  queryParamDco: any;

  constructor(
    public globalContent: GlobalContentStore,
    public appStore: AppStore,
    private _subscriptionService: ObservableSubscriptionService,
    private httpService: HttpService,
    private _routePermissionService: RoutePermissionService,
    private commonService: CommonService,
    private router: Router,
    private modalService: ModalService,
    private persistenceService: PersistenceService,
    private route: ActivatedRoute,
  ) {
    this.qrCodeString = 'https://' + window.location.host + '/html/redirectToStore.html'
   }



  @HostListener("window:resize", ["$event"])
  onResize(event) {
    let el = document.getElementById("pre-legal")
    if (el && el.style['display'] != 'none' && this.commonService.isDesktop()) {
      this.closeLegal();
    }
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }
  // ngOnInit() {
  //     this._subscriptionService.stringLob.subscribe((lob) => {
  //         if (lob) {
  //             this.lob = this.appStore.state.user.lob;
  //             this.contentService.loadGlobalContent().subscribe((globalData) => {
  //                 if (globalData) {
  //                     this.pageContent = globalData;
  //                     this.disclosure = globalData.text.disclosureNotes;
  //                 }
  //             });
  //         }
  //     });
  // }

  ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;
    console.log(queryParams);
    const prtId = queryParams["prtId"] || this.persistenceService.sessionStoreRetrieve('queryParamDco');
    console.log(prtId);
    console.log(typeof prtId);
    if(prtId !== "undefined" && prtId !== null){
      console.log('prtId is not null');
      this.isSourceDCO = true;
      this.persistenceService.sessionStorePersist('isSourceDCO',this.isSourceDCO);
    } else {
      this.isSourceDCO = false;
    }
    console.log(this.isSourceDCO);
   
    this.isApp = this.appStore.isApp('app');
    this.getBuildInfo();

    this.openNewWindowText = '<span class="sr-only">' + this.globalContent.text.newWindowText + '</span>'
  }

  clickLink(url) {
    window.open(url, "_system");
  }

  displayFull() {
    const loc = window.location.hash;
    if (this.isHome() || this.fullpages[loc] || loc.startsWith('#/onlineRegistration/verification') || this.ssoPagesList[loc]) {
      return true;
    }
    return false;
  }

  displayGlobalFooter() {
    const loc = window.location.hash;
    if (this.globalFooterPages[loc]) {
      return true;
    }
    return false;
  }

  isSSOPages() {
    const loc = window.location.hash;
    if (this.ssoPagesList[loc]) {
      return true;
    }
    return false;
  }


  isHome() {
    const loc = window.location.hash;
    if (loc.indexOf("/home/") != -1) {
      return true;
    }
    return false;
  }

  swapLocale() {
    if (this.appStore.lang.toLowerCase() == 'en') {
      this._subscriptionService.passLocale('fr');
    } else {
      this._subscriptionService.passLocale('en');
    }
  }

  isBuildInfoEnable() {
    return this._routePermissionService.enableBuildInfo;
  }

  getBuildInfo() {
    this.buildInfo_Client = 'cVersion: ' + buildInfo.num + ' tag: ' + buildInfo.tag;
    if (this.appStore.isApp('app')) {
      this.buildInfo_App = 'aVersion: ' + this.appStore.platformInfo.appVersion + '-'
        + this.appStore.platformInfo.revision;
      // + this.appStore.platformInfo.build + '-'
    }
    // this.buildServerInfoAPI().subscribe(
    //   (data: any) => {
    //     if (data) {
    //       this.buildInfo_Server = data.ServerId + ' [Release ' + data.Release + ']' + ' sVersion: ' + data.num;
    //     }
    //     // this.loadingService.dismiss();
    //   },
    //   (err) => {
    //     if (err.message.indexOf('/html/error500.html') != -1) {
    //       throw err;
    //     } else {
    //       console.error('Error : ' + err.message);
    //     }
    //   });
  }

  // buildServerInfoAPI() {
  //   return this.httpService.post(gatewayConfig.APIServices.getServerInfo.url, {}, { params: { skiploading: 'true' } });
  // }

  openExternalLink(subLinks) {
    if (subLinks.external === 'Y') {
      if (subLinks.linkTo === 'dco') {
        this.openDCOAppModal();
      } else {
        window.open(subLinks.linkTo, '_system', 'location=yes, resizable=yes, scrollbars=yes');
      }
    } else {
      if (subLinks.isApiCall) {
        this.router.navigate([subLinks.linkTo]);
      } else {
        this.commonService.callNonAPI(subLinks.linkTo);
      }
    }
  }

  openDCOAppModal() {
    this.modalService.open('dcoApp', 'dcoAppModalHeader');
  }

  getAppleForMobileWealthApp() {
    if (this.appStore.lang === Lang.FR) {
      window.open('https://apps.apple.com/ca/app/cibc-mobile-wealth/id441129412?l=fr', '_system', 'location=yes,scrollbars=yes');
    } else {
      window.open('https://apps.apple.com/ca/app/cibc-mobile-wealth/id441129412', '_system', 'location=yes,scrollbars=yes');
    }
  }
  getGooglePlayForMobileWealthApp() {
    if (this.appStore.lang === Lang.FR) {
      window.open('https://play.google.com/store/apps/details?id=com.mobilebrokerage.CIBC&hl=fr', '_system', 'location=yes,scrollbars=yes');
    } else {
      window.open('https://play.google.com/store/apps/details?id=com.mobilebrokerage.CIBC&hl=en', '_system', 'location=yes,scrollbars=yes');
    }
  }

  openLegal() {
    this.modalService.open("pre-legal", "pre-legal-close-button");
  }

  closeLegal() {
    this.modalService.close("pre-legal", "pre-legal-open-button");
  }

  openInNewTab(url) {
    window.open(url, "_blank");
  }
}
