<div *ngIf="appStore.lob.toLowerCase() !== 'ie' && pageContent.lob == lob && globalContent.lob == lob" class="footer">
  <div class="footerColumns" *ngIf="pageContent">
    <div class="globalFooterColumn" *ngFor="let footerLink of pageContent.list.globalFooterLinks; let i = index">
      <div class="W16m color01 globalFooterColumnHeaders" *ngIf="footerLink.header">{{footerLink.header}}</div>
      <ul [ngClass]="isDesktop() ? 'A12' : 'A14'">
        <ng-container *ngFor="let subLinks of footerLink.content">
          <li *ngIf="!subLinks.perm || isPermission(subLinks.perm)">
            <a href="javascript:void(0);" [id]="subLinks.linkTo === 'dco'? 'dcoAppLink':''"
              (click)="openExternalLink(subLinks)" class="footerLinkAlignment"><span [innerHTML]='subLinks.text'></span>
              <span *ngIf="subLinks.external == 'Y'" class="sr-only">{{pageContent.text.newWindowText}}</span></a>
            <span aria-hidden="true" class="icon-PDF footerLinkPDF" *ngIf="subLinks.pdf"></span>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <div class="A12 color01 footer-disclosure">
    <img class="cibc-img"
      [src]="isDesktop() ? 'assets/images/logos/cibc_'+lob+'_'+lang+'_rev_desktop.svg' : 'assets/images/logos/cibc_'+lob+'_'+lang+'_rev_mobile.svg'"
      [alt]="globalContent.text.logo">
    <div id="footer_disclosure_id" class="footer-content">
      <div>{{disclosure}}</div>
      <div *ngIf="appStore.lob.toLowerCase() === 'cpic' || appStore.lob.toLowerCase() === 'wg'"><br>{{disclosure2}}
      </div>
    </div>
    <div *ngIf="isBuildInfoEnable()" id="footer_build_info_id" class="footer-content build-info">
      <p>{{buildInfo_Server}}</p>
      <p>{{buildInfo_Client}}<span *ngIf="isApp" class="inline"> {{buildInfo_App}}</span></p>
    </div>
  </div>
</div>

<div *ngIf="appStore.lob.toLowerCase() == 'ie' && pageContent.lob == 'ie' && globalContent.lob == 'ie'" [ngClass]="isDesktop() ? 'ie-modern-footer': 'ie-footer'">
  <ng-container *ngIf="isDesktop(); else ieSmallFooter">
    <div class="footer-container">
      <div class="footer-link-container">
        <div *ngFor="let link of globalContent.list.postSignonModernFooterLink.content">
          <a (click)="openInNewTab(link.linkTo, link.isInternal)" href="javascript:void(0);" [attr.aria-label]="link.text + link.hdnTxt">{{link.text}}</a>
        </div>
      </div>
      <div class="footer-bottom-container"> 
        <div class="footer-right">
          <img class="cibc-footer-logo" src="assets/images/logos/cibc-footer-ie-{{this.appStore.lang.toLowerCase()}}.svg" alt="logo" aria-hidden="true">
          <div class="cibc-copyright">
            {{globalContent.list.postSignonModernFooterLink.copyRight}}
          </div>
        </div>
        <div class="bigFooterExtraGrow"></div>
        <div class="wealth-app">
          <div class="wealth-app-text">
            <a class="wealth-app-link" href="javascript:void(0);" (click)="openInNewTab(globalContent.list.postSignonModernFooterLink.wealthAppLink)" 
            [attr.aria-label]="globalContent.list.postSignonModernFooterLink.download + globalContent.list.postSignonModernFooterLink.wealthApp + globalContent.list.postSignonModernFooterLink.wealthAppHdnTxt" >
            {{globalContent.list.postSignonModernFooterLink.download}}
            <br />
            <span class="underline">
              {{globalContent.list.postSignonModernFooterLink.wealthApp}}
            </span>
            </a>
          </div>
          <div class="qr-code">
            <qr-code value={{qrCodeString}} size="72" errorCorrectionLevel="M" [attr.aria-label]="globalContent.list.postSignonModernFooterLink.qrCodeHdnTxt">
            </qr-code>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bigFooterContainer" [ngClass]="'bigFooterContainer' + appStore.lang.toUpperCase()">
      <div class="bigFooterColumns">
        <div class="bigFooterColumn" *ngFor="let footerLink of globalContent.list.globalFooterLinks; let i = index">
          <h3 *ngIf="footerLink.header">{{footerLink.header}}</h3>
          <ul>
            <ng-container *ngFor="let subLinks of footerLink.content">
              <ng-container *ngIf="!subLinks.isAnyApp || isAnyApp">
                <li *ngIf="!subLinks.perm || isPermission(subLinks.perm)">
                  <a href="javascript:void(0);" [id]="subLinks.linkTo === 'dco'? 'dcoAppLink':''"
                    (click)="openExternalLink(subLinks)" class="footerLinkAlignment"
                    [innerHtml]="subLinks.text + ' ' + subLinks.srText"></a>
                  <span aria-hidden="true" class="icon-PDF footerLinkPDF" *ngIf="subLinks.pdf"></span>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
      <div class="bigFooterApp">
        <div class="mobile-wealth-content">
          <div>{{globalContent.text.getCIBCMobileWealthApp}}</div>
        </div>
        <div class="mobile-wealth-image-content">
          <a (click)="getAppleForMobileWealthApp()">
            <img
              [src]="isDesktop() ? 'assets/images/icons/mobile_'+appStore.lob.toLowerCase()+'_'+appStore.lang.toLowerCase()+'_apple_store.svg' : 'assets/images/icons/mobile_'+appStore.lob.toLowerCase()+'_'+appStore.lang.toLowerCase()+'_apple_store.svg'"
              [alt]="globalContent.text.newAppleWindow"></a>
          <a (click)="getGooglePlayForMobileWealthApp()">
            <img
              [src]="isDesktop() ? 'assets/images/icons/mobile_'+appStore.lob.toLowerCase()+'_'+appStore.lang.toLowerCase()+'_google_play_store.svg' : 'assets/images/icons/mobile_'+appStore.lob.toLowerCase()+'_'+appStore.lang.toLowerCase()+'_google_play_store.svg'"
              [alt]="globalContent.text.newGooglePlayWindow"></a>
        </div>
      </div>
    </div>
    <div class="bigFooterExtra">
      <img class="footer-copyright-image" [src]="'assets/images/logos/cibc_'+lob+'_'+lang+'_rev_desktop.svg'" alt="logo"
        aria-hidden="true">
      <div class="footer-copyright-text">
        {{disclosure}}</div>
    </div> -->
  </ng-container>
  <ng-template #ieSmallFooter>
    <!-- <div class="vendorIcons">
      <a class="vendorIcon1" (click)="clickLink(globalContent.list.preFooter.CIPFHref)" href="javascript:void(0);">
        <img [src]="'assets/images/logos/CIPF_black_' + appStore.lang.toUpperCase() + '.png'"
          [alt]="globalContent.list.preFooter.CIPFText">
      </a>
      <a class="vendorIcon2" (click)="clickLink(globalContent.list.preFooter.IIROCHref)" href="javascript:void(0);">
        <img [src]="'assets/images/logos/IIROC_black_' + appStore.lang.toUpperCase() + '.png'"
          [alt]="globalContent.list.preFooter.IIROCText">
      </a>
    </div> -->
  </ng-template>
</div>

<app-modal [attr.id]="'dcoApp'" [id]="'dcoApp'" [header]="'dcoAppModalHeader'" style='display: none;'
  (keydown.esc)="closeDCOModal()" targetElement="dcoAppLink">
  <div class="cibc-account-selector-modal-body" aria-controls="dcoApp">
    <div class="cibc-account-selector-modal-content">
      <div class="removeHeader allignCentre" id="dcoAppModalHeader" tabindex="-1">
        {{pageContent.text.dcoAPPModalHeader}}
      </div>
      <div class="marginTop30">
        <section [ngClass]="isDesktop()?'float-right':'page-bottom-left-button-group'">
          <button type="submit" id="global_footer_primary_btn_id" class='button primary marginBottom10-small' (click)="submitDCOApp()">
            {{pageContent.text.continueLabel}}
          </button>
        </section>
        <section [ngClass]="isDesktop()?'':'page-bottom-right-button-group'">
          <button type="button" id="global_footer_secondary_btn_id" class='button secondary' (click)="closeDCOModal()">
            {{pageContent.text.cancelLabel}}
          </button>
        </section>
      </div>
    </div>
  </div>
</app-modal>

<app-modal id="post-legal" style="text-align: center; overflow-x: hidden; display: none;">
  <div *ngIf="globalContent.list.preSignonFooterLegalLinks" class="post-legal-container">
    <div class="post-legal-header">
      <button id="post-legal-close-button" type="button" (click)="closeLegal()" class="icon-Close" [attr.aria-label]="globalContent.text.closeModal"></button>
    </div>
    <div class="post-legal-body-container">
      <div class="post-legal-body">
        <div class="post-legal-body-header">{{globalContent.list.preSignonFooterLegalLinks.header}}</div>
        <a class="post-legal-body-link"
          *ngFor="let legalLink of globalContent.list.globalFooterLegalLinks.content; let i = index"
          (click)="openExternalLink(legalLink, true)" href="javascript:void(0);">
          <span class="post-legal-body-link-text">{{legalLink.text}}</span>
          <ng-container *ngIf="isApp && legalLink.srTextApp; else browserSrTxt">
            <span class="sr-only">{{legalLink.srTextApp}}</span>
          </ng-container>
          <ng-template #browserSrTxt>
            <span class="sr-only">{{legalLink.srText}}</span>
          </ng-template>
          <span class="icon-Chevron-Right"></span>
        </a>
      </div>
      <div class="post-legal-spacer"></div>
      <div class="post-legal-footer">
        <div class="post-legal-vendors">
          <a class="vendorIcon1" (click)="clickLink(globalContent.list.preFooter.CIPFHref)" href="javascript:void(0);">
            <img [src]="'assets/images/logos/CIPF_black_' + appStore.lang.toUpperCase() + '.svg'"
              [alt]="globalContent.list.preFooter.CIPFText">
          </a>
          <a class="vendorIcon2" (click)="clickLink(globalContent.list.preFooter.CIROHref)" href="javascript:void(0);">
            <img [src]="'assets/images/logos/CIRO_black_' + appStore.lang.toUpperCase() + '.svg'"
              [alt]="globalContent.list.preFooter.CIROText">
          </a>
        </div>
        <div class="post-legal-disclosure">{{globalContent.text.disclosureNotesPreSignon}}</div>
      </div>
    </div>
  </div>
</app-modal>