import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, mapToCanActivate } from '@angular/router';
import { TxnComponent } from './txn.component';
import { StateTransitionGuard } from '../../core/guard/state-transition.guard';
import { TxnGuard } from '../../core/guard/txn.guard';
import { AgreementsGuard } from '../../core/guard/agreements.guard';
import { permissionGuard } from './../../core/guard/route-permission.guard';
import { AuthGuard } from '../../core/guard/auth.guard';
import { GlobalFixGuard } from '../../core/guard/global-fix.guard';
import { lobPermissionGuard } from './../../core/guard/lob.guard';
import { RestoreGuard } from '../../core/guard/restore.guard';
import { OptionCentreGuard } from '../../core/guard/option-centre.guard';

const routes: Routes = [

  {
    path: 'txn', component: TxnComponent, canActivate: mapToCanActivate([AuthGuard, TxnGuard]),
    children: [
      {
        path: 'accounts',
        loadChildren: () => import('./accounts/account-info/account-info.module').then(m => m.AccountInfoModule),
        data: { preload: true },
        canActivate: mapToCanActivate([RestoreGuard, permissionGuard('MYACT', 'MYPRT'), GlobalFixGuard])
      },
      {
        path: 'accounts',
        loadChildren: () => import('./accounts/account-holdings/account-holdings.module').then(m => m.AccountHoldingsModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('ACTHD', 'PRTHD'), GlobalFixGuard])
      },
      {
        path: 'performancereporting',
        loadChildren: () => import('./performanceReporting/performance-reporting.module').then(m => m.PerformanceReportingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('PRFRP'), GlobalFixGuard])
      },
      {
        path: 'edocuments',
        loadChildren: () => import('./edocuments/edocuments.module').then(m => m.EdocumentsModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('EDOCS'), GlobalFixGuard])
      }, {
        path: 'aboutmyinvestmentcounsellor',
        loadChildren: () => import('./about-my-investment-counsellor/about-my-investment-counsellor.module').then(m => m.AboutMyInvestmentCounsellorModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, permissionGuard('MYCNS', 'MYADV'), GlobalFixGuard])
      },
      {
        path: 'newsandnotes',
        loadChildren: () => import('./accounts/news-and-notes/news-and-notes.module').then(m => m.NewsAndNotesModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, permissionGuard('NWNTS'), GlobalFixGuard])
      },
      {
        path: 'accountdetails',
        loadChildren: () => import('./accounts/account-details/account-details.module').then(m => m.AccountDetailsModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('ACTDT'), GlobalFixGuard])
      },
      {
        path: 'tradeStock/landing',
        loadChildren: () => import('./tradeStocks/landing/trade-stocks-landing.module').then(m => m.TradeStocksLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRSTK'), GlobalFixGuard])
      },
      {
        path: 'tradeStock/verify',
        loadChildren: () => import('./tradeStocks/verify/trade-stocks-verify.module').then(m => m.TradeStocksVerifyModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRSTK'), GlobalFixGuard])
      },
      {
        path: 'tradeStock/confirm',
        loadChildren: () => import('./tradeStocks/confirm/trade-stocks-confirm.module').then(m => m.TradeStocksConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRSTK'), GlobalFixGuard])
      },
      {
        path: 'transactionhistory',
        loadChildren: () => import('./transactionHistory/transaction-history.module').then(m => m.TransactionHistoryModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRSHS'), GlobalFixGuard])
      },
      {
        path: 'regularinvestmentplan',
        loadChildren: () => import('./regular-investment-plan/regular-investment-plan.module').then(m => m.RegularInvestmentPlanModule),
        data: { preload: false }
      },
      {
        path: 'iposandnewissues',
        loadChildren: () => import('./ipos-and-newissues/ipos-and-newissues.module').then(m => m.IposAndNewIssuesModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRIPO'), GlobalFixGuard])
      },
      {
        path: 'fixed-income',
        loadChildren: () => import('./fixed-income/fixed-income.module').then(m => m.FixedIncomeModule),
        data: { preload: false },
      },
      {
        path: 'buy-structured-notes/selection',
        loadChildren: () => import('./structured-notes/buy-structured-notes/selection/buy-struct-notes-selection.module').then(m => m.BuyStructNotesSelectionModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRFXQ'), GlobalFixGuard])
      },
      {
        path: 'buy-structured-notes/entry',
        loadChildren: () => import('./structured-notes/buy-structured-notes/entry/buy-struct-notes-entry.module').then(m => m.BuyStructNotesEntryModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRFXQ'), GlobalFixGuard])
      },
      {
        path: 'buy-structured-notes/verify',
        loadChildren: () => import('./structured-notes/buy-structured-notes/verify/buy-struct-notes-verify.module').then(m => m.BuyStructNotesVerifyModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRFXQ'), GlobalFixGuard])
      },
      {
        path: 'buy-structured-notes/confirm',
        loadChildren: () => import('./structured-notes/buy-structured-notes/confirm/buy-struct-notes-confirm.module').then(m => m.BuyStructNotesConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRFXQ'), GlobalFixGuard])
      },
      {
        path: 'sell-structured-notes/landing',
        loadChildren: () => import('./structured-notes/sell-structured-notes/landing/sell-struct-notes-landing.module').then(m => m.SellStructNotesLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRFXQ'), GlobalFixGuard])
      },
      {
        path: 'sell-structured-notes/verify',
        loadChildren: () => import('./structured-notes/sell-structured-notes/verify/sell-struct-notes-verify.module').then(m => m.SellStructNotesVerifyModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRFXQ'), GlobalFixGuard])
      },
      {
        path: 'sell-structured-notes/confirm',
        loadChildren: () => import('./structured-notes/sell-structured-notes/confirm/sell-struct-notes-confirm.module').then(m => m.SellStructNotesConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRFXQ'), GlobalFixGuard])
      },
      {
        path: 'transferHub',
        loadChildren: () => import('./cashtransfer/transferCentral/transfer-central.module').then(m => m.TransferCentralModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('CSHTR'), GlobalFixGuard])
      },
      {
        path: 'cashTransfer/landing',
        loadChildren: () => import('./cashtransfer/landing/cashtransfer-landing.module').then(m => m.CashtransferLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRFND', 'CSHTR'), GlobalFixGuard])
      },
      // TODO commented for R2 and load coming soon page default
      {
        path: 'cashTransfer/verify',
        loadChildren: () => import('./cashtransfer/verify/cashtransfer-verify.module').then(m => m.CashtransferVerifyModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRFND', 'CSHTR'), GlobalFixGuard])
      },
      {
        path: 'cashTransfer/confirm',
        loadChildren: () => import('./cashtransfer/confirm/cashtransfer-confirm.module').then(m => m.CashtransferConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRFND', 'CSHTR'), GlobalFixGuard])
      },
      {
        path: 'exchangeCurrency/landing',
        loadChildren: () => import('./exchange-currency/exchange-currency.module').then(m => m.ExchangeCurrencyModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRFND', 'CSHTR'), GlobalFixGuard])
      },
      {
        path: 'optionsCentral',
        loadChildren: () => import('./tradeOptions/optionsCentral/options-central.module').then(m => m.OptionsCentralModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TROPT'), GlobalFixGuard])
      },
      {
        path: 'optionsCentral/singleLeg/landing',
        loadChildren: () => import('./tradeOptions/landing/trade-options-landing.module').then(m => m.TradeOptionsLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TROPT'), GlobalFixGuard])
      },
      {
        path: 'optionsCentral/singleLeg/verify',
        loadChildren: () => import('./tradeOptions/verify/trade-options-verify.module').then(m => m.TradeOptionsVerifyModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TROPT'), GlobalFixGuard])
      },
      {
        path: 'optionsCentral/singleLeg/confirm',
        loadChildren: () => import('./tradeOptions/confirm/trade-options-confirm.module').then(m => m.TradeOptionsConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TROPT'), GlobalFixGuard])
      },
      {
        path: 'optionsCentral/MultiLeg/landing',
        loadChildren: () => import('./tradeMLS/landing/trade-MLS-landing.module').then(m => m.TradeMLSLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRMLS'), GlobalFixGuard])
      },
      {
        path: 'optionsCentral/MultiLeg/verify',
        loadChildren: () => import('./tradeMLS/verify/trade-MLS-verify.module').then(m => m.TradeMLSVerifyModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRMLS'), GlobalFixGuard])
      },
      {
        path: 'orderStatus',
        loadChildren: () => import('./orderStatus/landing/order-status.module').then(m => m.OrderStatusModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('ORDST'), GlobalFixGuard])
      },
      // TODO commented for R2 and load coming soon page default
      {
        path: 'mutualFunds/landing',
        loadChildren: () => import('./mutualFunds/landing/mutual-funds-landing.module').then(m => m.MutualFundsLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRMTF'), GlobalFixGuard])
      },
      {
        path: 'mutualFunds/verify',
        loadChildren: () => import('./mutualFunds/verify/mutual-funds-verify.module').then(m => m.MutualFundsVerifyModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRMTF'), GlobalFixGuard])
      },
      {
        path: 'mutualFunds/confirm',
        loadChildren: () => import('./mutualFunds/confirm/mutual-funds-confirm.module').then(m => m.MutualFundsConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRMTF'), GlobalFixGuard])
      },
      {
        path: 'mutualFunds/search',
        loadChildren: () => import('./mutualFunds/search/mutual-funds-search.module').then(m => m.MutualFundsSearchModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('TRMTF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/otvc/otvcsetup',
        loadChildren: () => import('./otvc/otvc-security/otvc-security.module').then(m => m.OtvcSecurityModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/otvc/confirm',
        loadChildren: () => import('./otvc/confirm/otvc-confirm.module').then(m => m.OtvcConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences',
        loadChildren: () => import('./userPreferences/landing/userPreferences-landing.module').then(m => m.UserPreferencesModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/touchid/landing',
        loadChildren: () => import('./userPreferences/touchid/landing/touchid-landing.module').then(m => m.TouchIDLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'quotesResearch/factSheets',
        loadChildren: () => import('./investment-factsheets/investment-factsheets.module').then(m => m.InvestmentFactsheetsModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('QRIFS'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/connectCIBCOnline/landing',
        loadChildren: () => import('./userPreferences/connect-cibc-online/landing/connect-cibc-online-landing.module').then(m => m.ConnectCIBCOnlineLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/connectCIBCOnline/verify',
        loadChildren: () => import('./userPreferences/connect-cibc-online/verify/connect-cibc-online-verify.module').then(m => m.ConnectCIBCOnlineVerifyModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/connectCIBCOnline/confirm',
        loadChildren: () => import('./userPreferences/connect-cibc-online/confirm/connect-cibc-online-confirm.module').then(m => m.ConnectCIBCOnlineConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/userIDChange/landing',
        loadChildren: () => import('./userPreferences/changeUserID/landing/change-userid-landing.module').then(m => m.ChangeUserIDLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/userIDChange/confirm',
        loadChildren: () => import('./userPreferences/changeUserID/confirm/change-userid-confirm.module').then(m => m.ChangeUserIDConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/changeSignOnPassword/landing',
        loadChildren: () => import('./userPreferences/changeSignOnPassword/landing/change-signon-password-landing.module').then(m => m.ChangeSignOnPasswordLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/changeSignOnPassword/confirm',
        loadChildren: () => import('./userPreferences/changeSignOnPassword/confirm/change-signon-password-confirm.module').then(m => m.ChangeSignOnPasswordConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/changeTradingPassword/landing',
        loadChildren: () => import('./userPreferences/changeTradingPassword/landing/change-trading-password-landing.module').then(m => m.ChangeTradingPasswordLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/changeTradingPassword/confirm',
        loadChildren: () => import('./userPreferences/changeTradingPassword/confirm/change-trading-password-confirm.module').then(m => m.ChangeTradingPasswordConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/forgotTradingPassword/landing',
        loadChildren: () => import('./userPreferences/forgotTradingPassword/landing/forgot-trading-password-landing.module').then(m => m.ForgotTradingPasswordLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/forgotTradingPassword/confirm',
        loadChildren: () => import('./userPreferences/forgotTradingPassword/confirm/forgot-trading-password-confirm.module').then(m => m.ForgotTradingPasswordConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/setUpTradeFillAlert/landing',
        loadChildren: () => import('./userPreferences/setUpTradeFillAlerts/landing/setup-trade-fill-alerts-landing.module').then(m => m.SetUpTradeFillAlertsLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/relateBrokerageAccounts/landing',
        loadChildren: () => import('./userPreferences/relateBrokerageAccounts/landing/relate-brokerage-accounts-landing.module').then(m => m.RelateBrokerageAccountsLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/relateBrokerageAccounts/confirm',
        loadChildren: () => import('./userPreferences/relateBrokerageAccounts/confirm/relate-brokerage-accounts-confirm.module').then(m => m.RelateBrokerageAccountsConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/removeBrokerageAccounts/verify',
        loadChildren: () => import('./userPreferences/removeBrokerageAccounts/verify/remove-brokerage-accounts-verify.module').then(m => m.RemoveBrokerageAccountsVerifyModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/removeBrokerageAccounts/confirm',
        loadChildren: () => import('./userPreferences/removeBrokerageAccounts/confirm/remove-brokerage-accounts-confirm.module').then(m => m.RemoveBrokerageAccountsConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/changeMailingOptions/verify',
        loadChildren: () => import('./userPreferences/changeMailingOptions/verify/change-mailing-options-verify.module').then(m => m.ChangeMailingOptionsVerifyModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/changeMailingOptions/confirm',
        loadChildren: () => import('./userPreferences/changeMailingOptions/confirm/change-mailing-options-confirm.module').then(m => m.ChangeMailingOptionsConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/nicknameAccounts/landing',
        loadChildren: () => import('./userPreferences/nicknameAccounts/landing/nickname-accounts-landing.module').then(m => m.NicknameAccountsLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/nicknameAccounts/confirm',
        loadChildren: () => import('./userPreferences/nicknameAccounts/confirm/nickname-accounts-confirm.module').then(m => m.NicknameAccountsConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/touchid/confirm',
        loadChildren: () => import('./userPreferences/touchid/submit/touchid-confirm.module').then(m => m.TouchIDConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/setUpTradeFillAlert/confirm',
        loadChildren: () => import('./userPreferences/setUpTradeFillAlerts/confirm/setup-trade-fill-alerts-confirm.module').then(m => m.SetUpTradeFillAlertsConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/exchangeAgreements/landing',
        loadChildren: () => import('./userPreferences/exchangeAgreements/landing/exchange-agreements-landing.module').then(m => m.ExchangeAgreementsLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/cegAgreement',
        loadChildren: () => import('./userPreferences/exchangeAgreements/cegAgreement/ceg-agreement.module').then(m => m.CegAgreementModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/nyAgreement',
        loadChildren: () => import('./userPreferences/exchangeAgreements/nyAgreement/ny-agreement.module').then(m => m.NyAgreementModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/nasdaqAgreement',
        loadChildren: () => import('./userPreferences/exchangeAgreements/nasdaqAgreement/nasdaq-agreement.module').then(m => m.NasdaqAgreementModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/opraAgreement',
        loadChildren: () => import('./userPreferences/exchangeAgreements/opraAgreement/opra-agrement.module').then(m => m.OpraAgreementModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/removeBrokerageAccounts/landing',
        loadChildren: () => import('./userPreferences/removeBrokerageAccounts/landing/remove-brokerage-accounts-landing.module').then(m => m.RemoveBrokerageAccountsLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/changeMailingOptions/landing',
        loadChildren: () => import('./userPreferences/changeMailingOptions/landing/change-mailing-options-landing.module').then(m => m.ChangeMailingOptionsLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/link-bank-accounts/landing',
        loadChildren: () => import('./userPreferences/linkBankAccounts/landing/link-bank-accounts-landing.module').then(m => m.LinkBankAccountsLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('WFLBA'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/manage-trusted-contact-person/landing',
        loadChildren: () => import('./userPreferences/manageTrustedContactPerson/landing/manage-trusted-contact-person-landing.module').then(m => m.ManageTrustedContactPersonLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('WFLBA'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      // {
      //   path: 'userPreferences/manage-trusted-contact-person/confirm',
      //   loadChildren: () => import('./userPreferences/manageTrustedContactPerson/confirm/manage-trusted-contact-person-confirm.module').then(m => m.ManageTrustedContactPersonConfirmModule),
      //   data: { preload: false },
      //   canActivate: [RestoreGuard, StateTransitionGuard, permissionGuard('WFLBA'), lobPermissionGuard('IE'), GlobalFixGuard]
      // },
      {
        path: 'userPreferences/link-bank-accounts/confirm',
        loadChildren: () => import('./userPreferences/linkBankAccounts/confirm/link-bank-accounts-confirm.module').then(m => m.LinkBankAccountsConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('WFLBA'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/enable-margin-and-options-trading/landing',
        loadChildren: () => import('./userPreferences/enableMarginAndOtionsTrading/landing/enable-margin-and-options-trading-landing.module').then(m => m.EnableMarginAndOptionsTradingLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([StateTransitionGuard, permissionGuard('WFEUT'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/enable-margin-and-options-trading/confirm',
        loadChildren: () => import('./userPreferences/enableMarginAndOtionsTrading/confirm/enable-margin-and-options-trading-confirm.module').then(m => m.EnableMarginAndOptionsTradingConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([StateTransitionGuard, permissionGuard('WFEUT'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/enable-US-markets-trading/landing',
        loadChildren: () => import('./userPreferences/enableUSMarketTrading/landing/enable-US-market-trading-landing.module').then(m => m.EnableUSMarketTradingLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('WFEUT'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/enable-US-markets-trading/confirm',
        loadChildren: () => import('./userPreferences/enableUSMarketTrading/confirm/enable-US-market-trading-confirm.module').then(m => m.EnableUSMarketTradingConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('WFEUT'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/renew-US-trading/landing',
        loadChildren: () => import('./userPreferences/w8Ben/landing/w8Ben-landing.module').then(m => m.W8BenLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/renew-US-trading/verify',
        loadChildren: () => import('./userPreferences/w8Ben/verify/w8Ben-verify.module').then(m => m.W8BenVerifyModule),
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/renew-US-trading/confirm',
        loadChildren: () => import('./userPreferences/w8Ben/confirm/w8Ben-confirm.module').then(m => m.W8BenConfirmModule),
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/contact-info-update/landing',
        loadChildren: () => import('./userPreferences/contactInfoUpdate/landing/contact-info-update-landing.module').then(m => m.ContactInfoUpdateLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('WFACI'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/contact-info-update/confirm',
        loadChildren: () => import('./userPreferences/contactInfoUpdate/confirm/contact-info-update-confirm.module').then(m => m.ContactInfoUpdateConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('WFACI'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/rrsp-withdrawal/landing',
        loadChildren: () => import('./userPreferences/rrspWithdrawal/landing/rrsp-withdrawal-landing.module').then(m => m.RRSPWithdrawalLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('WFRRW'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/rrsp-withdrawal/confirm',
        loadChildren: () => import('./userPreferences/rrspWithdrawal/confirm/rrsp-withdrawal-confirm.module').then(m => m.RRSPWithdrawalConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('WFRRW'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/currency-transfer/landing',
        loadChildren: () => import('./userPreferences/currencyTransfer/landing/currency-transfer-landing.module').then(m => m.CurrencyTransferLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('WFCST'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/currency-transfer/confirm',
        loadChildren: () => import('./userPreferences/currencyTransfer/confirm/currency-transfer-confirm.module').then(m => m.CurrencyTransferConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('WFCST'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/transfer-forms/landing',
        loadChildren: () => import('./userPreferences/transferForms/landing/transfer-forms-landing.module').then(m => m.TransferFormsLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('WFTRI'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/transfer-forms/verify',
        loadChildren: () => import('./userPreferences/transferForms/verify/transfer-forms-verify.module').then(m => m.TransferFormsVerifyModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('WFTRI'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/transfer-forms/confirm',
        loadChildren: () => import('./userPreferences/transferForms/confirm/transfer-forms-confirm.module').then(m => m.TransferFormsConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('WFTRI'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/drips-forms/landing',
        loadChildren: () => import('./userPreferences/dripsForm/landing/drips-form-landing.module').then(m => m.DripsFormLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('WFDRP'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'userPreferences/drips-forms/confirm',
        loadChildren: () => import('./userPreferences/dripsForm/confirm/drips-form-confirm.module').then(m => m.DripsFormConfirmModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('WFDRP'), lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'marketsLanding',
        loadChildren: () => import('./markets/landing/markets-landing.module').then(m => m.MarketsLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, AgreementsGuard, permissionGuard('QRMKC'), GlobalFixGuard])
      },
      {
        path: 'markets/detail',
        loadChildren: () => import('./markets/details/markets-detail.module').then(m => m.MarketsDetailModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('QRMKC'), GlobalFixGuard])
      },
      {
        path: 'quotesWatchList',
        loadChildren: () => import('./quotesWatchList/landing/quotes-watchlist.module').then(m => m.QuotesWatchListModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, AgreementsGuard, permissionGuard('QRLST'), GlobalFixGuard])
      },
      {
        path: 'quotesResearch/optionCentre',
        loadChildren: () => import('./option-centre/option-centre.module').then(m => m.OptionCentreModule),
        data: { preload: false },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard, OptionCentreGuard])
      },
      {
        path: 'quotesResearch/tradeIdeas',
        loadChildren: () => import('./quotesResearch/strategyBuilder/quotes-research-strategy-builder.module').then(m => m.QuotesResearchStrategyBuilderModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('QRMKC'), GlobalFixGuard])
      },
      {
        path: 'quotesResearch/technicalInsights',
        loadChildren: () => import('./quotesResearch/technicalInsights/quotes-research-technical-insights.module').then(m => m.QuotesResearchTechnicalInsightsModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('QRMKC'), GlobalFixGuard])
      },
      {
        path: 'quotesResearch/:module',
        loadChildren: () => import('./quotesResearch/quotes-research.module').then(m => m.QuotesResearchModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('QRLST', 'QRMKC', 'QRSTC', 'QRETF', 'QRFND', 'QRRPT', 'QRALR'), GlobalFixGuard])
      },
      {
        path: 'bridge/quotesResearch',
        loadChildren: () => import('./quotesResearch/bridge/quotes-research-bridge.module').then(m => m.QuotesResearchBridgeModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'quotesWatchList/detail',
        loadChildren: () => import('./quotesWatchList/quoteDetails/quotes-detail.module').then(m => m.QuotesDetailModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('USRPRF'), GlobalFixGuard])
      },
      {
        path: 'contactus',
        loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, GlobalFixGuard])
      }, {
        path: 'creditpolicy',
        loadChildren: () => import('./creditPolicy/credit-policy.module').then(m => m.CreditPolicyModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, GlobalFixGuard])
      },
      {
        path: 'feescommission',
        loadChildren: () => import('./fees-commission/fees-commission.module').then(m => m.FeesCommissionModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, lobPermissionGuard('IIS', 'CFPI', 'WG', 'CPIC'), GlobalFixGuard])
      },
      {
        path: 'help',
        loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, permissionGuard('IRHLP'), GlobalFixGuard])
      },
      {
        path: 'sitemap',
        loadChildren: () => import('./siteMap/site-map.module').then(m => m.SiteMapModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'agreementanddisclosures',
        loadChildren: () => import('./agreements-and-disclosures/agreements-and-disclosures.module').then(m => m.AgreementsAndDisclosuresModule),
        data: { preload: false },
        canActivate: mapToCanActivate([RestoreGuard, StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'exchangeCurrencyNew/landing',
        loadChildren: () => import('./exchange-currency/landing/exchange-currency-landing.module').then(m => m.ExchangeCurrencyLandingModule),
        data: { preload: false },
        canActivate: mapToCanActivate([StateTransitionGuard, permissionGuard('FXALD'), GlobalFixGuard])
      },
      { path: '**', redirectTo: '/error404' },
    ]
  },
  { path: '**', redirectTo: '/error404' }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class TxnRoutingModule { }
