<!-- <div class="home-page">
  <div class="home-card-wrapper">
    <img *ngIf="content.list.cards.A.image" class="home-card-image" [attr.src]="content.list.cards.A.image" alt='' aria-hidden="true">
    <div class="home-card (centered)">
          <div class="home-card-pre-title">{{content.list.cards.A.preTitle}}</div>
      <h2 class="home-card-title">{{content.list.cards.A.title}}</h2>
      <div class="home-card-content">{{content.list.cards.A.content}}</div>
      <div class="home-card-link">
        <a class="home-button button primary" role="link" [attr.href]="content.list.cards.A.linkHref">
          {{content.list.cards.A.linkText}}
        </a>
        <a class="home-link" role="link" [attr.href]="content.list.cards.A.linkHref">
          {{content.list.cards.A.linkText}}
          <span class="icon-Chevron-Right"></span>
        </a>
        <a class="home-link" role="link" href="javascript:void(0);" (click)="newWindow({ url: content.list.cards.A.linkHref })">
          {{content.list.cards.A.linkText}}
          <span class="icon-Chevron-Right"></span>
        </a>
        <a class="home-link" role="link" href="javascript:void(0);" (click)="navigate(content.list.cards.A.linkHref2)">
          {{content.list.cards.A.linkText2}}
        </a>
      </div>
    </div>
  </div>
</div> -->
<div *ngIf="!isLobChangeHomeContentReload && content.name == 'home'">
  <h1 class="sr-only">{{content.text.SRH1}}</h1>
  <div *ngIf="appStore.lob.toLowerCase() == 'ie'">
    <div class="home-page">
      <div class="home-card-wrapper">
        <img [attr.src]="content.list.cards.massOfferLimited.image" alt="" class="home-card-image">
        <div class="home-card centered limited">
          <span id="home_card_badge_id" class="home-card-badge-limited">
            <span class="icon-star-filled" aria-hidden="true"></span>
            {{content.list.cards.massOfferLimited.badgeTitle}}
            </span>
          <h1 class="home-card-title-internal">
            {{this.PEEnabled && content.list.cards.massOfferLimited.titlePE ? content.list.cards.massOfferLimited.titlePE : content.list.cards.massOfferLimited.title}}
          </h1>
          <h2 class="home-card-subTitle" *ngIf="!this.PEEnabled">{{content.list.cards.massOfferLimited.subTitle}}</h2>
          <div class="home-card-link standard-spacing">
            <a class="home-card-standard-content learn-more" role="link" href="javascript:void(0);" (click)="isDesktop() ? newWindow({ url: content.list.cards.massOfferLimited.linkUrl }, true) : openWindowUrl(content.list.cards.massOfferLimited.linkUrl)">
              {{content.list.cards.massOfferLimited.linkText}}
              <span class="sr-only" *ngIf="content.list.cards.getAPreviewIE.linkHiddenText">
                {{content.list.cards.getAPreviewIE.linkHiddenText}}
              </span>
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="home-card-wrapper" [ngClass]>
        <span id="home_card_badge_id" class="home-card-badge">
          <span class="icon-star-filled"
            aria-hidden="true"></span>{{content.list.cards.getAPreviewIE.preTitle}}</span>
        <img *ngIf="content.list.cards.getAPreviewIE.image" class="home-card-image"
          [attr.src]="getContentImageAndId(content.list.cards.getAPreviewIE.image)" alt='' aria-hidden="true">
        <div class="home-card centered"
          [ngClass]="{'blue-card' : content.list.cards.getAPreviewIE.backgroundBlue, 'grey-card' : content.list.cards.getAPreviewIE.backgroundGrey, 'no-image' : !content.list.cards.getAPreviewIE.image}">
          <span id="home_card_badge_id" class="home-card-badge">
            <span class="icon-star-filled"
              aria-hidden="true"></span>{{content.list.cards.getAPreviewIE.preTitle}}</span>
          <h1 class="home-card-title-internal"><span [innerHTML]="content.list.cards.getAPreviewIE.title"></span>
          </h1>
          <h2 class="home-card-subTitle" *ngIf="content.list.cards.getAPreviewIE.subTitle">
            {{content.list.cards.getAPreviewIE.subTitle}}</h2>
          <div class="home-card-standard-content">
            {{content.list.cards.getAPreviewIE.content}}
          </div>
          <div class="home-card-link standard-spacing" *ngIf="content.list.cards.getAPreviewIE.linkText">
            <a id="home_card_getAPreviewIE_id" class="home-link" role="link" href="javascript:void(0);"
              (click)="newWindow({ url: content.list.cards.getAPreviewIE.linkUrl }, true)">
              {{content.list.cards.getAPreviewIE.linkText}}
              <span class="sr-only"
                *ngIf="content.list.cards.getAPreviewIE.linkHiddenText">{{content.list.cards.getAPreviewIE.linkHiddenText}}</span>
              <span aria-hidden="true" class="icon-Chevron-Right"></span>
            </a>
          </div>
        </div>
      </div> -->
      <div class="home-card-wrapper">
        <div id="home_card_FHSA_offer_id" class="home-card centered">
            <h1 class="home-card-title FHSA_title">{{content.list.cards.FHSAOffer.title}}</h1>
            <div class="home-card centered display:flex">
              <section>
                <span class="home-card-standard-content">{{content.list.cards.FHSAOffer.content}}</span>
              </section>
            </div>  
            <div>
              <!-- <a href="{{content.list.cards.FHSAOffer.signOnButtonLink}}" class="signOnButton">
                <span>{{content.list.cards.FHSAOffer.signOnButton}}</span>
                <span class="sr-only">{{signOnButtonSrText}}</span>
                <span class="icon-Chevron-Right quickLinksChevron"></span>
              </a> -->
            </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="appStore.lob.toLowerCase() == 'iis' || appStore.lob.toLowerCase() == 'cfpi'">
    <div class="home-page">
      <div class="home-card-wrapper">
        <img *ngIf="content.list.cards.insightsIIS.image" class="home-card-image"
          [attr.src]="content.list.cards.insightsIIS.image" alt='' aria-hidden="true">
        <div class="home-card centered">
          <h2 class="home-card-title">{{content.list.cards.insightsIIS.title}}</h2>
          <div class="home-card-content">
            {{content.list.cards.insightsIIS.content}}
          </div>
          <div class="home-card-link">
            <a id="home_card_insightIIS_id" class="home-link" role="link" href="javascript:void(0);"
              (click)="newWindow({ url: content.list.cards.insightsIIS.linkHref })">
              {{content.list.cards.insightsIIS.linkText}}
              <span aria-hidden="true" class="icon-Chevron-Right"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="appStore.lob.toLowerCase() == 'wg'">
    <div class="home-page">
      <div class="home-card-wrapper">
        <img *ngIf="content.list.cards.discoverWG.image" class="home-card-image"
          [attr.src]="content.list.cards.discoverWG.image" alt='' aria-hidden="true">
        <div class="home-card centered">
          <h2 class="home-card-title">{{content.list.cards.discoverWG.pretitle}}</h2>
          <div class="home-card-link">
            <a id="home_card_discoverWG_id" class="home-link" role="link" href="javascript:void(0);"
              (click)="newWindow({ url: content.list.cards.discoverWG.linkHref1 })">
              {{content.list.cards.discoverWG.linkText1}}
              <span aria-hidden="true" class="icon-Chevron-Right"></span>
            </a>
          </div>
          <!-- <div class="home-card-link">
            <a class="home-button-small button secondary" role="link" href="javascript:void(0);"
              (click)="newWindow({ url: content.list.cards.discoverWG.linkHref2 })">
              {{content.list.cards.discoverWG.linkText2}}
            </a>
          </div> -->
        </div>
      </div>


      <div class="home-card-wrapper">
        <div class="home-card centered">
          <h2 class="home-card-title">{{content.list.cards.mobileWG.title}}</h2>
          <div class="home-card-link">
            <a id="home_card_mobileWG_id" class="home-link" role="link" href="javascript:void(0);"
              (click)="newWindow({ url: content.list.cards.mobileWG.linkHref })">
              {{content.list.cards.mobileWG.linkText}}
              <span aria-hidden="true" class="icon-Chevron-Right"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="appStore.lob.toLowerCase() == 'cpic'">
    <div class="home-page">
      <div class="home-card-wrapper">
        <img *ngIf="content.list.cards.financialPortraitCPIC.image" class="home-card-image"
          [attr.src]="content.list.cards.financialPortraitCPIC.image" alt='' aria-hidden="true">
        <div class="home-card centered">
          <h2 class="home-card-title">{{content.list.cards.financialPortraitCPIC.pretitle}}</h2>
          <div class="home-card-content">{{content.list.cards.financialPortraitCPIC.content1}}</div>
          <div class="home-card-link">
            <a id="home_card_financial_CPIC1_id" class="home-link" role="link" href="javascript:void(0);"
              (click)="newWindow({ url: content.list.cards.financialPortraitCPIC.linkHref1 })">
              {{content.list.cards.financialPortraitCPIC.linkText1}}
              <span aria-hidden="true" class="icon-Chevron-Right"></span>
            </a>
          </div>
        </div>
      </div>

      <div class="home-card-wrapper">
        <div class="home-card centered">
          <h2 class="home-card-title">{{content.list.cards.financialPortraitCPIC.content2}}</h2>
          <div class="home-card-link">
            <a id="home_card_financial_CPIC2_id" class="home-link" role="link" href="javascript:void(0);" (click)="goToSignOn()">
              {{content.list.cards.financialPortraitCPIC.linkText2}}
              <span aria-hidden="true" class="icon-Chevron-Right"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="appStore.lob.toLowerCase() == 'gen'">
    <div class="home-page">
      <div id="home_card_wrapper_id" class="home-card-wrapper">
        <img *ngIf="content.list.cards.CIBCMobileWealth.image" class="home-card-image"
          [attr.src]="content.list.cards.CIBCMobileWealth.image" alt='' aria-hidden="true">
        <div class="home-card centered">
          <h2 class="home-card-title">{{content.list.cards.CIBCMobileWealth.title}}</h2>
          <div class="home-card-content">{{content.list.cards.CIBCMobileWealth.content}}</div>
          <!-- <div class="home-card-link">
            <a class="home-link" role="link" href="javascript:void(0);" (click)="newWindow()">
              {{content.list.cards.CIBCMobileWealth.linkText}}
              <span aria-hidden="true" class="icon-Chevron-Right"></span>
            </a>
          </div> -->
        </div>
      </div>

      <div class="home-card-wrapper">
        <div class="home-card-gen">
          <div class="home-card-gen-title">{{content.list.cards.CIBCIE.title}}</div>
          <div class="home-card-gen-content">{{content.list.cards.CIBCIE.content}}</div>
          <div class="home-card-gen-link">
            <!-- <a class="home-gen-link" role="link" href="javascript:void(0);"
              (click)="newDynLinkWindow({LANG: appStore.lang, LOB:'ie'})">
              {{content.list.cards.CIBCIE.linkText1}}
            </a> -->
            <a id="home_card_CIBCIE_link1_id" class="home-gen-link" role="link" href="javascript:void(0);"
              (click)="newWindow({ url: content.list.cards.CIBCIE.linkHref1 })">
              {{content.list.cards.CIBCIE.linkText1}}
            </a>
            <span aria-hidden="true" class="icon-Out-of-App"></span>
          </div>
          <div class="home-card-gen-link">
            <a id="home_card_CIBCIE_link2_id" class="home-gen-link" role="link" href="javascript:void(0);"
              (click)="newWindow({ url: content.list.cards.CIBCIE.linkHref2 })">
              {{content.list.cards.CIBCIE.linkText2}}
            </a>
            <span aria-hidden="true" class="icon-Out-of-App"></span>
          </div>
          <div class="home-card-gen-link">
            <a id="home_card_CIBCIE_link3_id" class="home-gen-link" role="link" href="javascript:void(0);" (click)="registerOnline('ie')">
              {{content.list.cards.CIBCIE.linkText3}}
            </a>
          </div>
        </div>


        <div class="home-card-gen">
          <div class="home-card-gen-title">{{content.list.cards.CIBCIIS.title}}</div>
          <div class="home-card-gen-content">{{content.list.cards.CIBCIIS.content}}</div>
          <div class="home-card-gen-link">
            <!-- <a class="home-gen-link" role="link" href="javascript:void(0);"
              (click)="newDynLinkWindow({LANG: appStore.lang, LOB:'iis'})">
              {{content.list.cards.CIBCIIS.linkText1}}
            </a> -->
            <a id="home_card_CIBCWG_link1_id" class="home-gen-link" role="link" href="javascript:void(0);"
              (click)="newWindow({ url: content.list.cards.CIBCIIS.linkHref1 })">
              {{content.list.cards.CIBCIIS.linkText1}}
            </a>
            <span aria-hidden="true" class="icon-Out-of-App"></span>
          </div>
          <div class="home-card-gen-link">
            <a id="home_card_CIBCWG_link2_id" class="home-gen-link" role="link" href="javascript:void(0);"
              (click)="newWindow({ url: content.list.cards.CIBCIIS.linkHref2 })">
              {{content.list.cards.CIBCIIS.linkText2}}
            </a>
            <span aria-hidden="true" class="icon-Out-of-App"></span>
          </div>
          <div class="home-card-gen-link">
            <a id="home_card_CIBCIIS_link3_id" class="home-gen-link" role="link" href="javascript:void(0);" (click)="registerOnline('iis')">
              {{content.list.cards.CIBCIIS.linkText3}}
            </a>
          </div>
        </div>


        <div class="home-card-gen">
          <div class="home-card-gen-title">{{content.list.cards.CIBCWG.title}}</div>
          <div class="home-card-gen-content">{{content.list.cards.CIBCWG.content}}</div>
          <div class="home-card-gen-link">
            <!-- <a class="home-gen-link" role="link" href="javascript:void(0);"
              (click)="newDynLinkWindow({LANG: appStore.lang, LOB:'wg'})">
              {{content.list.cards.CIBCWG.linkText1}}
            </a> -->
            <a id="home_CIBCWG_link_id" class="home-gen-link" role="link" href="javascript:void(0);"
              (click)="newWindow({ url: content.list.cards.CIBCWG.linkHref1 })">
              {{content.list.cards.CIBCWG.linkText1}}
            </a>
            <span aria-hidden="true" class="icon-Out-of-App"></span>
          </div>
          <div class="home-card-gen-link">
            <a id="home_card_CIBCWG_link_id" class="home-gen-link" role="link" href="javascript:void(0);"
              (click)="newWindow({ url: content.list.cards.CIBCWG.linkHref2 })">
              {{content.list.cards.CIBCWG.linkText2}}
            </a>
            <span aria-hidden="true" class="icon-Out-of-App"></span>
          </div>
        </div>


        <div class="home-card-gen">
          <div class="home-card-gen-title">{{content.list.cards.CIBCCPIC.title}}</div>
          <div class="home-card-gen-content">{{content.list.cards.CIBCCPIC.content}}</div>
          <div class="home-card-gen-link">
            <!-- <a class="home-gen-link" role="link" href="javascript:void(0);"
              (click)="newDynLinkWindow({LANG: appStore.lang, LOB:'ie'})">
              {{content.list.cards.CIBCCPIC.linkText1}}
            </a> -->
            <a id="home_CPIC_link_id" class="home-gen-link" role="link" href="javascript:void(0);" (click)="goToCPICSignOn()">
              {{content.list.cards.CIBCCPIC.linkText1}}
            </a>
            <span aria-hidden="true" class="icon-Out-of-App"></span>
          </div>
          <div class="home-card-gen-link">
            <a id="home_card_CPIC_link_id" class="home-gen-link" role="link" href="javascript:void(0);"
              (click)="newWindow({ url: content.list.cards.CIBCCPIC.linkHref2 })">
              {{content.list.cards.CIBCCPIC.linkText2}}
            </a>
            <span aria-hidden="true" class="icon-Out-of-App"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="wrapper-signon-button" *ngIf="content.name == 'home'">
  <a *ngIf="appStore.isApp() || !isDesktop()"  class="home-button button secondary marginBottom10"  role="link" href="javascript:void(0);" (click)="openAccount()">{{content.text.openAccount}}</a>
  <span class="sr-only"  *ngIf="appStore.isApp()">{{content.text.openAccount}}{{content.text.accountopen-aria-label}}</span>
  <a id="floating-sign-on-link" class="home-button button primary" role="link" href="javascript:void(0);"
    (click)="goToSignOn()">
    {{content.text.signOn}}
  </a>
</div>