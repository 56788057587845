import { Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppStore } from '../models/app-store';
import { PersistenceService } from '../../core/services/persistence.service';
import { CommonService } from './common.service';
import { Lob } from 'src/app/shared/models/lob.enum';
import { isDefined } from './utils.service';

declare let window: any;

@Injectable({ providedIn: 'root' })
export class ObservableSubscriptionService {
  constructor(
    private appStore: AppStore,
    private persistenceService: PersistenceService,
    private commonService: CommonService
  ) { }
  // Observable string sources
  private static setModalCloserEventSource = new Subject<string>();
  static setModalCloserEvent$ = ObservableSubscriptionService.setModalCloserEventSource.asObservable();

  private setErrorEventSource = new Subject<string>();
  private getErrorEventSource = new Subject<string>();
  private setCustomErrorEventSource = new Subject<string>();
  private getCustomErrorEventSource = new Subject<string>();
  private modalTrapFocusSource = new Subject<string>();
  private modalUnTrapFocusSource = new Subject<string>();
  private modalSendSource = new Subject<string>();
  private enableButtonSource = new Subject<string>();
  private chatHideSource = new Subject<boolean>();
  private tokenReturned = new Subject<string>();
  private newsOpenSource = new Subject<string>();
  private globalRefreshEventSource = new Subject<string>();
  private globalRefreshUpdateSource = new Subject<any>();
  private globalRefreshUpdateStartSource = new Subject<any>();
  private loadStaticChatSource = new Subject<boolean>();
  private chatStatusSource = new Subject<boolean>();
  private headerSearchRefreshSource = new Subject<any>();
  private chatVisibilitySource = new Subject<boolean>();

  // TODO: Need to check why public subject needed
  public stringLob = new BehaviorSubject('');
  public globalLob = new Subject();
  public stringLocale = new BehaviorSubject('');
  public isKeyboardOpen = new Subject<boolean>();
  public tradeDrawerPosition = new Subject<number>();
  public accountNumberChange: Subject<boolean> = new Subject();
  public openOrCloseDrawer: Subject<any> = new Subject();
  public tradeDrawerCrossFlow: Subject<any> = new Subject();
  public quickOptionsPosition = new Subject<number>();
  public quickOptionsOpenOrCloseDrawer : Subject<any> = new Subject();
  public quickOptionsCrossFlow: Subject<any> = new Subject();
  public refreshOrder = new Subject<string>();
  public userLob = new BehaviorSubject({});
  public premiumUser = new BehaviorSubject({});
  public universalLinkSource = new BehaviorSubject<any>('');
  public brazeContentCardSubscriber = new BehaviorSubject({});

  // Observable string streams
  setErrorEvent$ = this.setErrorEventSource.asObservable();
  getErrorEvent$ = this.getErrorEventSource.asObservable();
  setCustomErrorEvent$ = this.setCustomErrorEventSource.asObservable();
  getCustomErrorEvent$ = this.getCustomErrorEventSource.asObservable();
  modalTrapFocusEvent$ = this.modalTrapFocusSource.asObservable();
  modalUnTrapFocusEvent$ = this.modalUnTrapFocusSource.asObservable();
  modalSendEvent$ = this.modalSendSource.asObservable();
  enableButtonEvent$ = this.enableButtonSource.asObservable();
  chathideEvent$ = this.chatHideSource.asObservable();
  tokenReturnedEvent$ = this.tokenReturned.asObservable();
  newsOpenEvent$ = this.newsOpenSource.asObservable();
  globalRefreshEvent$ = this.globalRefreshEventSource.asObservable();
  globalRefreshUpdate$ = this.globalRefreshUpdateSource.asObservable();
  globalRefreshUpdateStart$ = this.globalRefreshUpdateStartSource.asObservable();
  loadStaticChat$ = this.loadStaticChatSource.asObservable();
  chatStatusSource$ = this.chatStatusSource.asObservable();
  
  headerSearchRefresh$ = this.headerSearchRefreshSource.asObservable();
  chatVisibilityEvent$ = this.chatVisibilitySource.asObservable();

  // Service message commands
  setError(event) {
    this.setErrorEventSource.next(event);
  }

  getError(event: string) {
    this.getErrorEventSource.next(event);
  }

  setCustomError(event) {
    this.setCustomErrorEventSource.next(event);
  }

  getCustomError(event: string) {
    this.getCustomErrorEventSource.next(event);
  }

  modalTrapFocus(event: string) {
    this.modalTrapFocusSource.next(event);
  }

  modalUnTrapFocus(event: string) {
    this.modalUnTrapFocusSource.next(event);
  }

  modalSend(event: string) {
    this.modalSendSource.next(event);
  }

  enableButton(event: string) {
    this.enableButtonSource.next(event);
  }

  hideChat() {
    this.chatHideSource.next(true);
  }

  showChat() {
    this.chatHideSource.next(false);
  }

  setModalClose(event: string) {
    ObservableSubscriptionService.setModalCloserEventSource.next(event);
  }

  passLob(data) {
    window.medalliaEnabled = data.toLowerCase() == Lob.IE && !this.appStore.isApp() && window.__env.enableMedallia;
    // if (isDefined(window.KAMPYLE_ONSITE_SDK)) {
    //   setTimeout(() => {
    //     //window.KAMPYLE_ONSITE_SDK.updatePageView();
    //   })
    // }else {
    //   // add a listener for the event neb_OnsiteLoaded which will occur once all the
    //   // Digital code has been executed by the browser
    //   window.addEventListener('neb_OnsiteLoaded', this.medalliaLoaded());
    // }
    this.appStore.state.user.lob = data.toLowerCase();
    this.persistenceService.cookieStorePersist('MBI_LOB', data.toLowerCase());
    this.stringLob.next(data);
  }

  // medalliaLoaded() {
  //   // load the form and store status (true/false) in neb_status
  //   if(window.KAMPYLE_ONSITE_SDK) {
  //     var neb_status = window.KAMPYLE_ONSITE_SDK.loadForm(this.commonService.feedbackButtonFormId);
  //     if (neb_status === true){
  //     //this.feedbackAvailable.next(true);
  //   }
    
  //   }
  // }

  passGlobalLob(data) {
    this.globalLob.next(data);
  }

  passLocale(data) {
    this.appStore.lang = data;
    this.persistenceService.cookieStorePersist('MBI_LANG', data);
    this.commonService.updateLanguage();
    this.stringLocale.next(data);
  }

  passPEUser(data) {
    this.premiumUser.next(data);
  }

  setKeyboardStatus(data: boolean) {
    this.isKeyboardOpen.next(data);
  }

  returnMarkitToken(event: string) {
    this.tokenReturned.next(event);
  }

  // getServerMessage(error: HttpErrorResponse): string {
  //     return error.message;
  // }

  // getServerStack(error: HttpErrorResponse): string {
  //     // handle stack trace
  //     return 'stack';
  // }

  setTradeDrawerPosition(position: number) {
    this.persistenceService.sessionStorePersist("tradeDrawerPosition", position);
    this.tradeDrawerPosition.next(position);
  }

  setQuickOptionsPosition(position:number){
    this.persistenceService.sessionStorePersist("quickOptionsPosition", position);
    this.quickOptionsPosition.next(position);
  }

  setQuickOptionsCrossFlow(){
    this.quickOptionsCrossFlow.next();
  }

  setTradeDrawerCrossFlow() {
    this.tradeDrawerCrossFlow.next();
  }

  openWhatsNewItem(data) {
    this.newsOpenSource.next(data);
  }

  refreshPageContent(channel) {
    this.globalRefreshEventSource.next(channel);
  }

  updateRefreshTime(options) {
    this.globalRefreshUpdateSource.next(options);
  }

  startRefreshTime(options) {
    this.globalRefreshUpdateStartSource.next(options);
  }

  passUserLobs(data){
    this.userLob.next(data);
  }
  loadStaticChat(load) {
    this.loadStaticChatSource.next(load);
  }
  
  setChatIconStatus(status) {
    this.chatStatusSource.next(status);
    this.persistenceService.sessionStorePersist('chatOpen', status);
  }
  
  headerSearchRefresh(options) {
    this.headerSearchRefreshSource.next(options);
  }

  setUniversalLink(url) {
    this.universalLinkSource.next(url);
  }

  setContentCards(cards) {
    this.brazeContentCardSubscriber.next(cards);
  }

  chatVisibilityHidden() {
    this.chatVisibilitySource.next(false);
    this.persistenceService.sessionStorePersist('chatVisibility', false);
  }

  chatVisibilityShow() {
    this.chatVisibilitySource.next(true);
    this.persistenceService.sessionStorePersist('chatVisibility', true);
  }
}
