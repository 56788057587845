import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges, ViewEncapsulation, OnDestroy } from '@angular/core';
import { isDefined } from 'src/app/shared/services/utils.service';
import { AppStore } from '../../models/app-store';
import { SymbolQuoteService } from './services/symbol-quote.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalContentStore } from '../../store/global-content-store';
import { ErrorConstant } from '../../../core/models/app-error';
import { nasdaqSymbolsArray } from './data/symbol-quote-data';
import { ObservableSubscriptionService } from '../../services/observable-subscription.service';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-symbol-quote',
  templateUrl: './symbol-quote.component.html',
  styleUrls: ['./symbol-quote.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SymbolQuoteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mode: string;
  @Input() selectedSymbol: any;
  @Input() optionInfo: any;
  @Input() contents: any;
  @Input() customRefresh: boolean;
  @Input() orderType: string;
  @Input() refreshEnabled = true;
  @Input() titleId = '';
  @Input() quote = undefined;
  @Input() fromQuickTrade: boolean;

  @Output() quoteChange = new EventEmitter();
  @Output() errorCallback: EventEmitter<any> = new EventEmitter<any>();
  @Output() quoteCallBack: EventEmitter<any> = new EventEmitter<any>();
  @Output() successCallback: EventEmitter<any> = new EventEmitter<any>();
  @Output() hidepage: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshCallback: EventEmitter<any> = new EventEmitter<any>();

  loading = false;
  chartType = 'Quotes>Quote List>Quote Detail Equity';
  dash = '-';
  hasError = false;
  errorDisplay = '';
  isExpanded = false;
  noResults = false;
  errObject: any;
  formError: any = {};
  openIdAria = true;
  showChart = false;
  EAAErrorFlag = false;

  quoteSubscriber: any;
  showSharesLabel: boolean = false;

  constructor(
    private router: Router,
    public appStore: AppStore,
    public service: SymbolQuoteService,
    public globalContent: GlobalContentStore,
    public subscriptionService: ObservableSubscriptionService,
    public commonService: CommonService
  ) { }

  ngOnDestroy() {
    if (isDefined(this.quoteSubscriber)) {
      this.quoteSubscriber.unsubscribe();
    }
  }

  ngOnInit() {

  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    //  ngDoCheck(){
    if (isDefined(simpleChanges.quote) && isDefined(this.quote)) {
      if (this.quote.MarketId) {
        this.showSharesLabel = (nasdaqSymbolsArray.indexOf(this.quote.MarketId) !== -1);
      }
      this.EAAErrorFlag = false;
      if (this.quote.Errors) {
        this.hasError = true;
        this.errorDisplay = this.quote.Errors.ErrorMessage;
        this.quoteChange.emit(this.quote);
        this.showChart = false;
        if (this.quote.Errors.ErrorCode === ErrorConstant.QUOTEEAAERROR) {
          this.EAAErrorFlag = true;
        }
      } else {
        this.showChart = (this.quote.showChart) ? this.quote.showChart : false;
        console.log('Quote' + JSON.stringify(this.quote));
      }
    } else if (isDefined(simpleChanges.selectedSymbol) && isDefined(this.selectedSymbol)) {
      const request = { Symbols: [this.selectedSymbol] };
      if (isDefined(this.optionInfo)) {
        request.Symbols[0].OptionInfo = this.optionInfo;
      }

      if (isDefined(this.orderType)) {
        Object.assign(request, { OrderType: this.orderType });
      }

      // Order Type is required for Stocks ETB validation
      // Object.assign(request, this.orderType != null ? { OrderType: this.orderType } : null);

      if (isDefined(this.quoteSubscriber)) {
        this.quoteSubscriber.unsubscribe();
      }

      this.loading = true;
      this.EAAErrorFlag = false;
      this.quoteSubscriber = this.service.getQuote(request).subscribe((data: any) => {
        if (data && data.Quotes) {
          this.quote = data.Quotes[0];
          this.quoteChange.emit(this.quote);
          this.quoteCallBack.emit(this.quote);
          this.showSharesLabel = (nasdaqSymbolsArray.indexOf(this.quote.MarketId) !== -1);
          console.log('Quote' + JSON.stringify(this.quote));
          if (data.quote === undefined) {
            this.noResults = true;
          } else {
            this.noResults = false;
          }
          this.loading = false;
          this.hasError = false;
          this.successCallback.emit();
        } else {
          this.loading = false;
          this.hasError = false;
          this.errorCallback.emit();
        }
      },
        (err: HttpErrorResponse) => {
          this.loading = false;
          if (err.status === 406) {
            if (err && err.error && err.error.Exception && err.error.Exception.ErrorCode === ErrorConstant.SESSIONTIMEOUT) {
              throw err;
            } else if (err && err.error && err.error.Exception && err.error.Exception.ErrorCode === ErrorConstant.QUOTEEAAERROR) {
              this.hasError = true;
              this.EAAErrorFlag = true;
              this.errorDisplay = err.error.Exception.ErrorMessage;
            } else {
              this.errorCallback.emit(err.error);
            }
          } else if (err.error) {
            console.log('error');
            // if (this.errorCallback.observers.length === 0) {
            this.hasError = true;
            // }
            const exception = err.error.Exception;
            if (isDefined(exception)) {
              if (isDefined(exception[0])) {
                if (isDefined(exception[0].Category)) {
                  this.errorCallback.emit(exception[0]);
                } else {
                  this.errorDisplay = exception[0].ErrorMessage;
                  this.errorCallback.emit(exception[0]);
                }
              } else {
                if (isDefined(exception.Category)) {
                  this.errorCallback.emit(exception);
                } else {
                  this.errorDisplay = exception.ErrorMessage;
                  this.errorCallback.emit(exception);
                }
              }
            }
          }
        });
    } else {
      if (isDefined(this.quote)) {
        // do nothing
      } else {
        this.quote = undefined;
        this.quoteChange.emit(this.quote);
      }
    }
  }

  refresh() {
    let button = document.getElementById('new-refresh-button-' + this.titleId);
    if (button) {
      button.className = "new-quote-refresh-button icon-Reload2";
      requestAnimationFrame(() => {
        button.className = "new-quote-refresh-button icon-Reload2 refresh-animate"
      });
    }
    if (this.customRefresh) {
      this.refreshCallback.emit();
    } else if (isDefined(this.selectedSymbol)) {
      const request = { Symbols: [JSON.parse(JSON.stringify(this.selectedSymbol))] };
      if (isDefined(this.optionInfo)) {
        request.Symbols[0].OptionInfo = this.optionInfo;
      }
      // Order Type is required for Stocks ETB validation
      if (isDefined(this.orderType)) {
        Object.assign(request, { OrderType: this.orderType });
      }

      if (isDefined(this.quoteSubscriber)) {
        this.quoteSubscriber.unsubscribe();
      }

      let loadingcheck = true;
      let loadingSub = undefined;
      setTimeout(() => {
        if (loadingcheck && loadingSub == this.quoteSubscriber) {
          this.loading = true;
        }
      }, 1000);
      this.EAAErrorFlag = false;
      this.quoteSubscriber = this.service.getQuote(request).subscribe((data: any) => {
        if (data && data.Quotes) {
          this.quote = data.Quotes[0];
          this.quoteChange.emit(this.quote);
          this.quoteCallBack.emit(this.quote);
          this.showSharesLabel = (nasdaqSymbolsArray.indexOf(this.quote.MarketId) !== -1);
          console.log('Quote' + JSON.stringify(this.quote));
          if (data.quote === undefined) {
            this.noResults = true;
          } else {
            this.noResults = false;
          }
          loadingcheck = false;
          this.loading = false;
          this.hasError = false;
          this.successCallback.emit();
        } else {
          loadingcheck = false;
          this.loading = false;
          this.hasError = false;
          this.errorCallback.emit();
        }
      },
        (err: HttpErrorResponse) => {
          console.log('error');
          loadingcheck = false;
          this.loading = false;
          if (err.status === 406) {
            this.errorCallback.emit(err.error);
            if (err.error.Exception.ErrorCode === ErrorConstant.SESSIONTIMEOUT) {
              throw err;
            }
          } else if (isDefined(err.error)) {
            if (this.errorCallback.observers.length === 0) {
              this.hasError = true;
            }
            const exception = err.error.Exception;
            if (isDefined(exception[0])) {
              if (isDefined(exception[0].Category)) {
                this.errorCallback.emit(exception[0]);
              } else {
                this.errorDisplay = exception[0].ErrorMessage;
                this.errorCallback.emit(exception[0]);
              }
            } else {
              if (isDefined(exception.Category)) {
                this.errorCallback.emit(exception);
              } else {
                this.errorDisplay = exception.ErrorMessage;
                this.errorCallback.emit(exception);
              }
            }
          }
        });
      loadingSub = this.quoteSubscriber;
    } else {
      this.quote = undefined;
      this.quoteChange.emit(this.quote);
    }
  }

  addCommas(text) {
    if (text.length >= 3 && text != this.dash) {
      if (this.appStore.lang.toLowerCase() == 'en') {
        return text.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return text.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    } else {
      return text;
    }
  }

  toggleSize() {
    if (!(this.isExpanded)) {
      this.isExpanded = true;
    } else {
      this.isExpanded = false;
    }
  }
  valueOrDash(value) {
    if (isDefined(value)) {
      return value;
    }
    return this.dash;
  }
  valueOrDashContent(value) {
    if (isDefined(value)) {
      return value.Content;
    }
    return this.dash;
  }
  valueOrDashData(value) {
    if (isDefined(value)) {
      return value.Data;
    }
    return this.dash;
  }

  isOpenInterestDefined() {
    let isOpenInterest = false;
    if (isDefined(this.quote)) {
      if (isDefined(this.quote.OpenInterest)) {
        isOpenInterest = true;
      }
    }
    return isOpenInterest;
  }

  isMutualFund() {
    let isMutualFundQuote = false;
    if (isDefined(this.quote)) {
      if (this.quote.QuoteType === '4') {
        isMutualFundQuote = true;
      }
    }
    return isMutualFundQuote;
  }

  isExpiryTypeUndefined(mode) {
    let isExpiryType = true;
    let userSignedOn = this.appStore.isLoggedIn();
    if (userSignedOn === undefined) {
      userSignedOn = false;
    }

    if (mode === 'quote-norefresh' && userSignedOn) {
      if (isDefined(this.quote)) {
        if (isDefined(this.quote.ExpiryType)) {
          isExpiryType = false;
        }
      }
    }
    return isExpiryType;
  }

  isPositive(priceChange) {
    if (isDefined(priceChange)) {
      return priceChange > 0.00;
    }
    // @ifdef DEBUG
    console.log('isPositive=false');
    // @endif
    return false;
  }
  isNegative(priceChange) {
    if (isDefined(priceChange)) {
      return priceChange < 0.00;
    }
    // @ifdef DEBUG
    console.log('isNegative=false');
    // @endif
    return false;
  }


  getFlag(flagName): string {
    if (flagName === undefined) {
      return null;
    } else {
      let response = '';

      switch (flagName.toLowerCase()) {
        case 'ca':
          response = './assets/images/icons/icon-CDN-flag.svg';
          break;
        case 'cdn':
          response = './assets/images/icons/icon-CDN-flag.svg';
          break;
        case 'usd':
          response = './assets/images/icons/icon-USD-flag.svg';
          break;
        case 'us':
          response = './assets/images/icons/icon-USD-flag.svg';
          break;
      }

      return response;
    }
  }

  nullFlag(flagName) {
    return !(flagName === undefined);
  }

  // This Method is for Account Holding Details Page when View More Details link is displaying.
  getQuoteDetail(symbol) {
    // console.log("Symbol #######" + symbol);
    // let quotereq = { PermissionInfoReq: { Permission: [] },
    //   QuoteListReq: { Symbols: [{ SymbolName: symbol.SymbolName, Market: symbol.Market }] }
    // };
    // let pageReq = { PageName: "QuoteDetails" };
    // let payload = [
    //   { request: pageReq, url: this.APIServices['pageContent'].url },
    //   { request: quotereq, url: this.APIServices["quoteDetail"].url }
    // ];
    // this.loadingService.present();
    // let pageContentresp:any;
    // this.service.init(quotereq, this.APIServices["quoteDetail"].url).subscribe((data: any) => {
    //   if (data) {
    //     let obj = null;
    //     let quoteres = data;
    //     this.service.init(pageReq, this.APIServices["pageContent"].url).subscribe((data: any) => {
    //       pageContentresp = data;
    //       obj = { "pageContent": pageContentresp.Contents, "isWatchList": false, "quote": quoteres, "listName": "" };
    //       let pageObj = { req: quotereq, resp: quoteres, params: obj };
    //       RootService.setPageName("QuoteDetails");
    //       RootService.setPageObj(RootService.getPageName(), pageObj);
    //       this.navCtrl.push(RootService.getPageName(), null, {animate: false});
    //     },
    //     (err: HttpErrorResponse) => {

    //     });
    //   } else {
    //     this.loadingService.dismiss();
    //   }
    // },
    // (err: HttpErrorResponse) => {
    //   this.hasError = true;
    //   // Refreshing charts() on error with latest data on Holding Details Symbol-Quote component when QuoteDetails failed
    //   // Otherwise on Quotedetails() fail - Symbol quote on Holding details became No data Available - Try again even
    //   // quote is available
    //   this.refresh();
    //   this.loadingService.dismiss();
    //   if (err.error instanceof Error) {
    //     // console.log('An error occurred:', err.error.message);
    //     this.errObject = this.errorHandler.handleAppError(err);
    //     return this.errObject;
    //   } else {
    //     // console.log(`Backend returned code ${err.status}, body was: ${err.error}`);
    //     this.errObject = this.errorHandler.handleAppError(err);
    //     if (this.errObject.reason === 'FORM_VALIDATION_ERROR') {
    //       //this.scrollToTopError();
    //       return this.errObject;
    //     } else {
    //       this.errorEventHandler.setError(this.errObject);
    //       if (this.errObject.reason == 'PAGE_ERROR') {
    //         //this.scrollToTopError();
    //       }
    //       return this.errObject;
    //     }
    //   }

    // });
  }
  updateHidepage(val) {
    // this.hidepage = val;
    console.log('Modal opened / closed' + val);
    this.hidepage.emit(val);
  }

  isWhite(mode) {
    return mode === 'compact-small-order' || mode === 'compact-large-order';
  }

  isDisplay(mode) {
    return mode === 'full-small' || mode === 'full-large' || mode === 'compact-small' || mode === 'compact-large' || mode === 'compact-small-order' || mode === 'compact-large-order';
  }

  isTopDisplay(mode) {
    return mode === 'full-small' || mode === 'full-large' || mode === 'compact-small' || mode === 'compact-large';
  }

  isDynamic(mode) {
    return mode == 'compact-small' || mode == 'compact-large' || mode == 'compact-small-order' || mode == 'compact-large-order'
  }

  isDisplayAll(mode) {
    return (mode === 'full-small' && this.isExpanded) || mode === 'full-large';
  }

  isNew(mode) {
    return mode == 'new-full' || mode == 'new-full-collapse' || mode == 'new-compact'
  }

  isNewDisplayBottom(mode) {
    return mode == 'new-full' || (mode == 'new-full-collapse' && this.isExpanded);
  }

  isNewExpandable(mode) {
    return mode == 'new-full-collapse';
  }

  // hide trade drawer
  hideTrdaeDrawer(eve) {
    if (this.fromQuickTrade && this.commonService.isDesktop() && eve.target.nodeName == 'A') {
      this.subscriptionService.openOrCloseDrawer.next();
    }
  }

  EAANavigation() {
    this.router.navigate(['/txn/userPreferences/exchangeAgreements/landing']);
  }

}
