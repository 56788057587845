import { RouteGroup } from "../shared/models/route-config";

export const UpdatePasswordConfig: RouteGroup = {
  "/updatePassword/landing": {
    name: "updatePasswordLanding",
    service: "UpdatePasswordLandingService",
    store: "UpdatePasswordLandingStore",
    resolveContent: "updatePasswordLanding",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      }, 
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
    ],
    trackingData: {
      name: "details",
      nameGA: "Update Password Details Landing Page",
      formInfo: { name: "update-password", stepName: "landing" },
      events: { formView: true, pageView: true, formStep: true },
    },
  },
  "/updatePassword/confirm": {
    name: "updatePasswordConfirm",
    service: "UpdatePasswordConfirmService",
    store: "UpdatePasswordConfirmStore",
    resolveContent: "updatePasswordConfirm",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      }, 
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
    ],
    trackingData: {
      name: "details",
      nameGA: "Update Password Details Confirmation Page",
      formInfo: { name: "update-password", stepName: "confirm" },
      events: { formView: true, pageView: true, formStep: true },
    },
  }
};
