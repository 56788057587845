<section id="accountSelectorDD" class="A16 color02 accountSelector">
  <button id="ddButton" type="button" class="selector-button" aria-haspopup="listbox" (click)="setDropDownState(!open)"
    [ngClass]="[lob ==='cpic' ? 'cpic-button': '', regularDropdown ? 'regularDropdown-selector-button': '']">
    {{accountDisplayName}}
    <span *ngIf="(selectedAccountNumber.Currency) && (lob === 'ie' || lob === 'iis' || lob === 'cfpi')">
      <span aria-hidden="true">{{accountDisplayCurrency}}</span>
      <span class='sr-only'>{{globalcontent.list.currencyLongName[accountDisplayCurrency]}}</span>
    </span>
    <span class="select-icon icon-Arrow-Down color03" aria-hidden="true"></span>
    <div *ngIf="(lob === 'wg' || lob === 'cpic') && subAccountDisplayName !== ''" class="A14 color03 subAccountCpic">
      {{subAccountDisplayName}}
      <span aria-hidden="true">{{accountDisplayCurrency}}</span>
      <span class='sr-only'>{{globalcontent.list.currencyLongName[accountDisplayCurrency]}}</span>
    </div>
  </button>
  <div id="results" class="results" *ngIf="open"
    [ngClass]="{'cpic-button': lob ==='cpic', 'cpicResults': (lob ==='wg' || lob === 'cpic'), 'dropdown-full-width': dropdownFullWidth}">
    <ul role="listbox">
      <li class="group" id="ASDD{{account.Id}}" *ngFor="let account of singleAccountList; let i= index;"
        (click)="accountSelected(account)" role="option" tabindex="0" (keyup)="onKeyUp($event)"
        (keydown)="onKeyDown($event)" (blur)="onBlur($event)" (mouseenter)="onMouseEnter($event)">
        <div *ngIf="!account.IsSubAccount && lob !=='cpic'"
          [ngClass]="{'account': lob !== 'wg', 'accountWG': lob == 'wg'}" [class.border-top]="i !== 0">
          {{account.AccountFriendlyName ? account.AccountFriendlyName :
          this.getAccountTypeDesc(account)}}
          {{account.HypothecationInd}} ({{account.AccountNumber}}) <span *ngIf="!hideCombinedText"> -
            {{pageContent.text.combined}}</span>
        </div>
        <div *ngIf="!account.IsSubAccount && lob ==='cpic'"
          [ngClass]="{'account': lob !== 'cpic', 'accountWG': lob == 'cpic'}" [class.border-top]="i !== 0">
          {{account.AccountFriendlyName}} - {{account.AccountNumber}}
        </div>
        <div *ngIf=" account.IsSubAccount && (lob==='ie' || lob==='iis' || lob==='cfpi' )" class="subAccount color03">
          {{this.getAccountType(account)}} ({{account.AccountNumber}}) -
          <span aria-hidden="true">{{account.Currency}}</span>
          <span class='sr-only'>{{globalcontent.list.currencyLongName[account.Currency]}}</span>
        </div>
        <div *ngIf="account.IsSubAccount && lob ==='wg'" class="subAccountWG color03">
          ({{account.SubAccountNumber}}) {{this.getAccountType(account)}} -
          <span aria-hidden="true">{{account.Currency}}</span>
          <span class='sr-only'>{{globalcontent.list.currencyLongName[account.Currency]}}</span>
        </div>
        <div *ngIf="account.IsSubAccount && lob ==='cpic'" class="subAccountWG  color03">
          {{account.SubAccountFriendlyName}}
        </div>
      </li>
    </ul>
  </div>
</section>