import { RoutePermissionService } from './../../services/route-permission.service';
import { DOCUMENT, Location } from '@angular/common';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { AppStore } from 'src/app/shared/models/app-store';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { Component, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Lang, Lob } from 'src/app/shared/models/lob.enum';
import { PersistenceService } from 'src/app/core/services/persistence.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { SignonStore } from './../../../modules/pre-txn/signon/data/signon-store';
import { ContentService } from 'src/app/core/services/content.service';
import { Subscription } from 'rxjs';
import { GlobalSignOffService } from 'src/app/core/services/global-signoff.service';
import { HttpService } from 'src/app/core/services/http.service';
import { isDefined } from 'src/app/shared/services/utils.service';
import { _User } from '../../models/user';
declare let require: any;
const gatewayConfig = require('../../../config/gateway-config.json');

@Component({
  selector: 'app-pre-header',
  templateUrl: './pre-header.component.html',
  styleUrls: ['./pre-header.component.scss']
})

export class PreHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentPage: string;
  subscription: Subscription;
  lob: any;
  currentLob = this.appStore.state.user.lob;
  isSSO = false;
  isApp = false;

  onlineRegisterPages: any = {
    "#/onlineRegistration/password": true,
    "#/onlineRegistration/username": true,
    "#/onlineRegistration/mailingOptions": true
  }

  globalHeaderExcludeList: any = {
    "#/signon": true,
    "#/registerOnline/landing": true,
    "#/registerOnline/createPassword": true,
    "#/registerOnline/confirm": true,
    "#/signOn/forgotSignOnPassword": true,
    "#/signOn/changeSignOnPassword": true,
    "#/signOn/changeSignOnPass/confirm": true
  }

  showNav: boolean = true;
  lastScrollPos: number;
  lang: string;
  isSourceDCO:boolean = false;
  queryParamDco:any;
  queryParamLocale: any;
  get LOB() { return Lob; }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.showNav = this.lastScrollPos > event.target.scrollingElement.scrollTop;
    if (event.target.scrollingElement.scrollTop <= 48) {
      this.showNav = true;
    }

    this.lastScrollPos = event.target.scrollingElement.scrollTop;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public globalContent: GlobalContentStore,
    public appStore: AppStore,
    private _subscriptionService: ObservableSubscriptionService,
    private _persistenceService: PersistenceService,
    private _commonService: CommonService,
    private _routePermissionService: RoutePermissionService,
    private _contentService: ContentService,
    private location: Location,
    private globalSignOffService: GlobalSignOffService,
    private httpService: HttpService,
    private persistenceService: PersistenceService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    const storedDco = this.persistenceService.sessionStoreRetrieve('queryParamDco');
    const storedLocale = this.persistenceService.sessionStoreRetrieve('queryParamLocale');
    const queryParams = this.route.snapshot.queryParams;
    if(storedDco !== 'undefined' && storedDco !== null) {
      this.queryParamDco = storedDco;
    }
    else {
      if(queryParams["prtId"] !== 'undefined' && queryParams["prtId"] !== null){
        this.queryParamDco = queryParams["prtId"];
      }
    }
    if(storedLocale !== 'undefined' && storedLocale !== null) {
      this.queryParamLocale = storedLocale;
    } else {
      if(queryParams["locale"] !== 'undefined' && queryParams["locale"] !== null){
        this.queryParamLocale = queryParams["locale"];
      }
    }
    this.persistenceService.sessionStorePersist('isSourceDCO',this.isSourceDCO);
    if(this.queryParamDco !== undefined && this.queryParamDco !== null) {
      this.persistenceService.sessionStorePersist('queryParamDco',this.queryParamDco);
    }
    if(this.queryParamLocale !== undefined && this.queryParamLocale !== null) {
    this.persistenceService.sessionStorePersist('queryParamLocale',this.queryParamLocale);
    }
    this.persistenceService.sessionStorePersist('isSourceDCO',this.isSourceDCO);

    if(this.queryParamDco == 'dco' ||(storedDco == "dco" || storedLocale == "fr")){
      this.isSourceDCO = true;
      if(this.queryParamLocale == 'fr'){
        this.appStore.initUser({
          lang: Lang.FR,
          lob: Lob.IE,
          status: _User.Status.GUEST,
          platformInfo: {
            mode: this.appStore.platformInfo.mode,
            platform: this.appStore.platformInfo.platform,
            container: 'N/A',
            appVersion: 'N/A',
            version: 'N/A',
            revision: 'N/A',
            build: 'N/A',
            layout: document.body.clientWidth < 900 ? 0 : 1
          },
          userlob: undefined,
          premiumUser: false,
        }); 
      }
    }else{
      this.isSourceDCO = false;
      this.lang = this.appStore.lang;
    }
    this.persistenceService.sessionStorePersist('isSourceDCO',this.isSourceDCO);
    this.lob = this.appStore.lob;
    this.isApp = this.appStore.isApp('app');
    this.subscription = this._subscriptionService.stringLob.subscribe(lobData => {
      if (lobData) {
        this.currentLob = Lob[lobData.toUpperCase()];
        this.lob = this.appStore.lob;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isUrlLangOrLobChangeAllowed(changes)) {
      const langFromPreviousUrl = this._commonService.getLangFromUrl(changes.currentPage.previousValue, 2);
      const langFromCurrentUrl = this._commonService.getLangFromUrl(changes.currentPage.currentValue, 2);

      if (this.isURLLocaleChanged(langFromPreviousUrl, langFromCurrentUrl)) {
        this.processLocaleChange(langFromCurrentUrl);
      }

      const lobFromPreviousUrl = this._commonService.getLOBFromUrl(changes.currentPage.previousValue, 3);
      const lobFromCurrentUrl = this._commonService.getLOBFromUrl(changes.currentPage.currentValue, 3);
      if (this.isURLLOBChanged(lobFromPreviousUrl, lobFromCurrentUrl)) {
        //console.log('LOB changed from ' + lobFromPreviousUrl + ' to ' + lobFromCurrentUrl);
        this.processLOBChange(lobFromCurrentUrl);
      }
    }
    let urlHash = window.location.hash;
    urlHash = urlHash.substring(1);
    const sval = urlHash.split('/');
    if (sval[1] === 'SSOpage') {
      this.isSSO = true;
    } else {
      this.isSSO = false;
    }
  }

  private isUrlLangOrLobChangeAllowed(changes: SimpleChanges) {
    // process URL or LOB change only when there is a change in home component URL
    return changes.currentPage && changes.currentPage.previousValue && changes.currentPage.currentValue &&
      changes.currentPage.previousValue.indexOf('/home/') > -1 && changes.currentPage.currentValue.indexOf('/home/') > -1;
  }

  /**
   * return true when locale in url is updated, for ex, either manually or via a bookmark
   * If URL changed from /en/ to /fr/ OR /fr/ to /en/ AND if the app lang value does not match the locale in changed url
   * @param langFromPreviousUrl, langFromCurrentUrl
   * @private
   */
  private isURLLocaleChanged(langFromPreviousUrl: string, langFromCurrentUrl: string) {
    let isURLLocaleChanged = false;
    if (langFromPreviousUrl && langFromCurrentUrl &&
      langFromPreviousUrl !== langFromCurrentUrl) {
      isURLLocaleChanged = true;
    }
    return isURLLocaleChanged;
  }

  /**
   * return true when LOB in url is updated, for ex, either manually or via a bookmark
   * If URL changed from one lob to another valid lob AND if the app lob value does not match the lob in changed url
   * @param lobFromPreviousUrl, lobFromCurrentUrl
   * @private
   */
  private isURLLOBChanged(lobFromPreviousUrl: string, lobFromCurrentUrl: string) {
    let isURLLOBChanged = false;
    if (lobFromPreviousUrl && lobFromCurrentUrl &&
      lobFromPreviousUrl !== lobFromCurrentUrl && lobFromCurrentUrl !== this.appStore.lob) {
      isURLLOBChanged = true;
    }
    return isURLLOBChanged;
  }

  processLocaleChange(lang: Lang) {
    this._subscriptionService.passLocale(lang);
  }

  processLOBChange(lob: Lob) {
    this.appStore.lob = lob;
    this.lob = this.appStore.lob;
    this._persistenceService.cookieStorePersist('MBI_LOB', lob);
    this._subscriptionService.passLob(lob.toLowerCase());
  }

  toggleLocale() {
    if (this.appStore.lang === Lang.EN) {
      this._subscriptionService.passLocale('fr');
    } else {
      this._subscriptionService.passLocale('en');
    }
  }

  appInfo() {
    this.router.navigate(['/_app/info']);
  }

  isDesktop() {
    return this._commonService.isDesktop();
  }

  isToggleLocaleEnable() {
    return this._routePermissionService.enableToggleLocaleButton && !this.appStore.signonHashingFlow;
  }

  isPreSignoffEnable() {
    //setTimeout(() => {
      return this._routePermissionService.enablePreSignoff;
    //}, 100);
  }

  signOff() {
    this.globalSignOffService.signOff();
  }

  goToOnlineBanking() {
    this.getConnectOnlineURL().subscribe(
      (data: any) => {
        if (data) {
          const url = data.ssourl;
          window.location.href = url;
        }
        // this.loadingService.dismiss();
      },
      (err) => {
        // super.handleError(err);
        if (isDefined(err.error.Exception) && err.error.Exception !== null && isDefined(err.error.Exception.ErrorCode) &&
          err.error.Exception.ErrorCode === 'OLBSSO406') {
          window.location.href = err.error.Exception.OlbRedirecturl;
        } else {
          // Should be External Signon - AEM page For now redirecting to OBR2 Signon
          // this.router.navigate(['/signon']);
          throw err;
        }
      });
  }

  getConnectOnlineURL() {
    return this.httpService.post(gatewayConfig.APIServices.goToBanking.url, {});
  }

  onlineRegister() {
    const loc = window.location.hash;
    if (this.onlineRegisterPages[loc] || loc.startsWith('#/onlineRegistration/verification')) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  displayGlobalHeader() {
    const loc = window.location.hash;
    if (this.lob == Lob.IE && (this.globalHeaderExcludeList[loc] || loc.indexOf("/home/") != -1 )) {
      return false;
    } else {
      return true;
    }
  }
}
