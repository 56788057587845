// replaced dyamically during build by jenkins

export interface BuildInfo {
    id: string;
    num: string;
    branch: string;
    tag: string;
}

export const buildInfo: BuildInfo = {
    id: '168222',
    num: '20250115.6',
    branch: 'EWUM-Pilot-cleanup',
    tag: '1.0.350-snapshot-sit3-both'
};

