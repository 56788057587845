import { Observable, throwError } from 'rxjs';
import { finalize, map, catchError, tap, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { SpinnerService } from '../components/spinner/service/spinner.service';
import { AppStore } from 'src/app/shared/models/app-store';
import { PersistenceService } from '../services/persistence.service';
import { ROOT_CTX } from 'src/app/shared/models/user';
import { HttpService } from 'src/app/core/services/http.service';
import { isDefined } from 'src/app/shared/services/utils.service';
import { GlobalSignOffService } from '../services/global-signoff.service';

declare let require: any;
const gatewayConfig = require('../../config/gateway-config.json');

@Injectable()
export class AppHttpInterceptor {
  constructor(
    private _spinnerService: SpinnerService,
    private appStore: AppStore,
    private persistenceService: PersistenceService,
    private httpService: HttpService,
    private globalSignoffService: GlobalSignOffService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
  | HttpSentEvent
  | HttpHeaderResponse
  | HttpProgressEvent
  | HttpResponse<any>
  | HttpUserEvent<any>
  | HttpEvent<any>
  | HttpErrorResponse
  > {
    if (
      isDefined(this.appStore.version) &&
      Date.now() - this.appStore.version.time > 1000 * 60 * 60 * 1
    ) {
      this.appStore.version.time = Date.now();
      this.httpService.getVersion().subscribe((data) => {
        if (data) {
          if (data.version !== this.appStore.version.number) {
            this.globalSignoffService.newVersion();
          }
        } else {
          // File not found or some error so considering as new version available
          this.globalSignoffService.newVersion();
        }
      });
    }
    let timeoutNum = (window as any).RequestTimeoutAmount;
    if((window as any).criticalAPIs) {
      if(gatewayConfig.APIServices) {
        const key = Object.entries(gatewayConfig.APIServices).find(([key, val]) => {
        if(req.url.toString().includes(val['url'])){
          return true
        }
        })?.[0];
        if(isDefined(key)) {
          if((window as any).criticalAPIs.indexOf(key) > -1) {
            req = req.clone({
              setParams: {
                critical: "true"
              }
            });
          }
        }
      }
    }
    if (req.params.get('critical') === 'true') {
      timeoutNum = (window as any).RequestCriticalTimeoutAmount;
    }
    const showSpinner = req.url.includes('silent=1') ? false : true;

    this.persistenceService.sessionStorePersist(
      'LAST_ACTIVITY',
      Date.now()
    );
    if (req.url.startsWith(ROOT_CTX)) {
      this.persistenceService.deletecookiespecificStorePersist(
        'XSRF-TOKEN'
      );
      this.persistenceService.deletecookiespecificStorePersist(
        'C-OBR2-TT'
      );
      const authReq = req.clone({
        headers: new HttpHeaders({
          'X-MODE': this.appStore.state.user.platformInfo.layout.toString(),
          'H-OBR2-TT': this.persistenceService.cookieStoreRetrieve(
            'C-OBR2-TT'
          ),
        }),
        url: req.url.replace('?silent=1', ''),
      });
      if (showSpinner && !(req.params.get('skiploading') === 'true')) {
        if (req.url == "/mobi" + gatewayConfig.APIServices.stocksVerify.url || req.url == "/mobi" + gatewayConfig.APIServices.stocksSubmit.url ||
          req.url == "/mobi" + gatewayConfig.APIServices.optionsVerify.url || req.url == "/mobi" + gatewayConfig.APIServices.optionsSubmit.url ||
          req.url == "/mobi" + gatewayConfig.APIServices.stocksChangeVerify.url || req.url == "/mobi" + gatewayConfig.APIServices.stocksChangeSubmit.url
          || req.url == "/mobi" + gatewayConfig.APIServices.createFxOrder.url) {
          this._spinnerService.showLoadingText(true);
        }
        this._spinnerService.onStarted(authReq);
      } else {
      }
      return next.handle(authReq).pipe(
        timeout(timeoutNum),
        finalize(() => {
          if (
            showSpinner &&
            !(req.params.get('skiploading') === 'true')
          ) {
            this._spinnerService.onFinished(authReq);
          } else {
          }
        }),
        // catchError((error: HttpErrorResponse) => {
        //   if (error.status === 401) {
        //     // refresh token
        //   } else {
        //     return throwError(error);
        //   }
        // }),
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const url = authReq.url;
            // temporary fix for time stamp, timeout should not be given, not getting value of current time stamp
            if (url.indexOf('json') === -1) {
              const currentTimeStamp = event.headers.get(
                'server_timestamp'
              );
              this.appStore.currentTimeStamp = currentTimeStamp;
            }
          }
          return event;
        })
      );
    } else {
      const authReq = req.clone();
      if (showSpinner && !(req.params.get('skiploading') === 'true')) {
        this._spinnerService.onStarted(authReq);
      } else {
      }
      return next.handle(authReq).pipe(
        timeout(timeoutNum),
        finalize(() => {
          if (
            showSpinner &&
            !(req.params.get('skiploading') === 'true')
          ) {
            this._spinnerService.onFinished(authReq);
          } else {
          }
        })
      );
    }
  }
}
