import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { PersistenceService } from './persistence.service';
import { SsoService } from '../../modules/pre-txn/sso/sso.service';
import { CommonService } from '../../shared/services/common.service';
import { AppStore } from '../../shared/models/app-store';
import { SignonStore } from '../../modules/pre-txn/signon/data/signon-store';
import { isDefined } from '../../shared/services/utils.service';
import { _User } from '../../shared/models/user';
import { AppErrorHandlerService } from '../components/error/services/app-error-handler.service';
import { AccountHoldingsStore } from 'src/app/modules/txn/accounts/account-holdings/data/account-holdings-store';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { of } from 'rxjs';
declare let require: any;
declare let StatusBar: any;
declare let BrazePlugin: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class GlobalSignOffService {


  constructor(
    private router: Router,
    private httpService: HttpService,
    private ssoService: SsoService,
    private _commonService: CommonService,
    private appStore: AppStore,
    private persistanceService: PersistenceService,
    private errorService: AppErrorHandlerService,
    private trackingService: TrackingService
  ) { }

  signOff(sessionTimeout?: boolean, SessionTimeoutDelay?: boolean) {
    const req: any = {};
    this.signOffOmnitureTagging();

    this.httpService.post(gatewayConfig.APIServices.signoff.url, req).subscribe((res) => {
      // to clear holdings store, to be removed in R4A release
      if (AccountHoldingsStore.params && AccountHoldingsStore.params.accountNumber.AccountNumber) {
        this.clearAccountHoldingParams();
      }
      this.persistanceService.sessionStorageClear();
      // clear json cache map while logging off
      this.httpService.responseCache.clear();
      clearTimeout(this.ssoService.keepAliveTimeOut);
      SignonStore.crossFlow = { signoff: true };
      this.clearsessions();
      if (isDefined(res) && res !== null && isDefined(res.olbsignoffUrlforSSO) && !this.appStore.isApp("inapp")) {
        // This is to signoff OLB side on our Signoff success for SSO (OLB_OBR2)
        const image = document.createElement('img');
        image.src = res.olbsignoffUrlforSSO;
        document.getElementById('imageDivId').appendChild(image);
      }
      if (this.appStore.isApp("inapp")) {
        if(this.appStore.state.user.premiumUser) {
          this.appStore.state.user.premiumUser = false;
          StatusBar.styleDefault();
        }
        if (sessionTimeout) {
          if (SessionTimeoutDelay) {
            setTimeout(() => {
              if(this.appStore.isApp('app')){
                if(isDefined(BrazePlugin)){
                  BrazePlugin.hideCurrentInAppMessage();
                }
              } else {
                if(this.appStore.brazeInAppMessage) {
                  this.appStore.brazeInAppMessage.closeMessage();
                  this.appStore.brazeInAppMessage = undefined;
                }
              }
              this.router.navigate(['/sessionEnd']);
            }, 2000)
          } else {
            if(this.appStore.isApp('app')){
              if(isDefined(BrazePlugin)){
                BrazePlugin.hideCurrentInAppMessage();
              }
            } else {
              if(this.appStore.brazeInAppMessage) {
                this.appStore.brazeInAppMessage.closeMessage();
                this.appStore.brazeInAppMessage = undefined;
              }
            }
            this.router.navigate(['/sessionEnd']);
          }
        } else {
          window.location.href = "cibcbanking://relaybacksso";
        }
        this.persistanceService.deletecookiespecificStorePersist("ssoToken");
        this.persistanceService.deletecookiespecificStorePersist("ssoIndicator");
      } else {
        if(this.appStore.isApp('app')){
          if(isDefined(BrazePlugin)){
            BrazePlugin.hideCurrentInAppMessage();
          }
        } else {
          if(this.appStore.brazeInAppMessage) {
            this.appStore.brazeInAppMessage.closeMessage();
            this.appStore.brazeInAppMessage = undefined;
          }
        }
        this.router.navigate(['/signon']);
      }
    }, (err) => {
      // to clear holdings store, to be removed in R4A release
      if (AccountHoldingsStore.params && AccountHoldingsStore.params.accountNumber.AccountNumber) {
        this.clearAccountHoldingParams();
      }
      this.persistanceService.sessionStorageClear();
      clearTimeout(this.ssoService.keepAliveTimeOut);
      SignonStore.crossFlow = { signoff: true };
      this.clearsessions();
      if (this.appStore.isApp("inapp")) {
        if (sessionTimeout) {
          if (SessionTimeoutDelay) {
            setTimeout(() => {
              if(this.appStore.isApp('app')){
                if(isDefined(BrazePlugin)){
                  BrazePlugin.hideCurrentInAppMessage();
                }
              } else {
                if(this.appStore.brazeInAppMessage) {
                  this.appStore.brazeInAppMessage.closeMessage();
                  this.appStore.brazeInAppMessage = undefined;
                }
              }
              this.router.navigate(['/sessionEnd']);
            }, 2000)
          } else {
            if(this.appStore.isApp('app')){
              if(isDefined(BrazePlugin)){
                BrazePlugin.hideCurrentInAppMessage();
              }
            } else {
              if(this.appStore.brazeInAppMessage) {
                this.appStore.brazeInAppMessage.closeMessage();
                this.appStore.brazeInAppMessage = undefined;
              }
            }
            this.router.navigate(['/sessionEnd']);
          }
        } else {
          window.location.href = "cibcbanking://relaybacksso";
        }
        this.persistanceService.deletecookiespecificStorePersist("ssoToken");
        this.persistanceService.deletecookiespecificStorePersist("ssoIndicator");
      } else {
        if(this.appStore.isApp('app')){
          if(isDefined(BrazePlugin)){
            BrazePlugin.hideCurrentInAppMessage();
          }
        } else {
          if(this.appStore.brazeInAppMessage) {
            this.appStore.brazeInAppMessage.closeMessage();
            this.appStore.brazeInAppMessage = undefined;
          }
        }
        this.router.navigate(['/signon']);
      }
    });
  }

  newVersion() {
    if (this._commonService.isUserSignedOn()) {
      const req: any = {};
      this.httpService.post(gatewayConfig.APIServices.signoff.url, req).subscribe((res) => {
        this.persistanceService.sessionStorageClear();
        clearTimeout(this.ssoService.keepAliveTimeOut);
        this.clearsessions();
        if (isDefined(res) && res !== null && isDefined(res.olbsignoffUrlforSSO)) {
          // This is to signoff OLB side on our Signoff success for SSO (OLB_OBR2)
          const image = document.createElement('img');
          image.src = res.olbsignoffUrlforSSO;
          document.getElementById('imageDivId').appendChild(image);
        }
        this._commonService.reloadURL('#/signon', '#/home/' + this.appStore.lang.toLowerCase() + '/' + this.appStore.lob.toLowerCase());
      });
    } else {
      this._commonService.reloadURL('#/signon', '#/home/' + this.appStore.lang.toLowerCase() + '/' + this.appStore.lob.toLowerCase());
    }
  }

  signOffException(error: any, message?: string) {
    const req: any = {};
    this.errorService.sendException(error).then((status) => {
      this.httpService.post(gatewayConfig.APIServices.signoff.url, req).subscribe((res) => {
        this.persistanceService.sessionStorageClear();
        clearTimeout(this.ssoService.keepAliveTimeOut);
        this.clearsessions();
        if (isDefined(res) && res !== null && isDefined(res.olbsignoffUrlforSSO)) {
          // This is to signoff OLB side on our Signoff success for SSO (OLB_OBR2)
          const image = document.createElement('img');
          image.src = res.olbsignoffUrlforSSO;
          document.getElementById('imageDivId').appendChild(image);
        }
        this.persistanceService.sessionStorePersist('hasUnexpectedError', true);
        if (isDefined(message)) {
          this.persistanceService.sessionStorePersist('unexpectedMessage', message);
        }
        this._commonService.reloadURL('#/signon', '#/signon');
      });
    });
  }

  timeOut() {
    if (AccountHoldingsStore.params && AccountHoldingsStore.params.accountNumber.AccountNumber) {
      this.clearAccountHoldingParams();
    }
    this.persistanceService.sessionStorageClear();
    clearTimeout(this.ssoService.keepAliveTimeOut);
    this.appStore.state.user.status = _User.Status.GUEST;
    if (this.appStore.isApp("inapp")) {
      this.router.navigate(['/sessionEnd']);
    } else {
      SignonStore.crossFlow = { timeout: true };
      if(this.appStore.isApp('app')){
        if(isDefined(BrazePlugin)){
          BrazePlugin.hideCurrentInAppMessage();
        }
      } else {
        if(this.appStore.brazeInAppMessage) {
          this.appStore.brazeInAppMessage.closeMessage();
          this.appStore.brazeInAppMessage = undefined;
        }
      }
      this.router.navigate(['/signon']);
    }
  }

  obr2DCOWrapperSignoff() {
    this.signOffOmnitureTagging();
    if (AccountHoldingsStore.params && AccountHoldingsStore.params.accountNumber.AccountNumber) {
      this.clearAccountHoldingParams();
    }
    this.persistanceService.sessionStorageClear();
    SignonStore.crossFlow = { signoff: true };
    this.router.navigate(['/signon']);
  }

  dcoFormSubmit(modalVals, modalService, data?) {
    modalService.close(modalVals.name, modalVals.value);
    let domain = "https://";
    if (window.location.host == 'm2.onlinebrokerage.cibc.com') {
      domain += 'm.onlinebrokerage.cibc.com';
    } else {
      domain += window.location.host;
    }
    const target = this.appStore.isApp() ? "_system" : "_self";
    const token = data?.DcoAcctOpenJwtToken;
    const lang = this.appStore.lang;
    let url = domain + "/dco/html/registerOnline.html#" + lang;
    if (token) {
      url = url + "/" + encodeURIComponent(token);
    }

    window.open(url, target);
    if (this.appStore.isApp('app')) {
      this.obr2DCOWrapperSignoff();
    } else if (this.appStore.isApp('inapp')) {
      this.signOff(true, true);
    }
  }

  clearsessions() {
    // if (this.ssoService.getPersistenceTimeStamp() !== null) {
    if (isDefined(this.appStore.retrieveSessionInfo)
      && isDefined(this.appStore.retrieveSessionInfo.OriginHost)
      && isDefined(this.appStore.retrieveSessionInfo.ObrSignOffUrl)) {
      const image = document.createElement('img');
      image.src = this.appStore.retrieveSessionInfo.OriginHost + this.appStore.retrieveSessionInfo.ObrSignOffUrl;
      document.getElementById('imageDivId').appendChild(image);
      // }
      // clears session storage, keepalive timer
      // this.persistanceService.sessionStorageClear();
      this.ssoService.clearSsoHeartbeattimer();
    }
  }
  clearAccountHoldingParams() {
    const accountNumberObj = {
      AccountNumber: '',
      AccountType: '',
      Currency: '',
      SubAccountNumber: ''
    };
    AccountHoldingsStore.params = { accountNumber: accountNumberObj };
  }

  signOffOmnitureTagging() {
    if ((window as any).TrackingEnabled) {
      let config = {
        name: "signOff",
        url: "/signOff",
        data: {
          name: "signout",
        },
      };
      of(this.trackingService.tagPageActionManually(config));
    }
  }
}
