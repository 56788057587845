import { Injectable } from '@angular/core';
import { SplashPasswordData } from './splashPassword-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class SplashPasswordStore extends PageStore<SplashPasswordData> {

    constructor() {
        super(new SplashPasswordData());
    }
}
