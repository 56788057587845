import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';



@Injectable({
  providedIn: 'root'
})
export class SplashPasswordService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return input;
  }

  optOutSplash() {
    return this.httpService.post('/v2/campaign/optout', {});
  }

  getPDFBlob(url) {
    return this.httpService.getBlob(url);
  }

  verifyMailingOptions(req) {
    return this.httpService.post('/v2/userpreference/verifyMailingOptions', req);
  }

  updateMailingOptions(req) {
    return this.httpService.post('/v2/userpreference/updateMailingOptions', req);
  }

  getMailingOption() {
    return this.httpService.post('/v2/userpreference/getMailingOption', {});
  }

  remindLater() {
    return this.httpService.post('/v2/auth/passwordMigration/skip', {});
  }

}
