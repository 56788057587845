import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AppStore } from '../models/app-store';
import { Lob } from '../models/lob.enum';
import { isDefined } from '../services/utils.service';
import { FormValidatorFactory } from '../services/cibc.formvalidator';


@Directive({
  selector: '[appPasswordCriteria]'
})

export class PasswordCriteriaDirective {
  private number = false;
  private lowerCase = false;
  private upperCase = false;
  private specialChar = false;
  private minLength = false;
  private similarChar = false;
  private sequencialChar = false;
  private notAllowedSpecialChar = false;
  @Input() comparePassword : any;
  @Input() showOnBlur;
  @Output() pwdTooltipDialog = new EventEmitter<boolean>();
  @Output() pwdHeading = new EventEmitter<any>();
  @Output() passwordStrength = new EventEmitter<any>();
  @Output() passwordStrengthLabel = new EventEmitter<boolean>();
  @Output() passwordMatchSection = new EventEmitter<boolean>();
  @Output() notAllowedSpecialCharLabel = new EventEmitter<any>();
  @Output() passwordStrengthDetails = new EventEmitter<{
    number: boolean;
    lowerCase: boolean;
    upperCase: boolean;
    specialChar: boolean;
    minLength: boolean;
    similarChar: boolean;
    sequencialChar: boolean;
  }>();
  @Output() passwordMatches = new EventEmitter<boolean>();
  constructor(
    private element: ElementRef,
    private router: Router,
    private appStore: AppStore
  ) { }
  private lowercaseRegex = /[a-z]/;
  private uppercaseRegex = /[A-Z]/;
  private numberRegex = /[0-9]/;
  private specialCharRegex = /[^a-zA-Z0-9]/;
  private notAllowedSpecialCharRegex = FormValidatorFactory.specialCharNotAllowedPattern;
  private sequencialCharRegex = /(012|123|234|345|456|567|678|789|890|901|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|yza|zab|ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ|YZA|ZAB)/;
  private similarCharRegex = /(\w)\1{2,}/;
  private spaceRegex = /\s/;

  ngOnInit() {
    if(this.appStore.lob == Lob.WG) {
      this.pwdTooltipDialog.emit(true);
      let details = {
        minLength: undefined,
        lowerCase: undefined,
        upperCase: undefined,
        number: undefined,
        specialChar: undefined,
        similarChar: undefined,
        sequencialChar: undefined
      };
      this.passwordStrengthDetails.emit(details);
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event) {
    if(!event.relatedTarget || (event.relatedTarget.type != "submit")) {
      if(!this.showOnBlur) {
        this.pwdTooltipDialog.emit(false);
      }
      //this.notAllowedSpecialCharLabel.emit(this.notAllowedSpecialChar);
      //this.pwdTooltipDialog.emit(false);
      //this.passwordMatchSection.emit(false);
    }
  }

  @HostListener('focus', ['$event'])
  onFocus() {
    const passwordInputId = this.element.nativeElement.id;
    this.pwdTooltipDialog.emit(true);
    // if (this.router.url.includes("Trading") || this.router.url.includes("trading")) {
    //   if (passwordInputId.includes("Trading") || passwordInputId.includes("trading")) {
    //     this.pwdHeading.emit(passwordInputId)
    //   }
    //   else if (passwordInputId.includes("Signon") || passwordInputId.includes("signon")) {
    //     // Do nothing
    //   } else {
    //     this.pwdHeading.emit(passwordInputId)
    //   }
    // } else if (passwordInputId.includes("Trading") || passwordInputId.includes("trading")) {
    //   this.pwdHeading.emit(passwordInputId)
    // }
  }

  @HostListener('input')
  checkPasswordCriteria() {
    const password = this.element.nativeElement.value;
    let details = {
      minLength: undefined,
      lowerCase: undefined,
      upperCase: undefined,
      number: undefined,
      specialChar: undefined,
      similarChar: undefined,
      sequencialChar: undefined
    };
    this.notAllowedSpecialChar = undefined;
    let strength;
    if(!isDefined(password)) {
      this.passwordStrengthDetails.emit(details)
      this.notAllowedSpecialCharLabel.emit({toggle: this.notAllowedSpecialChar,
        charList: password.match(this.notAllowedSpecialCharRegex)});
    }else {
      this.minLength = password.length > 7 && !this.spaceRegex.test(password);
      this.lowerCase = this.lowercaseRegex.test(password);
      this.upperCase = this.uppercaseRegex.test(password);
      this.number = this.numberRegex.test(password);
      this.specialChar = this.specialCharRegex.test(password);
      this.similarChar = this.similarCharRegex.test(password);
      this.sequencialChar = this.sequentialChars(password);
      this.notAllowedSpecialChar = !this.notAllowedSpecialCharRegex.test(password);
  
      details = {
        minLength: this.minLength,
        lowerCase: this.lowerCase,
        upperCase: this.upperCase,
        number: this.number,
        specialChar: this.specialChar,
        similarChar: this.similarChar,
        sequencialChar: this.sequencialChar
      };
      this.passwordStrengthDetails.emit(details);
      this.notAllowedSpecialCharLabel.emit({toggle: this.notAllowedSpecialChar,
        charList: password.match(this.notAllowedSpecialCharRegex)});
    }
    if (password.length < 8 || this.minLength == false ||
      this.upperCase == false || this.lowerCase == false || this.number == false || this.specialChar == false
      || this.similarChar == false || this.sequencialChar == false) {
        strength = 'weak';
    }
    if (password.length >= 8 && this.minLength == true &&
      this.upperCase == true && this.lowerCase == true && (this.number == true || this.specialChar == true) && this.similarChar == false &&
      this.sequencialChar == false) {
        strength = 'moderate';
    }
    if (password.length > 15 && this.minLength == true &&
      this.upperCase == true && this.lowerCase == true && (this.number == true || this.specialChar == true)
      && this.similarChar == false && this.sequencialChar == false) {
        strength = 'strong';
    }
    if(password == "" && this.comparePassword == "") {
      this.passwordMatches.emit(false);
      this.passwordMatchSection.emit(false);
    }else if (password === this.comparePassword){
     this.passwordMatches.emit(true);
     this.passwordMatchSection.emit(true);
    } else {
      this.passwordMatches.emit(false);
      this.passwordMatchSection.emit(true);
    }
    this.passwordStrength.emit(strength);
    this.passwordStrengthLabel.emit(true);
    if(password == "") {
      this.passwordStrengthLabel.emit(false);
      this.passwordMatchSection.emit(false);
    }
    if(this.comparePassword == "") {
      this.passwordMatchSection.emit(false);
    }
  }

  sequentialChars(text) {
    if(text.length < 3) {
      return false;
    }else {
      for(let i=0; i < text.length - 2; i++) {

        const c1 = text.charCodeAt(i);
        const c2 = text.charCodeAt(i + 1);
        const c3 = text.charCodeAt(i + 2);

        if((c1 == c2 + 1) && (c2 == c3 + 1)) {
          return true;
        }
        if((c1 + 1 == c2) && (c2 + 1 == c3)) {
          return true;
        }
        if( text.substring(i, i+3) == '890' || text.substring(i, i+3) == '098') {
          return true;
        }
      }
    }
    return false;
  }
}
