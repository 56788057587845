<div *ngIf="(error || htmlError) && !closed" class="page-level-error-container"
  [ngClass]="mode + ' ' + margin + ' ' + 'error-' + appStore.lob"
  [attr.id]="identifier ? identifier : 'app-page-level-error-container'">
  <div *ngIf="appStore.lob == 'ie'" aria-hidden="true" class="page-level-error-image">
    <div id="page_level_error_errorMode2" *ngIf="mode == 'error'" class="icon-alert-general-filled" aria-hidden="true">
      <span class="path1 color10"></span>
      <span class="path2 color01"></span>
    </div>
    <div id="page_level_error_warningMode2" *ngIf="mode == 'warning'" class="icon-info-diamond-filled" aria-hidden="true">
      <span class="path1 color57"></span>
      <span class="path2 color01"></span>
    </div>
    <div id="page_level_error_alertMode2" *ngIf="mode == 'alert'" class="icon-info-square-filled" aria-hidden="true">
      <span class="path1 color24"></span>
      <span class="path2 color01"></span>
    </div>
    <div id="page_level_error_successMode2" *ngIf="mode == 'success'" class="icon-success-filled" aria-hidden="true">
      <span class="path1 color66"></span>
      <span class="path2 color01"></span>
    </div>
  </div>
  <div id="page_level_error_image2" *ngIf="appStore.lob != 'ie'" aria-hidden="true" class="page-level-error-image">
    <div *ngIf="mode == 'error'" class="icon-Error-Warning-Fill color10" aria-hidden="true"></div>
    <div *ngIf="mode == 'warning'" class="icon-Caution" aria-hidden="true"></div>
    <div *ngIf="mode == 'alert'" class="icon-Info-Circle-Fill color24" aria-hidden="true"></div>
    <div *ngIf="mode == 'success'" class="icon-Checkmark-Fill color20" aria-hidden="true"></div>
  </div>
  <div id="page_level_error_text2" class="page-level-error-text">
    <div *ngIf="!htmlError" class="errorText" [innerHtml]="error | safe:'html'"></div>
    <div *ngIf="htmlError" class="errorText"><ng-content></ng-content></div>
    <div *ngIf="code" class="errorCode">{{ '{' }}{{globalContent.text.result}} #{{code}}{{ '}' }}</div>
  </div>
  <div class="page-level-error-close" *ngIf="canClose">
    <button id="page_level_error_closeBtn2" type="button" class="icon-Close" (click)="close()"><span
        class="sr-only">{{globalContent.text.close}}</span></button>
  </div>
</div>
<div *ngIf="multipleErrors.length > 0 && !closed" class="page-level-error-container multiple"
  [ngClass]="mode + ' ' + margin + ' ' + 'error-' + appStore.lob"
  [attr.id]="identifier ? identifier : 'app-page-level-error-container'">
  <div *ngFor="let errorItem of multipleErrors; let i = index" class="page-level-error-container-multiple">
    <div *ngIf="appStore.lob == 'ie'" aria-hidden="true" class="page-level-error-image">
      <div id="page_level_error_errorMode" *ngIf="mode == 'error'" class="icon-alert-general-filled" aria-hidden="true">
        <span class="path1 color10"></span>
        <span class="path2 color01"></span>
      </div>
      <div id="page_level_error_warningMode" *ngIf="mode == 'warning'" class="icon-info-diamond-filled" aria-hidden="true">
        <span class="path1 color57"></span>
        <span class="path2 color01"></span>
      </div>
      <div id="page_level_error_alertbtn" *ngIf="mode == 'alert'" class="icon-info-square-filled" aria-hidden="true">
        <span class="path1 color24"></span>
        <span class="path2 color01"></span>
      </div>
      <div id="page_level_error_successMode" *ngIf="mode == 'success'" class="icon-success-filled" aria-hidden="true">
        <span class="path1 color66"></span>
        <span class="path2 color01"></span>
      </div>
    </div>
    <div id="page_level_error_image" *ngIf="appStore.lob != 'ie'" aria-hidden="true" class="page-level-error-image">
      <div *ngIf="mode == 'error'" class="icon-Error-Warning-Fill color10" aria-hidden="true"></div>
      <div *ngIf="mode == 'warning'" class="icon-Caution" aria-hidden="true"></div>
      <div *ngIf="mode == 'alert'" class="icon-Info-Circle-Fill color24" aria-hidden="true"></div>
      <div *ngIf="mode == 'success'" class="icon-Checkmark-Fill color20" aria-hidden="true"></div>
    </div>
    <div id="page_level_error_text" class="page-level-error-text">
      <div class="errorText" [innerHtml]="errorItem.error | safe:'html'"></div>
      <div *ngIf="errorItem.code" class="errorCode">{{ '{' }}{{globalContent.text.result}} #{{errorItem.code}}{{ '}' }}
      </div>
    </div>
    <div *ngIf="canClose" class="page-level-error-close">
      <button id="page_level_error_closeBtn" *ngIf="i == 0" type="button" class="icon-Close" (click)="close()"></button>
    </div>
  </div>
</div>