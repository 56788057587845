import { BaseData } from 'src/app/shared/models/base-data';

export class SymbolQuoteData extends BaseData {
  constructor() {
    super();
  }
}

export class QuoteReq {
  Symbols: [
    {
      SymbolName: string;
      Market: string;
      SymbolType: string;
    }
  ];
}

export const nasdaqSymbolsArray = [
  "NXB",
  "NBN",
  "NBA"
];