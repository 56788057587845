import { Injectable } from '@angular/core';
import { Observable, empty, forkJoin, observable, ObservableInput } from 'rxjs';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Lob } from 'src/app/shared/models/lob.enum';
import { ROOT_CTX } from 'src/app/shared/models/user';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { PageStore } from 'src/app/shared/models/page-store';
import { routeConfig } from 'src/app/config/router-mapping';
import { SSOSetupStore } from '../data/sso-setup.store';
import { AssociateAccounts } from '../data/sso-setup.data';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class SSOSetupService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

    return input;
  }

  associateLogin(data) {
    const req: AssociateAccounts = new AssociateAccounts();
    req.UserId = data.UserId;
    req.Password = data.Password;
    req.Ssolocale = data.Ssolocale;
    req.CustomerType = data.CustomerType;
    req.SsoAvailable = data.SsoAvailable;
    return this.httpService.post(gatewayConfig.APIServices.SSOAssociateLogin.url, req);
  }

  getSSOOtvcPrompt() {
    return this.httpService.post(gatewayConfig.APIServices.SSOOTVCInit.url, {});
  }

  getOtvcPrompt() {
    return this.httpService.post(gatewayConfig.APIServices.OTVCInit.url, {});
  }

  associateAccounts() {
    return this.httpService.post(gatewayConfig.APIServices.SSOAssociate.url, {});
  }

  getConnectOnlineURL() {
    return this.httpService.post(gatewayConfig.APIServices.goToBanking.url, {});
  }

}
