import { Injectable } from '@angular/core';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppErrorHandlerService {

  loggedErrors: any = [];

  constructor(private appStore: AppStore, private httpService: HttpService) { }

  sendException(exception) {
    return new Promise((resolve) => {
      let isNew: boolean = true;
      let payload: any = {};
      payload.LogType = 'CLIENT';
      let errorMsg = (exception && exception.message) ? exception.message.replaceAll("https", "htps") : '';
      payload.Message = errorMsg.replace(/[^a-zA-Z0-9 ]/g, "");
      let errorStack = (exception && exception.stack) ? exception.stack.replaceAll("https", "htps") : '';
      payload.Stack = errorStack.replace(/[^a-zA-Z0-9 ]/g, "");
      payload.PageName  = (window.location.href).replace("https", "htps");

      while (this.loggedErrors.length > 0 && Date.now() - this.loggedErrors[0].Time > 300000) {
        this.loggedErrors.shift();
      }

      for (let i = 0; i < this.loggedErrors.length; i++) {
        if (payload.Stack == this.loggedErrors[i].Stack) {
          isNew = false;
        }
      }

      if (isNew) {
        this.loggedErrors.push({ Stack: payload.Stack, Time: Date.now() });
        this.httpService.post("/v2/common/log", payload).subscribe(
          (data: any) => {
            console.log("------- Logged Error -------");
            resolve(true);
          },
          (err) => {
            resolve(false);
          });
      }
    });
  }

  logPluginException(exception) {
    let payload: any = {};
    payload.LogType = 'CLIENT';
    payload.Params = {};
    payload.Params.message = exception.message;
    this.httpService.post("/v2/common/logger/log", payload, { params: { skiploading: 'true' } }).subscribe(
      (data: any) => {
        console.log("------- Logged Error -------");

      },
      (err: HttpErrorResponse) => {

      });
  }

}