import { Injectable, Renderer2, RendererFactory2, NgZone } from '@angular/core';
import { AppStore } from 'src/app/shared/models/app-store';
import { Lob, Lang } from 'src/app/shared/models/lob.enum';
import { _User } from 'src/app/shared/models/user';
import { PersistenceService } from './persistence.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { Observable, of, forkJoin } from 'rxjs';
import { ErrorConstant, AppError } from '../models/app-error';
import { ContentService } from './content.service';
import { PluginService } from 'src/app/shared/services/plugin.service';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { isDefined } from 'src/app/shared/services/utils.service';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import Status = _User.Status;
import { HttpService } from './http.service';
import { buildMarkitPageActions } from '../../shared/services/markit.service';
import { createFile, writeFile, removeFile, triggerFileOpen } from 'src/app/shared/services/file-utils.service';
import { openWindow } from 'src/app/shared/services/utils.service';
import { EnvService } from 'src/app/env.service';
import { TrackingService } from '../../shared/services/tracking.service';
import { GtagService } from 'src/app/shared/services/gtag.service';

declare let navigator: any;
declare let AppRate: any;
declare let window: any;
declare let deviceOrientation: any;
declare let link: any;
declare let cordova: any;
declare let Keyboard: any;
declare let StatusBar: any;
declare let MobileAccessibility: any;
declare let BrazePlugin: any;
declare let universalLinks: any;
declare let medalliaDigital: any;

declare let require: any;
const braze = require("@braze/web-sdk");

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  htmllang = 'en';

  renderer: Renderer2;

  constructor(
    private appStore: AppStore,
    private router: Router,
    private persistenceService: PersistenceService,
    private commonService: CommonService,
    private contentProvider: ContentService,
    private pluginService: PluginService,
    private gContentStore: GlobalContentStore,
    private location: Location,
    private metaData: Meta,
    private rendererFact: RendererFactory2,
    private httpService: HttpService,
    private observableSubscriptionService: ObservableSubscriptionService,
    private ngZone: NgZone,
    private env: EnvService,
    private trackingService: TrackingService,
    private gtagService: GtagService
  ) {
    this.renderer = rendererFact.createRenderer(null, null);
  }

  resolvePlatformDetails(reqUrl: string): any {
    let mode1: _User.Mode = _User.Mode.WEB;
    let version1 = 'N/A';
    let appVersion = 'N/A';
    let revision1 = 'N/A';
    let build1 = 'N/A';
    let container1 = 'N/A';
    let platform1 = 'web';
    let checksum = 'N/A';

    const url = new URL(reqUrl);

    if (reqUrl.indexOf('&channel=native') > -1) {
      this.persistenceService.sessionStorePersist('OLBchannel', 'native');
      mode1 = _User.Mode.INAPPBROWSER;
    } else if (this.persistenceService.sessionStoreRetrieve('OLBchannel') == 'native') {
      mode1 = _User.Mode.INAPPBROWSER;
    } else if (reqUrl.indexOf('&version=') > -1) {
      mode1 = _User.Mode.APP;
      version1 = url.searchParams.get('version');
      appVersion = version1;
      version1 = this.pluginService.resolvePluginVersion(version1);
      platform1 = url.searchParams.get('platform');

      revision1 = url.searchParams.get('revision');
      build1 = url.searchParams.get('build');
      checksum = url.searchParams.get('checksum');

      this.commonService.cleanWebOnlyCode();
    } else {
      container1 = navigator.appVersion;
    }
    const screenWidth = document.body.clientWidth;
    const layout1 = screenWidth < 900 ? 0 : 1;

    return {
      mode: mode1,
      appVersion: appVersion,
      version: version1,
      revision: revision1,
      build: build1,
      container: container1,
      platform: _User.Platform[platform1.toUpperCase()],
      layout: layout1,
      checksum: checksum
    };
  }

  resolvePlatformDetailsChild(reqUrl: string) {
    let mode1: _User.Mode = _User.Mode.WEB;
    let version1 = 'N/A';
    let appVersion = 'N/A';
    let revision1 = 'N/A';
    let build1 = 'N/A';
    let container1 = 'N/A';
    let platform1 = 'web';
    let checksum = 'N/A';

    const url = new URL(reqUrl);
  }

  resolveAppDetails(platformDetails: any): any {
    const screenWidth = document.body.clientWidth;
    let layout: number;
    let lang: string;
    let lob: string;
    let userStatus: Status = _User.Status.GUEST;
    layout = screenWidth < 900 ? 0 : 1;

    if (this.commonService.retrieveSessionStorageIsSignedOnFlag()) {
      userStatus = _User.Status.AUTHZ;
    }
    if (platformDetails.mode === _User.Mode.APP) {
      // for app
      lob = this.commonService.getUserLOB(true);
    } else {
      // for web
      let urlHash = window.location.hash;
      urlHash = urlHash.substring(1);
      const sval = urlHash.split('/');
      if (sval.length === 5 && sval[1] === 'aem') {
        lang = sval[3];
        lob = sval[2];
      } else if (sval.length === 3 && sval[1] === 'aem' && sval[2] === 'REGISTER') {
        lob = this.commonService.getUserLOB(undefined, undefined, true);
        lang = this.commonService.getUserLANG(true);
      } else if (sval.length === 4 && sval[1] === 'home') {
        lang = sval[2];
        lob = sval[3];
      } else if (sval.length === 3 && sval[1] === 'olbsso') {
        // for sso routing handler

        let customerType = null;
        let ssoLocale = null;

        const queryParams = sval[2].split('?')[1].split('&');

        for (const value of queryParams) {
          if (value.startsWith('ssoCustomerType')) {
            customerType = value.split('=')[1];
          } else if (value.startsWith('locale')) {
            ssoLocale = value.split('=')[1];
          }
        }

        if (ssoLocale.startsWith('en')) {
          lang = Lang.EN;
        } else if (ssoLocale.startsWith('fr')) {
          lang = Lang.FR;
        }
        lob = this.commonService.getUserLOB(undefined, customerType);
        // if (isDefined(this.persistenceService.cookieStoreRetrieve('MBI_LOB'))
        //   && this.persistenceService.cookieStoreRetrieve('MBI_LOB') !== null
        //   && this.persistenceService.cookieStoreRetrieve('MBI_LOB') !== '') {

        //   lob = this.persistenceService.cookieStoreRetrieve('MBI_LOB');

        // } else {
        //   switch (customerType) {
        //     case 'R':
        //       lob = Lob.GEN;
        //       break;
        //     case 'I':
        //       lob = Lob.IIS;
        //       break;
        //     case 'W':
        //       lob = Lob.WG;
        //       break;
        //     case 'WG':
        //         lob = Lob.WG;
        //         break;
        //     case 'P':
        //       lob = Lob.CPIC;
        //       break;
        //     default:
        //       lob = Lob.IE;
        //       break;
        //   }
        // }
      } else {
        lob = this.commonService.getUserLOB();
        lang = this.commonService.getUserLANG();
      }

      if (platformDetails.mode !== _User.Mode.INAPPBROWSER) {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('web');
      }
      // const os = this.getMobileOperatingSystem();
      // to fix ios header issue
      // if (os === 'iOS') {
      //   body.style.position = 'fixed';
      // }
    }
    return {
      lob: Lob[lob.toLocaleUpperCase()],
      lang: lang ? Lang[lang.toLocaleUpperCase()] : undefined,
      userStatus,
    };
  }

  initBraze() {
    if(!this.appStore.isApp('app')) {
      if('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/service-worker.js');
      }
      braze.initialize((window as any).braze_id, {
        baseUrl: (window as any).braze_url,
        enableLogging: true,
        allowUserSuppliedJavascript: true
      });

      let self = this;
      braze.subscribeToInAppMessage(function(inAppMessage) {
        self.appStore.brazeInAppMessage = inAppMessage;
        braze.showInAppMessage(inAppMessage);
      });
      braze.requestPushPermission( function() {
      }, function() {
      });
      this.commonService.subscribeForBrazeContentCards((cards) => {
        this.observableSubscriptionService.setContentCards(cards);
      });
      braze.openSession();
      this.commonService.brazeSetLanguage();
      this.appStore.brazeUserSession = false;
    } else {
      let self = this;
      this.commonService.subscribeForBrazeContentCards((cards) => {
        this.observableSubscriptionService.setContentCards(cards);
      });
      this.commonService.brazeSetLanguage();
      this.appStore.brazeUserSession = false;
    }
  }

  initBrazePTE() {
    let mockCardsJSON = require('../../../assets/sampleJson/content_cards.json');
    this.observableSubscriptionService.setContentCards(mockCardsJSON);
  }


  initApp(): Promise<{}> {
    const reqUrl: string = window.location.href;
    const platformDetails: any = this.resolvePlatformDetails(reqUrl);
    const appDetails: any = this.resolveAppDetails(platformDetails);
    const screenWidth = document.body.clientWidth;

    this.httpService.getVersion().subscribe((data) => {
      this.appStore.version = { number: data.version, time: Date.now() };
    });

    buildMarkitPageActions(this.router, this.httpService, this.trackingService, this.observableSubscriptionService);

    if (this.commonService.gtagServiceEnabled && (window as any).TrackingEnabled) {
      this.gtagService.googleAnalyticsHeadScripts(localStorage.getItem("username"))
    }

    var markit_component_script = document.createElement('script');
    if (window.__env.production) {
      markit_component_script.setAttribute('src', 'assets/lib/web-components-prod/md-obr2-with-babel-polyfill.js');
    } else {
      markit_component_script.setAttribute('src', 'assets/lib/web-components/md-obr2-with-babel-polyfill.js');
    }
    document.head.appendChild(markit_component_script);

    let resObs: Observable<any>;
    if (_User.Mode.APP === platformDetails.mode) {
      resObs = this.commonService.checkCompatability({
        Platform: (window as any).isEnvPerfecto ? "IOS" : platformDetails.platform.toUpperCase(),
        Version: platformDetails.version,
        Checksum: platformDetails.checksum
      });
      // if (platformDetails.version === '4.0.2') {
      //   // In usual case from OLD Wrapper - using BETA link - will not detect as APP
      //   // as there will be no Version or platform details in Beta Link URL
      //   // Still putting it as to make sure
      //   // OLD Wrapper has this meta TAG whereas new Wrapper Supports only MOBI < 899px
      //   this.metaData.updateTag({ name: 'viewport', content: 'viewport-fit=cover,initial-scale=1.0,minimum-scale=1.0,width=device-width,user-scalable=no' });
      // } else {
      //   // if ((screenWidth < 900)) {
      //   //   this.metaData.updateTag({ name: 'viewport',
      //   //   content: 'viewport-fit=cover,initial-scale=1.0,minimum-scale=1.0,width=device-width,user-scalable=no' });
      //   // } else {
      //   //   this.metaData.updateTag({ name: 'viewport', content: 'viewport-fit=cover,width=899,user-scalable=no' });
      //   // }
      // }
      // resObs = of({ Compatible: true });
    } else {
      // this.metaData.updateTag({ name: 'viewport',
      // content: 'viewport-fit=cover,initial-scale=1.0,minimum-scale=1.0,width=device-width' });
      resObs = of({ Continue: true });
    }

    this.appStore.initUser({
      lob: appDetails.lob,
      status: appDetails.userStatus,
      lang: appDetails.lang,
      platformInfo: platformDetails,
      userlob:appDetails.UserLobs,
      premiumUser: appDetails.premiumUser
    });

    if(!this.appStore.isApp('app')) {
      if((window as any).isEnvPTE === true) {
        this.initBrazePTE();
      } else {
        this.initBraze();
      }
    }
    
    this.observableSubscriptionService.passLob(appDetails.lob);
    this.observableSubscriptionService.passLocale(appDetails.lang);

    console.log('Platform Details ' + JSON.stringify(platformDetails));
    console.log('App Details ' + JSON.stringify(appDetails));

    window.openWindow = (url) => {
      openWindow(url, this.appStore);
    }
    
    window.openBlob = (url, fileName) => {
      if (this.appStore.isApp('app')) {
        let mimeType = 'application/pdf';
        this.httpService.getBlob(url).subscribe((data: Blob) => {
          let fileData = new Blob([data], { type: mimeType });

          createFile(fileName, (fullPath) => {
            writeFile(fileName, fileData, () => {
              triggerFileOpen(fullPath, mimeType, () => {
              }, (error) => {
              });
            }, (error) => {
            });
          }, (error) => {
          });
        }, (err) => {
        });
      } else {
        openWindow(url, this.appStore, true);
      }
    }

    return new Promise((resolve, reject) => {
      resObs.subscribe((data) => {
        if (data && data.Continue) {
          // only for non app
          this.contentProvider
            .loadGlobalContent()
            .subscribe((res) => {
              Object.assign(this.gContentStore, res);
              resolve(true);
            });
          // Load Extra Content for Application
          // this.contentProvider.fetchCurerencyContent();
          // this.loadVendorIntegrationScripts();
          //this.loadGlobalVendorScript();
          // this.loadScript("assets/scripts/dynatrace/jsSnippet_1.js?"+ fileVersion);
          if (this.appStore.isApp("inapp")) {
            this.adjustMetaTagNew();
            window.addEventListener('orientationchange', () => {
              // const body = document.getElementsByTagName('body')[0];
              // body.classList.remove('fade-in');
              this.adjustMetaTagNew();
            });

            let normalOpen = window.open;
            window.open = function (a, b?, c?) {
              if (b == "_inapp") {
                normalOpen(a, "_system", c);
              } else {
                if (a.startsWith('/')) {
                  a = window.location.origin + a;
                } else if (!a.startsWith('http')) {
                  a = window.location.origin + '/' + a;
                }
                window.location.href = "cibcbanking://openURLInExternalBrowser?target=" + a;
              }
            };
          }
        } else if (data && data.Compatibility > 0) {
          // app but force upgrade needed
          resolve(true);
          this.router.navigate([(data.Compatibility === 1) ? '/integrity' : '/forceupgrade']);
          if(platformDetails.version === '5.0.2') {
            this.commonService.loadScript('assets/plugins/base/' + platformDetails.platform + '/cordova.js');
          }
         else {
            this.commonService.loadScript('assets/plugins/base-5.0.3/' + platformDetails.platform + '/cordova.js');
          }
        
          //this.commonService.loadScript('assets/plugins/4.0.2/' + platformDetails.platform + '/cordova-all.min.js');
          document.addEventListener('deviceready', (appStore) => {
            // this is for old app, later needs to be replaced with hideNow(), since newer app hide() will wait till the end.
            navigator.splashscreen.hide();
          });
        } else {
          // if (platformDetails.version === '4.0.2') {
          //   const lang = this.persistenceService.cookieStoreRetrieve('MBI_LANG');
          //   this.appStore.lang = (lang) ? Lang[lang.toLocaleUpperCase()] : undefined;
          //   if (lang === '') {
          //     this.persistenceService.cookieStorePersist('MBI_LANG', Lang.EN);
          //     this.appStore.lang = Lang.EN; // default set
          //   }
          //   this.contentProvider.loadGlobalContent().subscribe(res => {
          //     Object.assign(this.gContentStore, res);
          //     resolve(true);
          //   });
          //   this.contentProvider.fetchCurerencyContent();
          //   this.htmllang = lang;
          //   this.commonService.updateLanguage(this.htmllang);
          // } else {
          this.commonService.loadScript('assets/lib/test.js');
          this.commonService.loadScript(
            'assets/plugins/' +
            platformDetails.version +
            '/' +
            platformDetails.platform +
            '/cordova.js'
          );
          //this.loadGlobalVendorScript();
          document.addEventListener('deviceready', (appStore) => {

            this.loadData(platformDetails, resolve, reject);

            // Version check should be removed after the force upgrade
            // if (this.appStore.platform.toLowerCase() == 'android' && platformDetails.version === '5.0.1') {
            //   navigator.connection.getHashInfo((a) => {
            //     this.commonService.checkIntegrity(a).subscribe((data) => {
            //       if (data.Status == 0) {
            //         this.loadData(platformDetails, resolve, reject);
            //       } else {
            //         resolve(true);
            //         this.router.navigate(['/forceupgrade']);
            //       }
            //     });
            //   }, (b) => {
            //     resolve(true);
            //     this.router.navigate(['/forceupgrade']);
            //   });
            // } else {
            //   this.loadData(platformDetails, resolve, reject);
            // }
          }); // device ready ends
        }
      });
    });
  }

  successCb(d){
    console.log("+++++++++" + JSON.stringify(d));
  }
  errorCb(d){
    console.log("--------" + JSON.stringify(d));
  }

  private loadData(platformDetails, resolve, reject) {
    window.open = cordova.InAppBrowser.open;
    this.appStore.features.appRate = AppRate;
    this.appStore.features.touchId = window.plugins.touchid;
    this.appStore.features.splashScreen =
      navigator.splashscreen;
    this.appStore.features.keyboard = Keyboard;
    this.appStore.features.BrazePlugin = BrazePlugin;
    this.appStore.features.geolocation = navigator.geolocation;
    this.appStore.features.medalliaDigital = medalliaDigital;
    if (
      isDefined(this.getMobileOperatingSystem()) &&
      this.getMobileOperatingSystem() === 'iOS'
    ) {
      this.isScreenReaderOn();
      window.Biometric.isNotch((msg) => {
        this.appStore.isNotch = msg == '0';
      });
    } else {
      this.appStore.isNotch = false;
    }

    platformDetails.isMetaUpdateNeeded = this.isMetaUpdateNeeded();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('app');
    // invoked only for android
    document.addEventListener(
      'backbutton',
      () => {
        const split = window.location.hash.split('/');
        if (split.length > 1 && split[1] === 'home') {
          if (
            isDefined(window.plugins) &&
            isDefined(window.plugins.appMinimize)
          ) {
            window.plugins.appMinimize.minimize();
          }
        } else if (split.length == 2 && split[1] === 'signon') {
          window.location.replace(window.location.href.replace('/signon', '/home') + "/" + this.appStore.lang.toLowerCase() + "/" + this.appStore.lob.toLowerCase());
        } else if (split.length == 2 && split[1] === 'wrapper-maintenance') {
          // Dont do anything on this connection lost page
        } else if (this.appStore.backButtonFn) {
          const fn = this.appStore.backButtonFn;
          fn();
        } else {
          this.appStore.isWrappedBack = true;
          this.location.back();
        }
      },
      false
    );
    setTimeout(()=> {
      this.appStore.features.splashScreen.hide();
    },2000);

    if(this.appStore.isApp('app')) {
      this.initBraze();
    }
    
    if(this.appStore.platform.toLowerCase() == 'android') {
      if(BrazePlugin) {
        BrazePlugin.requestPushPermission();
      }
    }
    
 
     
    if(universalLinks) {
      universalLinks.subscribe('handleOpenUrl', (eventData) => {
        
          console.log('Did launch application from the link: ' + eventData.url);
          //alert(eventData.url);
          this.observableSubscriptionService.setUniversalLink(eventData.url);
        
      });
    }

    if(navigator.geolocation && ((window as any).enableGeolocationMobile === true)) {
      const options = { enableHighAccuracy: true };
      console.log('***** found geo')
      navigator.geolocation.getCurrentPosition(this.successCb, this.errorCb, options);
    }else {
      console.log('***** not found geo')
    }

    if (Keyboard) {
      Keyboard.shrinkView(true);
      Keyboard.disableScrollingInShrinkView(false);
      Keyboard.automaticScrollToTopOnHiding = true;
    }
    if (StatusBar) {
      StatusBar.overlaysWebView(false);
      if (this.appStore.platform.toLowerCase() == 'ios') {
        StatusBar.styleDefault();
      } else {
        //StatusBar.backgroundColorByHexString("#FFF");
      }
    }
    if(this.appStore.isApp() && medalliaDigital && ((window as any).enableFeedbackMobile === true) && (window.feedbackButtonTokenAndroid && window.feedbackButtonTokenIos)) {
      //set log level
      //medalliaDigital.setLogLevel(medalliaDigital.logLevel.DEBUG);
      const tokenForApp = this.appStore.platform.toLowerCase() === "ios" ? window.feedbackButtonTokenIos : window.feedbackButtonTokenAndroid;
      medalliaDigital.init(tokenForApp,
        function(msg) {
          //Handle successful initiation of Medallia SDK
          console.log('succcess medallia' + JSON.stringify(msg));
        },
        function(err) {
          console.log('error medallia' + JSON.stringify(err));
        }
      )
    }
    window.addEventListener('statusTap', function () {
      document.body.scrollTop = 0;
      var apc = document.getElementById(
        'main-app-container'
      );
      if (apc)
        apc.scrollTo({ top: 0, behavior: 'smooth' });
    });
    window.addEventListener('keyboardWillShow', () => {
      // Describe your logic which will be run each time when keyboard is about to be shown.
      this.onKeyboardOpen();
    });
    window.addEventListener('keyboardWillHide', () => {
      // Describe your logic which will be run each time when keyboard is about to be closed.
      this.onKeyboardClose();
    });
    navigator.globalization.getLocaleName(
      (locale) => {
        const locValue = locale.value;
        const dLocal: string = locValue.substr(
          0,
          locValue.indexOf('-')
        );
        if (dLocal.toLowerCase() !== 'fr') {
          this.appStore.lang = Lang.EN;
        } else {
          this.appStore.lang =
            Lang[dLocal.toUpperCase()];
        }
        this.contentProvider
          .loadGlobalContent()
          .subscribe((res) => {
            Object.assign(this.gContentStore, res);
            resolve(true);
          });
        // this.contentProvider.fetchCurerencyContent();
      },
      (err) => {
        reject(new AppError(ErrorConstant.TE101));
      }
    );

    // window
    //   .matchMedia('(orientation: portrait)' || '(orientation: landscape)')
    //   .addListener((e) => {
    //         const body = document.getElementsByTagName('body')[0];
    //         body.classList.add('fade-in');
    //   });
    // window.addEventListener('resize', () => {
    //   const body = document.getElementsByTagName('body')[0];
    //   body.classList.add('fade-in');
    // });
    this.adjustMetaTagNew();
    window.addEventListener('orientationchange', () => {
      // const body = document.getElementsByTagName('body')[0];
      // body.classList.remove('fade-in');
      this.adjustMetaTagNew();
    });

    // window.addEventListener('resize', () => {
    //   const body = document.getElementsByTagName('body')[0];
    //   body.classList.add('fade-in');
    // });
    // window.addEventListener('orientationchange', () => {
    //   setTimeout(() => {
    //     if (platformDetails.isMetaUpdateNeeded) {
    //       this.adjustMetaTag();
    //     }
    //   }, 5);
    // });

    // window.addEventListener('orientationchange', () => {
    //   // this.appStore.showSplashScreen();
    //   // setTimeout(() => {
    //   //   // const screenWidthOrientation = window.innerWidth;
    //   //   const documentClientWidth = document.documentElement.clientWidth;
    //   //   // alert(documentClientWidth);
    //   //   if (platformDetails.version === '4.0.2') {
    //   //     // In usual case from OLD Wrapper - using BETA link - will not detect as APP
    //   //     // as there will be no Version or platform details in Beta Link URL
    //   //     // Still putting it as to make sure
    //   //     // OLD Wrapper has this meta TAG whereas new Wrapper Supports only MOBI < 899px
    //   //     this.metaData.updateTag({ name: 'viewport',
    //   content: 'viewport-fit=cover,initial-scale=1.0,minimum-scale=1.0,width=device-width' });
    //   //   } else {
    //   //     // if ((documentClientWidth < 900)) {
    //   //     //   this.metaData.updateTag({ name: 'viewport',
    //   content: 'viewport-fit=cover,initial-scale=1.0,minimum-scale=1.0,width=device-width,user-scalable=no' });
    //   //     // } else {
    //   //     //   this.metaData.updateTag({ name: 'viewport', content: 'viewport-fit=cover,width=899,user-scalable=no' });
    //   //     // }
    //   //   }
    //   // }, 1000);
    //   // setTimeout(() => {
    //   //   this.appStore.hideSplashScreen();
    //   // }, 1000);
    // });
    // }

    document.addEventListener(
      'pause',
      () => {
        // Nothing as we have saved request time in Session storage already in HTTP interceptor
      },
      false
    );
    document.addEventListener(
      'resume',
      () => {
        if (this.commonService.isUserSignedOn()) {
          this.appTimeOut();
        }
      },
      false
    );
  }

  private isScreenReaderOn() {
    if (MobileAccessibility) {
      MobileAccessibility.isScreenReaderRunning(
        this.isScreenReaderRunningCallback
      );
    }
  }

  isScreenReaderRunningCallback = (flag) => {
    this.ngZone.run(() => {
      this.appStore.features.isScreenReaderON = flag;
    });
  };

  private onKeyboardOpen() {
    this.ngZone.run(() => {
      this.observableSubscriptionService.setKeyboardStatus(true);
    });
  }

  private onKeyboardClose() {
    this.ngZone.run(() => {
      this.observableSubscriptionService.setKeyboardStatus(false);
    });
  }

  private adjustMetaTagNew() {
    const fView =
      'viewport-fit=cover, user-scalable=no, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0';
    const mView = 'viewport-fit=cover, user-scalable=no, width=899';
    // let isUpdated = false;
    var orientation = 'landscape';
    if (window.orientation === 0 || window.orientation === 180) {
      orientation = 'portrait';
    }
    deviceOrientation.current = orientation;
    console.log('Changing to ' + orientation);
    if (deviceOrientation.portrait !== deviceOrientation.landscape) {
      if (deviceOrientation[orientation] === 0) {
        link.content = fView;
      } else {
        link.content = mView;
      }
      this.metaData.updateTag({
        name: 'viewport',
        content: link.content,
      });
      console.log('Updated to ' + orientation);
    } else if (deviceOrientation.portrait == 1) {
      link.content = mView;
      this.metaData.updateTag({
        name: 'viewport',
        content: link.content,
      });
    }
  }

  private adjustMetaTag() {
    const fView = 'viewport-fit=cover,user-scalable=no,width=device-width';
    const mView = 'viewport-fit=cover,user-scalable=no,width=899';
    let isUpdated = false;
    if (isUpdated) {
      // So This if part is for setting back to original that is 'fview'
      // as one timne is already updated, hence now putting back on orienatationchange
      // So does not have to go to all if else again
      // Thus putting this check as it will only come to this method if portrait !== Landscape
      isUpdated = false;
      link.content = fView;
    } else {
      if (screen) {
        if (
          (window.orientation === 0 || window.orientation === 180) &&
          deviceOrientation.portrait === 1
        ) {
          // alert('Protrait deviceOrientation ');
          isUpdated = true;
          link.content = mView;
        } else if (
          (window.orientation === 90 || window.orientation === -90) &&
          deviceOrientation.landscape === 1
        ) {
          // alert('Landscape');
          isUpdated = true;
          link.content = mView;
        }
      } else {
        if (
          (screen.orientation.type === 'portrait-primary' ||
            screen.orientation.type === 'portrait-secondary') &&
          deviceOrientation.portrait === 1
        ) {
          // alert('Protrait deviceOrientation ');
          isUpdated = true;
          link.content = mView;
        } else if (
          (screen.orientation.type === 'landscape-primary' ||
            screen.orientation.type === 'landscape-secondary') &&
          deviceOrientation.landscape === 1
        ) {
          // alert('Landscape');
          isUpdated = true;
          link.content = mView;
        }
      }
    }
    setTimeout(() => {
      this.metaData.updateTag({
        name: 'viewport',
        content: link.content,
      });
    });
    setTimeout(() => {
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('fade-in');
    }, 8000);
  }

  isMetaUpdateNeeded() {
    if (deviceOrientation.portrait !== deviceOrientation.landscape) {
      return true;
    } else if (
      deviceOrientation.portrait === 1 &&
      deviceOrientation.landscape === 1
    ) {
      // one-time update viewport. -  no need to change on orientation change
      this.metaData.updateTag({
        name: 'viewport',
        content: 'viewport-fit=cover,user-scalable=no,width=899',
      });
      return false;
    }
    return false;
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
    return 'unknown';
  }

  /**
   * Timeout the request after 30 min  inactivity and navigate back to home page
   */
  private appTimeOut() {
    let APP_sessionTimeout = 30 * 60 * 1000; //  30 minutes
    let delta =
      Date.now() -
      parseInt(
        this.persistenceService.sessionStoreRetrieve('LAST_ACTIVITY')
      );
    if (delta >= APP_sessionTimeout) {
      this.ngZone.run(() => {
        this.router.navigate(['/home']);
      });
    }
  }
}
