import { FormControl } from '@angular/forms';
import { isObject, isString, isNumber, isBoolean } from 'util';
import { isDefined } from 'src/app/shared/services/utils.service';
import _ from 'lodash';

export class FormValidatorFactory {
  static specialCharNotAllowedPattern = /[^a-zA-Z0-9@#$%^&*\-_!+=\[\]{}|\/:,.?\\`~"();'\s]+/g;

  static createNotBlankValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        // if object is of type String, trim before comparing
        if (isString(fc.value) && fc.value?.trim() !== '') {
          return null;
        }
        if (isNumber(fc.value)) {
          return null;
        }

        if (isObject(fc.value)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createIsNumberValidator(code, french?) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        if (french != undefined && french != null && !french) {
          let numPattern = new RegExp('^\-?[0-9]+\,[0-9]*$');
          if (numPattern.test(fc.value)) {
            return { errorCode: code };
          }
          numPattern = new RegExp('^\-?[0-9]*\,[0-9]+$');
          if (numPattern.test(fc.value)) {
            return { errorCode: code };
          }
        }
        let numPattern = new RegExp('^\-?[0-9]+\.?[0-9]*$');
        if (numPattern.test(fc.value)) {
          return null;
        }
        numPattern = new RegExp('^\-?[0-9]*\.?[0-9]+$');
        if (numPattern.test(fc.value)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    };
  }

  static createIsDigitsValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        let numPattern = new RegExp('^[0-9]*$');
        if (numPattern.test(fc.value)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    };
  }

  static createNoRepeatingCharacterValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        if (/^(?!.*(\w)\1{2,}).+$/.test(fc.value.substring(0, 8))) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    };
  }

  static createNoNumericSequenceValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        const sequentialNumbers =
          ['01234567', '12345678', '23456789', '98765432', '87654321', '76543210'];

        for (let seq of sequentialNumbers) {
          if (fc.value.toString().indexOf(seq) === 0) {
            return { errorCode: code };
          }
        }
        return null;
      }
    };
  }

  static createProperEmailValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        let email = fc.value.toString().toUpperCase();
        // if (/^[A-Z0-9._'\-]+@[A-Z0-9._'\-]+.[A-Z]{2,6}$/.test(email)) {
        if (/^[a-zA-Z0-9_.'-]+@[a-zA-Z0-9_.'-]+?\.[a-zA-Z]{2,6}$/.test(email)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    };
  }

  static createCustomEmailValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        let email = fc.value.toString().toUpperCase();
        // if (/^[A-Z0-9._'\-]+@[A-Z0-9._'\-]+.[A-Z]{2,6}$/.test(email)) {
        if (/^[a-zA-Z0-9_.'-]+@[a-zA-Z0-9_.'-]+?\.[a-zA-Z]{2,6}$/.test(email)) {
          return null;
        }
        return { errorCode: code };
      }
      // return { errorCode: code };
    };
  }

  static createGreaterThanValidator(code, lowerBound) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        let val = fc.value;
        if (isString(val)) {
          val = val.replace(',', '.');
        }
        const dataAsInt = parseFloat(val);
        if (isNumber(dataAsInt) && dataAsInt > lowerBound) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createGreaterThanOrEqualToValidator(code, lowerBound) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        let val = fc.value;
        if (isString(val)) {
          val = val.replace(',', '.');
        }
        const dataAsInt = parseFloat(val);
        if (isNumber(dataAsInt) && dataAsInt >= lowerBound) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createBoundedLengthValidator(code, low, high) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        let dataAsStr;
        if (isNumber(fc.value)) {
          dataAsStr = fc.value.toString();
        } else if (isString(fc.value)) {
          dataAsStr = fc.value;
        } else {
          return { errorCode: code };
        }

        if (dataAsStr.includes('.') || dataAsStr.includes(',')) {
          high += 1;
        }
        if ((low <= dataAsStr.length) && (dataAsStr.length <= high)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createLessThanValidator(code, upperBound) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        let val = fc.value;
        if (isString(val)) {
          val = val.replace(',', '.');
        }
        const dataAsInt = parseFloat(val);
        if (isNumber(dataAsInt) && dataAsInt < upperBound) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createExactMatchValidator(code, value) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        let val = fc.value;
        if (isString(val)) {
          val = val.replace(',', '.');
        }
        let amountValue = value;
        if (isString(value)) {
          amountValue = amountValue.replace(',', '.');
          amountValue = amountValue.replace(/\s/g, '');
        }
        const dataAsInt = parseFloat(val);
        const amountAsInt = parseFloat(amountValue);
        if (isNumber(dataAsInt) && dataAsInt === amountAsInt) {
          return { errorCode: code };
        }
        return null;
      }
      return null;
    };
  }

  static createLessThanOrEqualToValidator(code, upperBound) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        let val = fc.value;
        if (isString(val)) {
          val = val.replace(',', '.');
        }
        const dataAsInt = parseFloat(val);
        if (isNumber(dataAsInt) && dataAsInt <= upperBound) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createFeeLessThanValidator(code, amount) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        if (amount <= 150) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createTFSALessThanValidator(code, amount) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        if (amount <= 60) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createNumericEqualsValidator(code, toEqual) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        let val = fc.value;
        if (isString(val)) {
          val = val.replace(',', '.');
        }
        const dataAsInt = parseFloat(val);
        if (isNumber(dataAsInt) && dataAsInt === toEqual) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createNumericNotEqualsValidator(code, toEqual) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        let val = fc.value;
        if (isString(val)) {
          val = val.replace(',', '.');
        }
        const dataAsInt = parseFloat(val);
        if (isNumber(dataAsInt) && dataAsInt !== toEqual) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createStringEqualsValidator(code, toEqual) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        if (isString(fc.value) && fc.value === toEqual) {
          return null;
        }
        return { errorCode: code };
      } else if (!isDefined(toEqual)) {
        return null;
      }
      return { errorCode: code };
    }
  }

  static createStringNotEqualsValidator(code, toEqual) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value) && isString(fc.value) && isDefined(toEqual) && isString(toEqual)) {
        if (fc.value === toEqual) {
          return { errorCode: code };
        } else {
          return null;
        }
      } else {
        return null;
      }
      return null;
    }
  }

  static createStringStartsWithValidator(code, toEqual) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        if (isString(fc.value) && fc.value.charAt(0) === toEqual) {
          return null;
        }
        return { errorCode: code };
      } else if (!isDefined(toEqual)) {
        return null;
      }
      return { errorCode: code };
    }
  }

  static createStringNotStartsWithValidator(code, toEqual) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value) && isString(fc.value) && isDefined(toEqual) && isString(toEqual)) {
        if (fc.value.charAt(0) === toEqual) {
          return { errorCode: code };
        } else {
          return null;
        }
      } else {
        return null;
      }
      return null;
    }
  }


  static createNumAllowedDecimalValidator(code, numDecimalsAllowed): any {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        let dataAsStr;
        if (isNumber(fc.value)) {
          dataAsStr = fc.value.toString();
        } else {
          if (!isString(fc.value) || fc.value === null || !isNumber(parseFloat(fc.value))) {
            return { errorCode: code };
          }

          dataAsStr = fc.value;
        }

        const decimalValues = (dataAsStr.indexOf(',') > -1) ? dataAsStr.split(',')[1] : dataAsStr.split('.')[1];
        if (!isDefined(decimalValues) || decimalValues.length <= numDecimalsAllowed) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createAlphaNumWithSpecialCharsValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        const pattern = new RegExp('^[0-9 a-zA-Z\,\:\/\'\~\!\@\#\$\%\^\*\-\+\=\.]+$');
        if (pattern.test(fc.value)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

 

  static createAlphaWithEitherNumOrSpecialCharsValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9\W_]|[^\w\s])[\S]+$/;
        if (pattern.test(fc.value)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createOneLowercaseValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        const pattern = /[a-z]/;
        if (pattern.test(fc.value)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createOneUppercaseValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        const pattern = /[A-Z]/;
        if (pattern.test(fc.value)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createNotSpaceValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        const pattern = /\s/;
        if (!pattern.test(fc.value)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createOneNumberCharValidator(code) {
    const numPattern = /[0-9]/;
    const alphaPattern = /[^A-Za-z0-9]/;
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        if (numPattern.test(fc.value)) {
          return null;
        }else if(alphaPattern.test(fc.value)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createSpecialCharNotAllowedValidator(code) {
    const notAllowedPattern = this.specialCharNotAllowedPattern;
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        if (!notAllowedPattern.test(fc.value)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createSimilarCharValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        const pattern = /(\w)\1{2,}/;
        if (!pattern.test(fc.value)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createSequentialCharValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        const text = fc.value;
        if(text.length < 3) {
          return null;
        }else {
          for(let i=0; i < text.length - 2; i++) {
            const c1 = text.charCodeAt(i);
            const c2 = text.charCodeAt(i + 1);
            const c3 = text.charCodeAt(i + 2);

            if((c1 == c2 + 1) && (c2 == c3 + 1)) {
              return { errorCode: code };
            }
            if((c1 + 1 == c2) && (c2 + 1 == c3)) {
              return { errorCode: code };
            }
          }
        }
        return null;
      }
      return { errorCode: code };
    }
  }

  static createAlphaNumValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        const pattern = new RegExp('^[0-9a-zA-Z]+$');
        if (pattern.test(fc.value)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createContainsAlphaValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        if (fc.value.match(/[a-z]/i)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createPhoneNumberValidator(code, phoneNumberType) {
    return (fc: FormControl): any => {
      let countryCodeKey = '';
      let phoneNumberkey = '';
      if (phoneNumberType === 'M') {
        countryCodeKey = 'mobilePhoneCountry';
        phoneNumberkey = 'mobilePhone';
      } else if (phoneNumberType === 'H') {
        countryCodeKey = 'homePhoneCountry';
        phoneNumberkey = 'homePhone';
      } else if (phoneNumberType === 'B') {
        countryCodeKey = 'businessPhoneCountry';
        phoneNumberkey = 'businessPhone';
      }
      const countryCode = fc.parent.controls[countryCodeKey].value;
      // const countryCode = countryC;
      let phoneNumber = fc.parent.controls[phoneNumberkey].value;

      if ((countryCode) && (phoneNumber) && phoneNumber !== '') {
        phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
        // changing this code bec countryCode returns object
        // if (countryCode === '146' || countryCode === '110') {
        if (countryCode.CountryCode === '146' || countryCode.CountryCode === '110') {
          if (phoneNumber.length !== 10) {
            return { errorCode: code };
          }
        } else {
          if (phoneNumber.length < 5) {
            return { errorCode: code };
          }
        }
      }
      return null;
    };
  }

  static createCustomPhoneNumberValidator(code, phoneNumberType, countryC) {
    return (fc: FormControl): any => {
      let countryCodeKey = '';
      let phoneNumberkey = '';
      if (phoneNumberType === 'M') {
        countryCodeKey = 'mobilePhoneCountry';
        phoneNumberkey = 'mobilePhone';
      } else if (phoneNumberType === 'H') {
        countryCodeKey = 'homePhoneCountry';
        phoneNumberkey = 'homePhone';
      } else if (phoneNumberType === 'B') {
        countryCodeKey = 'businessPhoneCountry';
        phoneNumberkey = 'businessPhone';
      }
      // const countryCode = fc.parent.controls[countryCodeKey].value;
      const countryCode = countryC;
      let phoneNumber = fc.parent.controls[phoneNumberkey].value;

      if ((countryCode) && (phoneNumber) && phoneNumber !== '') {
        phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
        // changing this code bec countryCode returns object
        // if (countryCode === '146' || countryCode === '110') {
        if (countryCode.CountryCode === '146' || countryCode.CountryCode === '110') {
          if (phoneNumber.length !== 10) {
            return { errorCode: code };
          }
        } else {
          if (phoneNumber.length < 5) {
            return { errorCode: code };
          }
        }
      }
      return null;
    };
  }

  static createSimplePhoneNumberValidator(code) {
    return (fc: FormControl): any => {
      let phoneNumber = fc.value;
      if (isDefined(phoneNumber)) {
        if (phoneNumber && phoneNumber !== '') {
          phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
          if (phoneNumber.length !== 10) {
            return { errorCode: code };
          }
        }
      }
      return null;
    };
  }

  static createWholeNumberValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        const pattern = new RegExp('^[0-9]+$');
        if (pattern.test(fc.value)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static checkDropDownselected(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        const pattern = new RegExp('^[0-9]+$');
        if (pattern.test(fc.value)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static eitherORValidation(validator1, validator2) {
    return (fc: FormControl): any => {
      if (validator1(fc) === null || validator2(fc) === null) {
        return null;
      } else {
        //returning 1st error code only as not sure how to return 2 error codes and how to handle
        //so giving 1st validator a priority. // validator1(fc) || validator2(fc)
        return validator1(fc);
      }
    }
  }

  static DateGreaterThanCurrDateValidator(code) {

    return (fc: FormControl): any => {
      let q = new Date();
      let m = q.getMonth();
      let d = q.getDate();
      let y = q.getFullYear();
      let dm = parseInt(fc.value.substr(4, 2)) - 1;
      let dd = parseInt(fc.value.substr(6, 2));
      let dy = parseInt(fc.value.substr(0, 4));
      let currDate = new Date(y, m, d);
      let enteredDate = new Date(dy, dm, dd);

      if (currDate <= enteredDate) {
        return { errorCode: code };
      }
      return null;

    };
  }

  static bothANDValidation(validator1, validator2) {
    return (fc: FormControl): any => {
      if (validator1(fc) === null && validator2(fc) === null) {
        return null;
      } else if (validator1(fc) === null) {
        return validator2(fc);
      } else if (validator2(fc) === null) {
        return validator1(fc);
      } else {
        //returning 1st error code only as not sure how to return 2 error codes and how to handle
        //so giving 1st validator a priority. // validator1(fc) && validator2(fc)
        return validator1(fc);
      }
    }
  }

  static alwaysInvalid(code) {
    return (fc: FormControl): any => {
      return { errorCode: code };
    }
  }

  //This method is specific to check whether input value is positive or negative and assign error to differnt form field.
  static isPositive(code, lowerBound, value) {
    return (fc: FormControl): any => {
      console.log('enter');
      if (isDefined(value)) {
        let val = value;
        if (isString(val)) {
          val = val.replace(',', '.');
        }
        console.log('defined value');
        const dataAsInt = parseFloat(val);
        console.log('Parse float value' + dataAsInt);
        //if it is not null skip validation
        if (isNaN(dataAsInt)) {
          console.log('not number' + dataAsInt);
          return null
        }
        if (isNumber(dataAsInt) && dataAsInt > lowerBound) {
          console.log('is number' + dataAsInt);
          return null
        }
        console.log('Return Error');
        return { errorCode: code };
      }
      return { errorCode: code };
    };
  }

  static radioButtonCheckedValidator(code) {
    return (fc: FormControl): any => {
      if (isBoolean(fc.value)) {
        return null;
      } else {
        return { errorCode: code };
      }
    };
  }

  static checkBoxCheckedValidator(code) {
    return (fc: FormControl): any => {
      if (fc.value) {
        return null;
      } else {
        return { errorCode: code };
      }
    };
  }
  static isDropDownselected(code) {
    return (fc: FormControl): any => {
      // ignore the default value
      if (fc.value === '0') {
        return { errorCode: code };
      } else if (isDefined(fc.value)) {
        return null;
      }
      return { errorCode: code };
    };
  }

  static checkTextAreaNotBlankValidator(code) {
    return (fc: FormControl): any => {
      // check for nextLine
      const formValue = (typeof fc.value === 'string') ? fc.value.trim() : fc.value;
      if (formValue === '' || formValue === '\n') {
        return { errorCode: code };
      } else if (isDefined(formValue)) {
        return null;
      }
      return { errorCode: code };
    };
  }
  static createPostalCodeValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        const pattern = new RegExp('[a-zA-Z\u00C0\u00C2\u00C6\u00C7\u00C8\u00C9\u00CA\u00CB\u00CE\u00CF\u00D4\u00D9\u00DB\u00DC\u00E0\u00E2\u00E6\u00E7\u00E8\u00E9\u00EA\u00EB\u00EE\u00EF\u00F1\u00F4\u00F9\u00FB\u00FC\u00FF\u0152\u0153\u0178]{1}[0-9]{1}[a-zA-Z\u00C0\u00C2\u00C6\u00C7\u00C8\u00C9\u00CA\u00CB\u00CE\u00CF\u00D4\u00D9\u00DB\u00DC\u00E0\u00E2\u00E6\u00E7\u00E8\u00E9\u00EA\u00EB\u00EE\u00EF\u00F1\u00F4\u00F9\u00FB\u00FC\u00FF\u0152\u0153\u0178]{1}[\u0020\u002D]{0,1}[0-9]{1}[a-zA-Z\u00C0\u00C2\u00C6\u00C7\u00C8\u00C9\u00CA\u00CB\u00CE\u00CF\u00D4\u00D9\u00DB\u00DC\u00E0\u00E2\u00E6\u00E7\u00E8\u00E9\u00EA\u00EB\u00EE\u00EF\u00F1\u00F4\u00F9\u00FB\u00FC\u00FF\u0152\u0153\u0178]{1}[0-9]{1}');
        if (pattern.test(fc.value)) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createSINValidator(code) {
    return (fc: FormControl): any => {
      if (isDefined(fc.value)) {
        var sinSum = fc.value.toString().split("")
          .map(function (value) {
            return parseInt(value);
          })
          .reduceRight(function (prev, curr, currIndex) {
            //even index -> leave as is
            if (currIndex % 2 === 0) {
              return prev + curr;
            }

            //odd index -> double
            var doubledDigit = curr * 2;
            if (doubledDigit > 9) {
              //double digit number, subtract 9 (same as summing digits)
              return prev + (doubledDigit - 9);
            }
            else {
              return prev + doubledDigit;
            }

          }, 0);
        //mod10 check
        if (sinSum % 10 === 0) {
          return null;
        }
        return { errorCode: code };
      }
      return { errorCode: code };
    }
  }

  static createMaliciousEmpNameValidator(code) {
    return (fc: FormControl): any => {
      if(isDefined(fc.value)) {
        const value = fc.value;
        const BLACKLIST =/[^a-zà-ÿ\d \@\!\"\#\&\*\(\)\_\-\\\:\'\,\.\?\/]+/gi;
        const passWhitelist = value.match(BLACKLIST) === null;
        const MALICIOUS_WORDS = [
          'http:',
          'https:',
          'javascript',
          'www.',
          'wwW.',
          'wWw.',
          'wWW.',
          'Www.',
          'WwW.',
          'WWw.',
          'WWW.',
          '÷',
        ];

        const noMalicious = !_.some(MALICIOUS_WORDS,function(maliciousChar){
          return value.indexOf(maliciousChar) !== -1;
        });

        if(passWhitelist && noMalicious) {
          return null
        }

        return {errorCode: code};
      }
      return { errorCode: code };
    };
  }

  static createValidatePOBOX(errorCode) {
    const INVALID_MAILING_WORDS_EN = [
      'P.O. BOX',
      'PO BOX',
      'POBOX',
      'PO.BOX',
      'P.O',
      'General Delivery',
      'C/O',
      'C.O',
    ]
    const INVALID_MAILING_WORDS_FR = [
      'Poste Restante',
      'posterestante',
      'A/S',
      'caissespostale',
      'caisses postale',
      'C.P',
      'C.P.',
      'Case Postal',
      'CasePostal',
      'A.S',
      'A,S',
    ]
    const NOT_ALLOWED = INVALID_MAILING_WORDS_EN.concat(
      INVALID_MAILING_WORDS_FR
    );
    const BOX_BLACKLIST = /\ +?BOX\ *?[0-9]+/gi;
    const BOX_BLACKLIST_2 = /^BOX\ *?[0-9]+/gi;
    const PO_BOX_BLACKLIST = /P\.?O\.?\ +?BOX/gi;
    const A_S_With_Space = /A\ *?\/\ *?S/gi;
    const CP_WORD = /\ +CP\ +/gi;
    const CP_WORD2 = /^CP\ +/gi;
    const CP_WORD3 = /^CP$/gi;

    return (fc: FormControl): any => {
      if(!isDefined(fc.value)) {
        return null;
      }

      let passesMailingBlacklist = fc.value.match(BOX_BLACKLIST) === null;
      passesMailingBlacklist =
          passesMailingBlacklist &&
          fc.value.match(BOX_BLACKLIST_2) === null;
      passesMailingBlacklist =
          passesMailingBlacklist &&
          fc.value.match(PO_BOX_BLACKLIST) === null;

      let passesFrenchMailingBlacklist =
          fc.value.match(A_S_With_Space) === null;
      passesFrenchMailingBlacklist =
          passesFrenchMailingBlacklist &&
          fc.value.match(CP_WORD) === null;
      passesFrenchMailingBlacklist =
          passesFrenchMailingBlacklist &&
          fc.value.match(CP_WORD2) === null;
      passesFrenchMailingBlacklist =
          passesFrenchMailingBlacklist &&
          fc.value.match(CP_WORD3) === null;

      const noMalicious = !_.some(NOT_ALLOWED, (maliciousChar) => {
        return fc.value.toLowerCase().indexOf(maliciousChar.toLowerCase()) !== -1
      })

      if(passesMailingBlacklist && passesFrenchMailingBlacklist && noMalicious) {
        return null;
      }
      return {errorCode}
    }
  }

  static createValidateAddressPOBOX(errorCode) {
    const INVALID_MAILING_WORDS_EN = [
        'P.O. BOX','PO BOX','POBOX','PO.BOX','P.O','General Delivery','C/O','C.O',
        'P.O-','PO-','P O-','BOX-','GD','GD-','G.D'
    ];
    const INVALID_MAILING_WORDS_FR = [
        'Poste Restante','posterestante','A/S','caissespostale','caisses postale','C.P',
        'C.P.','Case Postal','CasePostal','A.S','A,S'
    ];

    const NOT_ALLOWED = INVALID_MAILING_WORDS_EN.concat(
        INVALID_MAILING_WORDS_FR
    );
    const BOX_BLACKLIST = /\ +?BOX\ *?[0-9]+/gi;
    const BOX_BLACKLIST_2 = /^BOX\ *?[0-9]+/gi;
    const BOX_BLACKLIST_3 = /\ +BOX\ +/gi;
    const BOX_BLACKLIST_4 = /\BOX\ +/gi;
    const BOX_BLACKLIST_5 = /\BOX$/gi;
    const PO_BOX_BLACKLIST = /P\.?O\.?\ +?BOX/gi;
    const PO_BLACKLIST = /\ +?PO\ *?[0-9]+/gi;
    const PO_BLACKLIST_2 = /^PO\ *?[0-9]+/gi;
    const PO_BLACKLIST_3 = /\ +PO\ +/gi;
    const PO_BLACKLIST_4 = /\PO\ +/gi;
    const PO_BLACKLIST_5 = /\P O\ +/gi;
    const A_S_With_Space = /A\ *?\/\ *?S/gi;
    const CP_WORD = /\ +CP\ +/gi;
    const CP_WORD2 = /^CP\ +/gi;
    const CP_WORD3 = /^CP$/gi;
    const GD_BLACKLIST_1 = /\ +G D\ +/gi;
    const GD_BLACKLIST_2 = /\G D\ +/gi;
    const GD_BLACKLIST_3 = /\G D$/gi;

    return (fc: FormControl): any => {
        if (!isDefined(fc.value)) {
            return null;
        }

        let passesMailingBlacklist = fc.value.match(BOX_BLACKLIST) === null;
        passesMailingBlacklist =
            passesMailingBlacklist &&
            fc.value.match(BOX_BLACKLIST_2) === null;
        passesMailingBlacklist =
            passesMailingBlacklist &&
            fc.value.match(BOX_BLACKLIST_3) === null;
        passesMailingBlacklist =
            passesMailingBlacklist &&
            fc.value.match(BOX_BLACKLIST_4) === null;
        passesMailingBlacklist =
            passesMailingBlacklist &&
            fc.value.match(BOX_BLACKLIST_5) === null;
        passesMailingBlacklist =
            passesMailingBlacklist &&
            fc.value.match(PO_BOX_BLACKLIST) === null;
        passesMailingBlacklist =
            passesMailingBlacklist &&
            fc.value.match(PO_BLACKLIST) === null;
        passesMailingBlacklist =
            passesMailingBlacklist &&
            fc.value.match(PO_BLACKLIST_2) === null;
        passesMailingBlacklist =
            passesMailingBlacklist &&
            fc.value.match(PO_BLACKLIST_3) === null;
        passesMailingBlacklist =
            passesMailingBlacklist &&
            fc.value.match(PO_BLACKLIST_4) === null;
        passesMailingBlacklist =
            passesMailingBlacklist &&
            fc.value.match(PO_BLACKLIST_5) === null;
        passesMailingBlacklist =
            passesMailingBlacklist &&
            fc.value.match(GD_BLACKLIST_1) === null;
        passesMailingBlacklist =
            passesMailingBlacklist &&
            fc.value.match(GD_BLACKLIST_2) === null;
        passesMailingBlacklist =
            passesMailingBlacklist &&
            fc.value.match(GD_BLACKLIST_3) === null;

        let passesFrenchMailingBlacklist =
            fc.value.match(A_S_With_Space) === null;
        passesFrenchMailingBlacklist =
            passesFrenchMailingBlacklist &&
            fc.value.match(CP_WORD) === null;
        passesFrenchMailingBlacklist =
            passesFrenchMailingBlacklist &&
            fc.value.match(CP_WORD2) === null;
        passesFrenchMailingBlacklist =
            passesFrenchMailingBlacklist &&
            fc.value.match(CP_WORD3) === null;

        const noMalicious = !_.some(NOT_ALLOWED, (maliciousChar) => {
            return fc.value.toLowerCase().indexOf(maliciousChar.toLowerCase()) !== -1
        })

        if (
            passesMailingBlacklist &&
            passesFrenchMailingBlacklist &&
            noMalicious
        ) {
            return null;
        }
        return { errorCode };
    };
}

  static createMaliciousCharForAddressValidator(code) {
    return (fc: FormControl): any => {
      if(isDefined(fc.value)) {
        const value = fc.value;
        const BLACKLIST = /[^a-zà-ÿ\d \'\-\.\/]+/gi;
        const passWhitelist = value.match(BLACKLIST) === null
        const MALICIOUS_WORDS = [
          'http:',
          'https:',
          'javascript',
          'www.',
          'wwW.',
          'wWw.',
          'wWW.',
          'Www.',
          'WwW.',
          'WWw.',
          'WWW.',
          '÷',
        ]
        const noMalicious = !_.some(MALICIOUS_WORDS,function(maliciousChar){
          return value.indexOf(maliciousChar) !== -1;
        })
        if(passWhitelist && noMalicious) {
          return null;
        }

        return {errorCode: code};
      }
      return {errorCode: code};
    }
  }

  static ratioQuantityValidator(code) {
    return (fc: FormControl): any => {
        if (isDefined(fc.value)) {
            let val = fc.value;
            if (val % 100 === 0 && val < 25000) {
                return null
            }
            else {
                return { errorCode: code }
            }

        }
        return { errorCode: code };
    }
}
}
